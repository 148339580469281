import { apiAgent } from "../../config/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    updateLoadError,
    setLoadingState,
    setSavingState
} from "./testSuitePlanSlice";

const attachPlansWithSuites = createAsyncThunk(
    "attachPlansWithSuites",
    async ({testSuiteIds, testPlanIds}, {dispatch, rejectWithValue}) => {
        await dispatch(setSavingState(true));
        try {
            const authToken = localStorage.getItem("token");
            const response = await apiAgent({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/api/v1/test_suite_plans/attach`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                data: {
                    test_suite_ids: testSuiteIds,
                    test_plan_ids: testPlanIds
                }
            });
            if (response.status === 201) {
                console.log(response?.data);
                await dispatch(setSavingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
        }
    }
);

const detachPlanFromSuite = createAsyncThunk(
    "detachPlanFromSuite",
    async ({testSuitePlanId}, {dispatch, rejectWithValue}) => {
        await dispatch(setSavingState(true));
        try {
            const authToken = localStorage.getItem("token");
            const response = await apiAgent({
                method: "DELETE",
                url: `${process.env.REACT_APP_API_URL}/api/v1/test_suite_plans/${testSuitePlanId}`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            });
            if (response.status === 204) {
                console.log(response?.data);
                await dispatch(setSavingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
        }
    }
);

export {
    attachPlansWithSuites,
    detachPlanFromSuite
}
