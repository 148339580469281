import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import {Divider, Box, Paper, FormGroup, FormControlLabel, Checkbox} from "@mui/material";

const CollectionPicker = ({onSelect}) => {
  const allCollections = useSelector((state) => state.user.collections);
  const [selectedCollections, setSelectedCollections] = useState([]);

  const handleClick = (collectionId) => {
    let newState;
    if (selectedCollections.indexOf(collectionId) !== -1) {
      newState = selectedCollections.filter(cId => cId !== collectionId);
    } else {
      newState = [...selectedCollections, collectionId];
    }
    setSelectedCollections(newState);
    const formattedCollections = allCollections.filter(c => newState.indexOf(c.id) !== -1).map(c => ({id: c.id, label: c.name}))
    onSelect(formattedCollections);
  };

  const formattedCollections = allCollections.map(collection => ({id: collection?.id, label: collection?.name}));

  return (
    <Box sx={{ my: 1, width: "100%" }}>
      <h6 style={{ fontWeight: "bold", textAlign: "left" }}>Select collections</h6>
      <Paper sx={{ width: "100%",maxHeight: "175px", overflow: "auto", boxShadow: "none", background: "#f5f5f5" }}>
        <FormGroup>
          {formattedCollections.map(collection => (
            <FormControlLabel 
              key={collection.id}
              control={
                <Checkbox 
                  checked={selectedCollections.indexOf(collection.id) !== -1}
                  onChange={() => { handleClick(collection.id);}}
                />
              }
              label={collection.label}
              sx={{borderBottom: "1px solid #e4e4e4", mr: 0, "&:hover" : {background: "rgba(0, 0, 0, 0.04)"}}}
            />
          ))}
        </FormGroup>
      </Paper>
    </Box>
  )
}

export default CollectionPicker;
