import { configureStore } from "@reduxjs/toolkit";
import currentUserReducer from "./currentUserSlice";
import dashboardReducer from "./dashboardSlice";
import globalReducer from "./globalReducers/globalSlice";
import tabReducer from "./tabSlice";
import testScenarioReducer from "./testScenarioReducers/testScenarioSlice";
import testCaseSetReducer from "./testCaseSetReducers/testCaseSetSlice";
import testRulesReducer from "./testRulesReducers/testRulesSlice";
import testScenarioBuildReducer from "./testScenarioBuildReducers/testScenarioBuildSlice"
import variableSetReducer from "./variableSetReducers/variableSetSlice"
import variableReducer from "./variableReducers/variableSlice"
import testSuiteReducer from "./testSuiteReducers/testSuiteSlice";
import testPlanReducer from "./testPlanReducers/testPlanSlice";
import testSuiteBuildReducer from "./testSuiteBuildReducers/testSuiteBuildSlice";
import testSuitePlanReducer from "./testSuitePlanReducers/testSuitePlanSlice";
import testSuiteScenarioReducer from "./testSuiteScenarioReducers/testSuiteScenarioSlice";
import testPlanBuildReducer from "./testPlanBuildReducers/testPlanBuildSlice";
import dataSetReducer from "./dataSetReducers/dataSetSlice";
import buildReportReducer from "./buildReportReducers/buildReportSlice";
import functionReducer from "./functionReducers/functionSlice";

export default configureStore({
  reducer: {
    user: currentUserReducer,
    dashboard: dashboardReducer,
    tabs: tabReducer,
    global: globalReducer,
    testScenario: testScenarioReducer,
    testCaseSet: testCaseSetReducer,
    testRules: testRulesReducer,
    testScenarioBuild: testScenarioBuildReducer,
    variableSet: variableSetReducer,
    variable: variableReducer,
    testSuite: testSuiteReducer,
    testPlan: testPlanReducer,
    testSuiteBuild: testSuiteBuildReducer,
    testSuitePlan: testSuitePlanReducer,
    testSuiteScenario: testSuiteScenarioReducer,
    testPlanBuild: testPlanBuildReducer,
    dataSet: dataSetReducer,
    buildReport: buildReportReducer,
    function: functionReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          "payload.headers",
          "payload.config",
          "payload.request",
          "payload.response",
        ],
      },
    }),
});
