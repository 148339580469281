import { useState } from 'react';
import AWS from 'aws-sdk';
import {v4 as uuidv4} from 'uuid';

export const useS3Upload = (config) => {
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [signedUrl, setSignedUrl] = useState('');

  AWS.config.update({
    accessKeyId: config.AWS_ACCESS_KEY_ID,
    secretAccessKey: config.AWS_SECRET_ACCESS_KEY,
    region: config.AWS_REGION
  });

  const s3 = new AWS.S3();

  const resetUploadStatus = () => {
    setUploadStatus('idle');
    setProgress(0);
    setSignedUrl('');
  };

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      setUploadStatus('uploading');
      let myuuid = uuidv4();
      const params = {
        Bucket: config.AWS_BUCKET,
        Key: `uploads/${myuuid}/${file.name}`,
        Body: file,
        ACL: 'public-read'
      };

      s3.upload(params)
        .on('httpUploadProgress', (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err, data) => {
          if (err) {
            console.log('Upload error:', err);
            setUploadStatus('error');
            reject(err);
          } else {
            const url = data.Location;
            setSignedUrl(url);
            setUploadStatus('success');
            resolve({ data, url });
          }
        });
    });
  };

  return { uploadFile, progress, uploadStatus, signedUrl, resetUploadStatus };
};
