import React, {useEffect, useState} from 'react';
import {
  Box,
  Paper,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";

import customUtils from '../../utils/customUtils';

const ActionPicker = ({onAddChatItem, onActionClick, currentActionState, onCreateChatItem, actionItems, onReset}) => {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    setActions([...actionItems]);
  }, [actionItems]);

  useEffect(() => {
    console.log(currentActionState);
  }, [currentActionState])

  const handleActionClick = (selectedAction) => {
    const calendlyDemoUrl = customUtils.calendlyDemoUrl;
    switch (selectedAction?.id) {
      case "onboardingGuide":
        onCreateChatItem({chatMessage: selectedAction?.display, readOnly: true, actionBy: "user"});
        onActionClick(selectedAction);
        break;
      case "automationHelp":
        onCreateChatItem({chatMessage: selectedAction?.display, readOnly: true, actionBy: "user"});
        window.open(calendlyDemoUrl, "_blank");
        break;
      case "getExpert":
        onCreateChatItem({chatMessage: selectedAction?.display, readOnly: true, actionBy: "user"});
        window.open(calendlyDemoUrl, "_blank");
        break;
      case "createScenario":
        onCreateChatItem({chatMessage: selectedAction?.display, readOnly: true, actionBy: "user"});
        onActionClick(selectedAction);
        break;
      default:
        onCreateChatItem({chatMessage: "Discarded previous actions", readOnly: true, actionBy: "user"});
        onReset();
        break;
    }
  }

  return (
    <Box>
      <Paper sx={{ width: "100%", background: "#f5f5f5", borderRadius: "4px"  }}>
        <MenuList sx={{ padding: "0px 16px", border: "1px solid #e4e4e4", borderRadius: "4px"}}>
          {actions.map((action, index) => {
            const isLast = index === actions.length - 1;
            if (action.type === "element") {
              return (<div key={action.id}>{action.element}</div>);
            } else {
              return (
                <MenuItem key={action.id} onClick={() => {handleActionClick(action)}} sx={{p: 0, py: "9px", borderBottom: (!isLast ? "1px solid #e4e4e4" : "")}}>
                  <ListItemIcon sx={{color: "#6241D4"}}>{action.icon}</ListItemIcon>
                  <ListItemText>{action.display}</ListItemText>
                </MenuItem>
              )
            }
          })}
        </MenuList>
      </Paper>
    </Box>
  )
}

export default ActionPicker;
