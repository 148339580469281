import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MetricsComponent = ({ testData, responseTimeData }) => {
  const maxResponseTimeValue = responseTimeData && Array.isArray(responseTimeData) ? Math.max(...responseTimeData.map(d => parseFloat(d.average_response_time))) : 0;
  const maxResponseTime = maxResponseTimeValue ? Math.ceil(maxResponseTimeValue / 50) * 50 : 100;

  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={testData}
          margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" label={{ value: 'Date', position: 'bottom', offset: 0 }} />
          <YAxis label={{ value: 'Total Tests', angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          <Legend verticalAlign="bottom" wrapperStyle={{ bottom: -10 }} />
          <Line type="monotone" dataKey="no_of_tests" stroke="#8884d8" name="Total Test Cases" />
          <Line type="monotone" dataKey="no_of_tests_passed" stroke="#82ca9d" name="Passed Test Cases" />
          <Line type="monotone" dataKey="no_of_tests_failed" stroke="#ff7300" name="Failed Test Cases" />
        </LineChart>
      </ResponsiveContainer>

      <ResponsiveContainer width="100%" height={300} style={{ marginTop: 50 }}>
        <LineChart
          data={responseTimeData}
          margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" label={{ value: 'Date', position: 'bottom', offset: 0 }} />
          <YAxis label={{ value: 'Response Time', angle: -90, position: 'insideLeft' }} domain={[0, maxResponseTime]} />
          <Tooltip />
          <Legend verticalAlign="bottom" wrapperStyle={{ bottom: -10 }} />
          <Line type="monotone" dataKey="average_response_time" stroke="#8884d8" name="Average Response Time" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MetricsComponent;
