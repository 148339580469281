import React, { useState, useRef, useEffect } from "react";
import MonacoEditor from "react-monaco-editor";
import { Box, Snackbar, Alert } from '@mui/material';

const BodyRaw = ({ body, onBodyChange }) => {
  const [bodyRaw, setBodyRaw] = useState(JSON.stringify(body, undefined, 4));
  const [error, setError] = useState(null);
  const editorRef = useRef(null);

  const handleChange = (newBodyRaw) => {
    setBodyRaw(newBodyRaw);
    setError(null);
  };

  const handleOutsideClick = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      try {
        const jsonObject = JSON.parse(bodyRaw);
        onBodyChange(jsonObject);
      } catch (err) {
        setError("Invalid JSON format. Please correct it before saving.");
      }
    }
  }

  useEffect(() => {
    setBodyRaw(JSON.stringify(body, undefined, 4));
  }, [body]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [bodyRaw]);

  return (
    <Box ref={editorRef} sx={{ height: "200px", border: "1px solid #e9e9e9", margin: "10px 0", borderRadius: '5px' }}>
      <MonacoEditor
        language="javascript"
        theme="vs-light"
        value={bodyRaw}
        onChange={handleChange}
        options={{
          automaticLayout: true,
          wordWrap: "on",
          minimap: { enabled: false },
          lineNumbers: "on",
          tabSize: 4,
          fontFamily: "Inter, Sans-serif",
          fontWeight: "550",
          fontSize: "12px"
        }}
      />
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BodyRaw;