import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Select, MenuItem, ListSubheader, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { forkCollection } from '../../redux-store/collectionActions';
import { fetchOrganizations } from '../../redux-store/dashboardActions';
import { useSnackbar } from '../../contexts/CustomSnackbarContext';

const ForkDialog = ({ selectedCollection, open, handleClose }) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  const [newCollectionName, setNewCollectionName] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchOrgsAndProjects = async () => {
      const res = await dispatch(fetchOrganizations());

      if (res.payload?.length > 0) {
        setOrganizations(res.payload);
      }
    };
  
    fetchOrgsAndProjects();
    setNewCollectionName(selectedCollection?.name + ' (Copy)')
  }, []);

  const handleForkCollectionConfirmed = () => {
    handleForkCollection();

    setNewCollectionName('');
    setSelectedProjectId('');
  };

  const handleForkCollection = async () => {
    const res = await dispatch(forkCollection({ collectionId: selectedCollection.id, collectionName: newCollectionName, projectId: selectedProjectId }));

    if (res.payload?.collection)
      openSnackbar({ message: 'Please wait while the collection is being forked', severity: 'info' });
    else if (res.payload?.error)
      openSnackbar({ message: 'Failed to fork collection', severity: 'error' });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Fork this collection?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Forking this collection will create a duplicate collection under your account. Are you sure you want to proceed?
        </DialogContentText>
        <Typography style={{ marginTop: '16px' }}>New Collection Name</Typography>
        <Typography variant="caption" style={{ marginTop: '16px' }}>Give a name to the duplicated collection</Typography>
        <TextField
          autoFocus
          margin="dense"
          id="new-collection-name"
          type="text"
          fullWidth
          variant="standard"
          value={newCollectionName ? newCollectionName : selectedCollection?.name + ' (Copy)'}
          onChange={(e) => setNewCollectionName(e.target.value)}
          required
        />

        <Typography style={{ marginTop: '16px' }}>Select Project</Typography>
        <Typography variant="caption" style={{ marginTop: '16px' }}>Select the project you want to duplicate the collection into</Typography>
        <Select
          size="small"
          labelId="project-select-label"
          id="project-select"
          value={selectedProjectId}
          onChange={(e) => setSelectedProjectId(e.target.value)}
          fullWidth
          displayEmpty
          required
        >
          {organizations.map((org) => [
            <ListSubheader key={org.id} disabled>Organization: {org.name}</ListSubheader>,
            ...org.projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
            ))
          ])}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleForkCollectionConfirmed} disabled={!newCollectionName || !selectedProjectId}>Fork</Button>
      </DialogActions>
    </Dialog>
  )
};

export default ForkDialog;
