import { apiAgent } from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateLoadError,
  setLoadingState,
  setSavingState,
  setAllVariableSets,
  updateSelectedVariableSetDetail,
} from './variableSetSlice';
import { VARIABLE_SETS_PAGE_SIZE } from '../../config/constants';

const createVariableSet = createAsyncThunk(
  'createVariableSet',
  async ({ projectId, title }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variable_sets/`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          project_id: projectId,
          title: title,
        },
      });
      if (response.status === 201) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getVariableSet = createAsyncThunk(
  'getVariableSet',
  async ({ variableSetId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variable_sets/${variableSetId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        console.log(response?.data);
        await dispatch(
          updateSelectedVariableSetDetail({
            variableSetDetail: response?.data,
          })
        );
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const updateVariableSet = createAsyncThunk(
  'updateVariableSet',
  async ({ variableSetId, data }, { dispatch }) => {
    await dispatch(setSavingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variable_sets/${variableSetId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data,
      });
      if (response.status === 200) {
        await dispatch(
          updateSelectedVariableSetDetail({
            variableSetDetail: response?.data,
          })
        );
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const deleteVariableSet = createAsyncThunk(
  'deleteVariableSet',
  async ({ variableSetId }, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variable_sets/${variableSetId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 204) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const attachVariableSet = createAsyncThunk(
  'attachVariableSet',
  async (
    { variableSetId, resourceId, resourceType },
    { dispatch, rejectWithValue }
  ) => {
    await dispatch(setSavingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variable_set_resources`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          resource_id: resourceId,
          resource_type: resourceType,
          variable_set_id: variableSetId,
        },
      });
      if (response.status === 201) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const detachVariableSet = createAsyncThunk(
  'detachVariableSet',
  async ({ variableSetResourceId }, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variable_set_resources/${variableSetResourceId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 204) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getAllVariableSets = createAsyncThunk(
  'getAllVariableSets',
  async ({ projectId, isEnvironment=false, skipEnvironment=false, pageNumber, pageSize=VARIABLE_SETS_PAGE_SIZE }, { dispatch }) => {
    const params = {
      project_id: projectId,
      page: pageNumber,
      page_size: pageSize
    };
    if (!skipEnvironment) {
      params.is_environment = isEnvironment;
    }
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variable_sets`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: params,
      });
      if (response.status === 200) {
        if (isEnvironment) {
          await dispatch(setAllVariableSets(response.data.variable_sets));
        }
        return response.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export {
  createVariableSet,
  updateVariableSet,
  deleteVariableSet,
  getVariableSet,
  attachVariableSet,
  detachVariableSet,
  getAllVariableSets,
};
