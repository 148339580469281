import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Box, TextField, Select, MenuItem, Button, Card, CardContent, Grid, IconButton, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OrganizationForm from '../Settings/OrganizationForm';

import { createOrganisation } from '../../redux-store/currentUserActions';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const OrganizationScreen = ({ onChild }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {openSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () =>{
    onChild();
  }

  const handleSubmit = async (data) => {
    setIsLoading(true);
    const response = await dispatch(createOrganisation(data))
    if (response?.payload) {
      openSnackbar({ message: "Organization created successfully!", severity: 'success' });
      setIsLoading(false);
      onChild();
      history.push("/dashboard");
    } else {
      openSnackbar({ message: "Organization creation failed!", severity: 'error' });
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      <Card variant="outlined">
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Create Organization</Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <OrganizationForm onSubmit={handleSubmit} isLoading={isLoading} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default OrganizationScreen;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
