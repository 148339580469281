import {
  Box,
  Grid,
  Typography,
  Stack,
  Pagination,
  Divider,
  Avatar,
  Toolbar
} from '@mui/material';
import { Person, LocalOffer } from '@mui/icons-material';
import { useState, useEffect, useRef } from 'react';

import LoadingOverlay from '../components/Common/LoadingOverlay';
import CustomChip from '../components/Common/CustomChip';
import SideDrawer from '../components/Common/SideDrawer';

const MainLayout = ({
  children,
  isLoading = false,
  headerEditable = false,
  headerText = '',
  subtitleText = '',
  code = null,
  status = null,
  owner = null,
  tags = [],
  customSubHeadComponent = null,
  ctaButton = <></>,
  withPagination = true,
  totalPages = 0,
  pageNumber = 1,
  sx = {},
  onPageChange = () => {},
  onHeaderChange = () => {},
  rightSideContent = null,
  renderDrawerItem = () => {return null;},
  drawerItems = [],
  renderHeader,
  enableSideDrawer = false,
  sxSideDrawer={},
  selectedItemContent=null,
  noHeader=false,
  sxLeftSide={},
  contentStyle= {}
}) => {
  const inputRef = useRef(null);
  const [localHeaderText, setLocalHeaderText] = useState(headerText);
  const [isHeaderEditing, setIsHeaderEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    const handleOutsideClick = async (event) => {
      if (
        headerEditable &&
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        isHeaderEditing
      ) {
        if (localHeaderText !== headerText) onHeaderChange(localHeaderText);

        setIsHeaderEditing(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [headerEditable, headerText, localHeaderText, isHeaderEditing, onHeaderChange]);

  useEffect(() => {
    if (headerText !== localHeaderText) setLocalHeaderText(headerText);
  }, [headerText]);

  useEffect(() => {
    if (!isHeaderEditing && localHeaderText !== headerText)
      setLocalHeaderText(headerText);
  }, [isHeaderEditing]);

  useEffect(() => {

  })

  const onDrawerClick = (selectedItem) => {
    console.log(selectedItem);
    setSelectedItem(prevState => selectedItem);
    renderDrawerItem({selectedItem, onDrawerClose: onDrawerClose});
  };

  const onDrawerClose = () => {
    setSelectedItem('');
  };

  const renderSubHeadComponent = () => {
    if (customSubHeadComponent) return customSubHeadComponent
  
    if (owner || tags.length) return (
      <Stack
        my="8px"
        direction="row"
        alignItems="center"
        spacing={1.5}
        divider={
          <Divider
            sx={{ borderColor: 'black', borderWidth: '1.5px' }}
            orientation="vertical"
            flexItem
          />
        }
      >
        {owner && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Person />
            <Avatar
              alt={owner.name}
              src={owner.avatar}
              sx={{ width: 24, height: 24 }}
            />
            <Typography variant="body1">{owner.name}</Typography>
          </Stack>
        )}

        {tags.length ? (
          <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1}>
            <LocalOffer />

            {tags.map((tag) => (
              <CustomChip key={tag} label={tag} />
            ))}
          </Stack>
        ) : null}
      </Stack>
    )
    return null;
  };

  return (
    <>
      {!noHeader && <Box className="toolbar-container" sx={{borderBottom: "none", height: "100px", position: "relative", zIndex: 10, overflow: "hidden", boxShadow: "0px 0px 2px 0.5px #4d4d4d"}}>
          <Toolbar variant="dense" className="tool-bar-style" sx={{zIndex: 2, height: "100%"}}>
            <Grid container sx={{ padding: '8px 20px' }} alignItems="center">
              <Grid item className="leftSide" sx={{maxWidth: "40%", ...sxLeftSide}}>
                {
                  renderHeader ? renderHeader() : (
                    <>
                      <h4
                        className="truncate-1-lines"
                        style={{
                          margin: '0px',
                          color: 'black',
                          fontSize: '24px',
                          cursor: headerEditable ? 'pointer' : 'default',
                        }}
                        onClick={headerEditable ? () => setIsHeaderEditing(true) : () => {}}
                      >
                        {localHeaderText}
                      </h4>
                      <Typography variant="body1" color="gray" className="truncate-1-lines">
                        {subtitleText}
                      </Typography>
                    </>
                  )
                }
              </Grid>
              {
                rightSideContent && (
                  <Grid className="margin-set">
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                      {rightSideContent}
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </Toolbar>
        </Box>
      }
      <Box sx={{ p: "24px", maxHeight: "calc(100vh - 100px)", width: enableSideDrawer ? "calc(100% - 86px)" : "100%", ...contentStyle }}>
        {children}
        <LoadingOverlay isLoading={isLoading} />
        {(withPagination && totalPages > 1) && (
          <Stack spacing={2} sx={{ margin: '8px', alignItems: 'center' }}>
            <Pagination
              count={totalPages}
              page={pageNumber}
              onChange={onPageChange}
            />
          </Stack>
        )}
      </Box>
      {
        enableSideDrawer && (
          <SideDrawer
            drawerItems={drawerItems}
            onChange={onDrawerClick}
            selectedItem={selectedItem}
            onClose={onDrawerClose}
            disabled={selectedItem.disabled}
            sx={{sxSideDrawer}}
          >
            {selectedItemContent}
          </SideDrawer>
        )
      }
    </>
  );
};

export default MainLayout;
