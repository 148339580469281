import React, { useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Divider,
  Modal
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarState } from '../../redux-store/currentUserSlice';
import { getEntity } from '../../redux-store/currentUserActions';
import Samples from '../Samples';
import LoadingOverlay from '../Common/LoadingOverlay';

const Audit = ({ auditData, detailedData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentApiDetails, setCurrentApiDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user.selectedState);

  const handleOpenModal = async (api) => {
    setCurrentApiDetails(null);
    setOpenModal(true);
    setIsLoading(true);

    try {
      const response = await dispatch(getEntity({ id: api.entity_id }));

      if (response?.payload) {
        await dispatch(setSidebarState(response.payload));
        setCurrentApiDetails(response.payload);
      }
    } catch (error) {
      console.error('Failed to fetch API record details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiTableContainer-root': {
      overflowY: 'auto',
      scrollbarColor: '#dcdff1 #eef0f6',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#dcdff1',
        outline: '1px solid #eef0f6',
      },
    },
    '& .MuiPaper-root': {
      backgroundColor: '#fafafa',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
    },
  };

  const containerTableStyles = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiTableContainer-root': {
      overflowY: 'auto',
      scrollbarColor: '#dcdff1 #eef0f6',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#dcdff1',
        outline: '1px solid #eef0f6',
      },
    },
    '& .MuiPaper-root': {
      backgroundColor: '#fafafa',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
    },
    height: '100% !important', 
    overflowY: 'scroll !important'
  };
  
  const tableStyle = {
    backgroundColor: '#ffffff',
    '& .MuiTableCell-head': {
      color: '#333',
      fontWeight: 'bold',
      backgroundColor: '#f0f2f5',
      padding: '8px 16px',
    },
    '& .MuiTableRow-root:hover': {
      backgroundColor: '#f5f5f5',
    },
    '& .MuiTableCell-body': {
      color: '#333',
      padding: '8px 16px',
    },
  };
  
  const paginationStyle = {
    '& .MuiToolbar-root': {
      color: '#333',
    },
    '& .Mui-selected': {
      backgroundColor: '#eef0f6',
    },
    '& .MuiSvgIcon-root': {
      color: '#333',
    },
    backgroundColor: '#f0f2f5',
    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
      color: '#333',
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '90vh',
    overflow: 'auto',
    borderRadius: '5px'
  };

  return (
    <Box className="audit-component" sx={{ height: '700vh !important', overflowY: 'scroll !important' }}>
      <Typography variant="h5" sx={{ marginBottom: '10px' }}>
        API Vulnerability and Fudge Testing
      </Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: '20px' }}>
        Showing results for API Vulnerability and Fudge Testing
      </Typography>

      <Box sx={ containerStyles }>
        <Paper sx={{ height: '99%', overflow: 'auto', '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none' }}>
          <TableContainer sx={{ width: '100%', height: 'calc(100% - 2px)', overflowY: 'auto', flexGrow: 1 }}>
            <Table aria-label="simple" sx={ tableStyle } >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center' }}>CATEGORY</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>TESTS</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>ISSUES</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>STATUS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditData?.map((row) => (
                  <TableRow key={row.category}>
                    <TableCell>{row.category}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.tests}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.issues}</TableCell>
                    <TableCell sx={{ 
                      textAlign: 'center', 
                      color: row.status === "PASSED" ? 'green !important' : row.status === "FAILED" ? 'red !important' : 'inherit !important'
                    }}>
                      {row.status}
                  </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {/* <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        component="div"
        count={10}
        rowsPerPage={10}
        page={0}
        // onPageChange={handleChangePage}
        // onRowsPerPageChange={handleChangeRowsPerPage}
        sx={ paginationStyle }
      /> */}

      <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
      <Typography variant="h5" sx={{ marginBottom: '10px' }}>
        API Vulnerability and Fudge Testing - Detailed Results
      </Typography>


      <Box sx={ containerTableStyles }>
        <Paper sx={{ height: '99%', overflow: 'auto', '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none' }}>
          <TableContainer sx={{ width: '100%', height: 'calc(100% - 2px)', overflowY: 'auto', flexGrow: 1 }}>
            <Table aria-label="simple" sx={ tableStyle } >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center' }}>CATEGORY</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>VULNERABILITY</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>DESCRIPTION</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>RESPONSE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {detailedData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textAlign: 'center' }}>{row.category}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.vulnerability}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.response || ''}</TableCell>
                  </TableRow>
                ))} */}

                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>A05:2021 - Security Misconfiguration</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Vulnerability Type B</TableCell>
                    <TableCell>The recent security configuration analysis has concluded, revealing a mix of passed and failed checks in our system's setup. Several critical issues were identified under A05:2021 - Security Misconfiguration, indicating areas where default configurations were not adequately secured or unnecessary services were left enabled. A portion of the configurations passed our security standards, suggesting that some system components are properly secured against potential vulnerabilities.</TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>A01:2021 - Broken Access Control</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Vulnerability Type A</TableCell>
                    <TableCell>The assessment identified several significant vulnerabilities related to inadequate access controls, indicating areas where unauthorized users might gain access to privileged functions or data. On a positive note, a part of our access control systems successfully passed the evaluation, showcasing effective restrictions and permissions that protect sensitive information and functionalities. Urgent attention and corrective measures are needed to address these vulnerabilities to prevent potential unauthorized access and ensure data integrity and confidentiality.</TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>A07:2021 - Identification and Authentication Failures</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Vulnerability Type A</TableCell>
                    <TableCell>No issues were found for this vulnerability. All tests passed. </TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>A09:2021 - Security Logging and Monitoring Failures</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Vulnerability Type B</TableCell>
                    <TableCell>Certain critical deficiencies were pinpointed, highlighting areas where logging and monitoring mechanisms are insufficient or improperly configured. This may lead to an inability to detect or respond to security incidents effectively. Conversely, several aspects of our logging and monitoring setup have met or exceeded the required security standards, demonstrating a capable defense against some forms of cyber threats. It is crucial to address the identified shortcomings promptly to prevent potential security incidents from going unnoticed.</TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>A03:2021 - Injection</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Vulnerability Type B</TableCell>
                    <TableCell>The assessment uncovered several instances where our applications are susceptible to injection attacks, including SQL, Command, and LDAP injections. These vulnerabilities arise from improper input validation and sanitization, allowing attackers to execute unauthorized commands or access data. On a brighter note, parts of our applications were found to implement robust input validation and sanitization techniques, effectively mitigating the risk of certain types of injection attacks. Immediate remediation of identified vulnerabilities is essential to protect our applications from potential injection attacks, which could lead to data breaches or loss of data integrity.</TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>A10:2021 - Server-Side Request Forgery (SSRF)</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Vulnerability Type A</TableCell>
                    <TableCell>Notable vulnerabilities were detected in several areas, demonstrating susceptibility to SSRF attacks where external or internal requests can be manipulated by attackers to access unauthorized resources. However, certain components of our system exhibited strong defenses against SSRF, showcasing effective validation and filtering of outbound requests to prevent unauthorized access to internal services or data. To prevent potential data breaches or unauthorized access to internal systems, immediate action is required to rectify these vulnerabilities.</TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>A02:2021 - Cryptographic Failures</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Vulnerability Type A</TableCell>
                    <TableCell>No issues were found for this vulnerability. All tests passed. </TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>A04:2021 - Insecure Design</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Vulnerability Type B</TableCell>
                    <TableCell>The review identified multiple instances of insecure design principles, including lack of proper security controls, insufficient data protection mechanisms, and inadequate threat modeling, which could lead to potential vulnerabilities and exploitation. Positively, certain elements and components were acknowledged for their adherence to secure design principles, demonstrating a proactive approach to security and risk management. Addressing the identified insecure design elements is paramount to enhancing our system's resilience against sophisticated attacks and ensuring the protection of sensitive information.</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {/* <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        component="div"
        count={10}
        rowsPerPage={10}
        page={0}
        // onPageChange={handleChangePage}
        // onRowsPerPageChange={handleChangeRowsPerPage}
        sx={ paginationStyle }
      /> */}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} pt={2}>
          { isLoading ? (
            <LoadingOverlay isLoading={isLoading} />
          ) : currentApiDetails ? (
            <Samples state={state} api={currentApiDetails} />
          ) : (
            <Typography variant="body1" sx={{ p: 2 }}>
              Unable to load details.
            </Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default Audit;
