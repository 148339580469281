import React, {useState} from 'react'
import { Box, TextField, Button, Grid, Typography, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import coreUtils from '../../utils/coreUtils';
import CustomButton from '../Common/CustomButton';
import { Close, Add, InfoOutlined } from '@mui/icons-material'
import MonacoEditor from "react-monaco-editor";
import LoadingOverlay from '../Common/LoadingOverlay';

import { generateRuleWithAI, createTestRule } from '../../redux-store/testRulesReducers/testRulesActions';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const TestRuleCustom = ({onSave, testCaseSetId, onCancel}) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [ruleTitle, setRuleTitle] = useState("");
  const [ruleExpression, setRuleExpression] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  const handleTitleChange = (event) => {
    setRuleTitle(event.target.value);
    setErrorMsg("");
  }
  
  const handleExpressionChange = (value) => {
    setRuleExpression(value);
    setErrorMsg("");
  }
  const onGenerate = async () => {
    setIsGenerating(true);
    const response = await dispatch(generateRuleWithAI({ruleText: ruleTitle, testCaseSetId: testCaseSetId}));
    if (response?.payload && !response?.error) {
      console.log(response.payload);
      if (response?.payload?.js_expression) {
        setRuleExpression(response.payload.js_expression);
        setIsGenerating(false);
        // openSnackbar({ message: "Test rule !", severity: 'success' });
      } else {
        openSnackbar({ message: "Unable to understand the rule text, please write rule expression yourself!", severity: 'error' });
        setIsGenerating(false);
      }
    } else {
      openSnackbar({ message: response?.payload?.error || "Failed to genearate rule expression, please try again!", severity: 'error' });
      setIsGenerating(false);
    }
  }

  const onCancelClick = () => {
    resetState();
    onCancel();
  }

  const resetState = () => {
    setErrorMsg("");
    setRuleExpression("");
    setRuleTitle("");
  }

  const onSaveRule = async () => {
    if (!coreUtils.isStringInvalidOrBlank(ruleTitle) && !coreUtils.isStringInvalidOrBlank(ruleExpression)) {
      const response = await dispatch(createTestRule({testCaseSetId: testCaseSetId, title: ruleTitle.trim(), expression: ruleExpression.trim()}));
      if (response?.payload) {
        openSnackbar({message: "Rule created successfully!", severity: "success"});
      } else {
        openSnackbar({message: "Rule creation failed!", severity: "error"});
      }
      onSave(response?.payload);
      resetState();
    } else {
      setErrorMsg("Invalid rule title or expression.");
    }
  }

  return (
    <Box sx={{mt: 1}}>
      <Grid container alignItems="center">
        <Grid item sx={{width: "calc(100% - 155px)"}}>
          <TextField size="small" disabled={isGenerating} variant="outlined" placeholder="Test rule title" fullWidth name="ruleTitle" value={ruleTitle} onChange={handleTitleChange} />
        </Grid>
        <Grid item justifySelf="bottom">
          <Button variant="outlined" disabled={!ruleTitle || isGenerating} onClick={onGenerate} sx={{ml: 1, fontWeight: "550"}}>
            Generate with AI
            <LoadingOverlay isLoading={isGenerating} />
          </Button>
        </Grid>
      </Grid>
      <Box sx={{border: "1px solid #e4e4e4", borderRadius: "4px", p: 1, my: 2}}>
        <Typography sx={{mb: "4px", fontWeight: 550, position: "relative"}}>
          function () {"{"}
          <Tooltip 
            title="Function should always return a boolean value."
            placement="left-end"
            sx={{fontFamily: "Inter, sans-serif", fontWeight: "550"}}
          >
            <InfoOutlined size="small" sx={{position: "absolute", right: 0, cursor: "pointer"}} />
          </Tooltip>
        </Typography>
        <MonacoEditor
          className="jsEditor"
          language="javascript"
          theme="vs-dark"
          value={ruleExpression}
          height="150"
          onChange={handleExpressionChange}
          style={{borderBottom: "1px solid #e4e4e4"}}
          options={{
            automaticLayout: true,
            wordWrap: "on",
            minimap: { enabled: false },
            lineNumbers: "off",
            tabSize: 4,
            overviewRulerLanes: 0
          }}
        />
        {/* <LoadingOverlay isLoading={isGenerating} /> */}
        <Typography sx={{fontWeight: 550}}>{"}"}</Typography>
      </Box>
      {errorMsg && <Typography color="error" sx={{mt: 1, mb: 1}}>{errorMsg}</Typography>}
      <CustomButton startIcon={<Add />} sx={{mr: 1}} disabled={!ruleExpression || !ruleTitle} onClick={onSaveRule}>Save</CustomButton>
      <CustomButton startIcon={<Close />} onClick={onCancelClick}>Cancel</CustomButton>
    </Box>
  )
}

export default TestRuleCustom;
