import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import BuildLevel from "./BuildLevel";

import { getBuild as getPlanBuild } from "../../../redux-store/testPlanBuildReducers/testPlanBuildActions";
import { getBuild as getSuiteBuild } from "../../../redux-store/testSuiteBuildReducers/testSuiteBuildActions";
import { getBuild as getScenarioBuild } from "../../../redux-store/testScenarioBuildReducers/testScenarioBuildActions";
import { setBuilds as setPlanBuilds } from "../../../redux-store/testPlanBuildReducers/testPlanBuildSlice";
import { setBuilds as setSuiteBuilds } from "../../../redux-store/testSuiteBuildReducers/testSuiteBuildSlice";
import { setBuilds as setScenarioBuilds } from "../../../redux-store/testScenarioBuildReducers/testScenarioBuildSlice";

const levelMap = {
  testPlan: {
    id: "test_plan",
    type: "level",
    name: "Test Plan",
    childBuildKey: "test_suite_builds",
    childBuildType: "testSuite",
    buildIdKey: "testPlanBuildId",
    sourceCodePrefix: "TP-0",
  },
  testSuite: {
    id: "test_suite",
    type: "level",
    name: "Test Suite",
    childBuildKey: "test_scenario_builds",
    childBuildType: "testScenario",
    buildIdKey: "testSuiteBuildId",
    sourceCodePrefix: "TSS-0",
  },
  testScenario: {
    id: "test_scenario",
    type: "level",
    name: "Test Scenario",
    childBuildKey: "test_case_builds",
    childBuildType: "testCase",
    childType: "log",
    buildIdKey: "testScenarioBuildId",
    sourceCodePrefix: "TS-0",
  },
};

const BuildContainer = ({ buildType="testPlan", buildId }) => {
  const dispatch = useDispatch();
  
  const getBuild = async (buildType, buildId) => {
    const levelInfo = getLevelInfo(buildType);
    const childBuildKey = levelInfo.childBuildKey;
    let buildDetail, childBuilds;
    if(buildType === "testPlan") {
        const planResponse = await dispatch(getPlanBuild({ testPlanBuildId: buildId }));
        buildDetail = planResponse?.payload;
        await dispatch(setPlanBuilds({[buildDetail?.id]: {...buildDetail}}));
        childBuilds = buildDetail[childBuildKey] || [];
        for (let i = 0; i < childBuilds.length; i++)
          await dispatch(setSuiteBuilds({[childBuilds[i].id]: childBuilds[i]}));
    } else if(buildType === "testSuite"){
        const suiteResponse = await dispatch(getSuiteBuild({testSuiteBuildId: buildId}));
        buildDetail = suiteResponse?.payload;
        await dispatch(setSuiteBuilds({[buildDetail?.id]: {...buildDetail}}));
        childBuilds = buildDetail[childBuildKey] || [];
        for (let i = 0; i < childBuilds.length; i++)
          await dispatch(setScenarioBuilds({[childBuilds[i].id]: childBuilds[i]}));
    } else if (buildType === "testScenario") {
        const scenarioResponse = await dispatch(getScenarioBuild({ testScenarioBuildId: buildId }));
        buildDetail = scenarioResponse?.payload;
        await dispatch(setScenarioBuilds({[buildDetail?.id]: {...buildDetail}}));
    }
  };

  const getLoadingState = (buildType, buildId) => {

  }

  const getLevelInfo = useCallback((levelType) => {
    return levelMap[levelType];
  }, []);

  console.log("Rerendering - build container");

  return (
    <Box sx={{height: "calc(100vh - 50px)", pb: 20}}>
      <BuildLevel
        buildType={buildType}
        getBuild={getBuild}
        buildId={buildId}
        getLevelInfo={getLevelInfo}
        getLoadingState={getLoadingState}
        expanded={true}
        root={true}
      />
    </Box>
  );
};

export default BuildContainer;
