import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Tabs,
  Tab,
} from '@mui/material';
import Runners from './Runners';
import RunsTriggered from './RunsTriggered';

const RunnerComponent = ({ resource }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <Runners resource={resource}/>;
      case 1:
        return <RunsTriggered resource={resource}/>;
      default:
        return <Runners resource={resource}/>;
    }
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Box display="flex" justifyContent="flex-start">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ height: '32px', minHeight: '32px !important', border: '1px solid #e9e9e9', borderRadius: '5px' }}
        >
          <Tab
            label="Runners"
            style={{
              height: '32px',
              minHeight: '32px',
              textTransform: 'none',
              backgroundColor: 'transparent'
            }}
          />
          <Tab
            label="Runs Triggered"
            style={{
              height: '32px',
              minHeight: '32px',
              textTransform: 'none',
              backgroundColor: 'transparent'
            }}
          />
        </Tabs>
      </Box>
      <Box>{renderTabContent()}</Box>
    </Box>
  );
};

export default RunnerComponent;
