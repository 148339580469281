import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, Box, Typography, Button, TextField, Paper, Select, MenuItem,
  Accordion, AccordionSummary, AccordionDetails, List, ListItem, Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useDispatch } from 'react-redux';
import { executeSampleTestCase, updateTestCase } from '../../redux-store/testCaseActions';
import { fetchEntityVariables } from '../../redux-store/entityActions';
import LoadingOverlay from '../Common/LoadingOverlay';
import { useSnackbar } from '../../contexts/CustomSnackbarContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'row',
  maxHeight: '90vh',
  overflow: 'auto',
  borderRadius: '5px'
};

function TestModal({ entity, open, selectedTestCase, handleClose, handleCreateTestCase }) {
  const dispatch = useDispatch();
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [testInput, setTestInput] = useState('');
  const [evaluations, setEvaluations] = useState([]);
  const [lhs, setLhs] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState(selectedTestCase?.priority || 0);
  const [isEditing, setIsEditing] = useState(false);
  const [inputError, setInputError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExecutingTest, setIsExecutingTest] = useState(false);
  const originalLhs = useRef('');
  const originalDescription = useRef('');
  const originalPriority = useRef(0);
  const [accordionContent, setAccordionContent] = useState({});
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchEntityVariablesData = async () => {
      setIsLoading(true);

      try {
        const res = await dispatch(fetchEntityVariables({ entityId: entity?.id }));
        if (res.payload) {
          setAccordionContent(Object.entries(res.payload).reduce((acc, [key, value]) => ({
            ...acc,
            [key]: JSON.stringify(value, null, 2)
          }), {}));
        }
      } catch (error) {
        console.error('Failed to fetch entity variables:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedTestCase) {
      setIsEditing(true);
      setLhs(selectedTestCase.lhs || '');
      setDescription(selectedTestCase.description || '');
      setPriority(selectedTestCase.priority);

      originalLhs.current = selectedTestCase.lhs || '';
      originalDescription.current = selectedTestCase.description || '';
      originalPriority.current = selectedTestCase.priority;
    } else {
      setIsEditing(false);
      setLhs('');
      setDescription('');
      setPriority(0);
    }

    fetchEntityVariablesData();
  }, [dispatch, entity?.id, selectedTestCase]);

  const getTestData = () => {
    const data = {
      id: selectedTestCase?.id,
      testCase: {
        lhs: lhs,
        description: description,
        priority: priority
      }
    }

    return data;
  }

  const hasTestCaseChanged = () => {
    return lhs !== originalLhs.current || description !== originalDescription.current || priority !== originalPriority.current;
  };

  const isSaveButtonEnabled = () => {
    if (isEditing) {
      return hasTestCaseChanged();
    } else {
      return lhs?.trim() !== '' && description?.trim() !== '';
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpandedPanel(newExpanded ? panel : null);
  };

  const handleInputChange = (event) => {
    setTestInput(event.target.value);
  };

  const handleNewTestCase = async () => {
    if (!testInput?.trim()) {
      setInputError('Can\'t be empty.');
      return;
    }

    setIsLoading(true);
    setInputError('');

    await handleCreateTestCase(testInput);
    setIsEditing(false);
    setIsLoading(false);
    setTestInput('')

    originalLhs.current = lhs;
    originalDescription.current = description;
    originalPriority.current = priority;
  }

  const handleUpdateTest = async () => {
    if (selectedTestCase) {
      setIsExecutingTest(true)
      await dispatch(updateTestCase(getTestData()));
      setIsExecutingTest(false);
    }
  };

  const handleCreateOrUpdateTestCase = async () => {
    if (selectedTestCase) {
      await updateTestCase(getTestData());
    } else {
      handleNewTestCase()
    }
  };

  const handleTestCaseExecution = async () => {
    setIsExecutingTest(true);

    // const response = await dispatch(executeSampleTestCase({ entityId: entity?.id, testCaseId: selectedTestCase?.id }));
    const response = await dispatch(executeSampleTestCase({ entityId: entity?.id, jsStatement: lhs, responseId: entity?.response?.id }));

    if (response && response?.payload) {
      const currentTime = new Date();
      const formattedTime = currentTime.getHours().toString().padStart(2, '0') + ':' + 
                            currentTime.getMinutes().toString().padStart(2, '0') + ':' + 
                            currentTime.getSeconds().toString().padStart(2, '0');
      
      const formattedDate = currentTime.getDate().toString().padStart(2, '0') + ' ' + 
                            currentTime.toLocaleString('default', { month: 'short' }) + ' ' + 
                            currentTime.getFullYear().toString().slice(-2);

      const status = response?.payload &&
                    (response.payload?.result === true || response.payload?.result === false) ? response.payload?.result
                    : response?.payload && response.payload?.error ? response.payload?.error : '-';

      const newEvaluation = {
        time: formattedTime,
        date: formattedDate,
        status: status
      };

      setEvaluations([...evaluations, newEvaluation]);
      setIsExecutingTest(false);

      if (response.payload?.error) openSnackbar({ message: response.payload?.error, severity: 'error' });
    } else {
      openSnackbar({ message: "Something went wrong.", severity: 'error' });
      setIsExecutingTest(false);
    }
  }

  return (
    <Modal
      open={open}
      onClose={(e) => { setEvaluations([]); handleClose(e); }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box pr={2} display="flex" flexDirection="column" sx={{ width: '70%' }}>
          { !selectedTestCase &&
            <>
              <Box display="flex" alignItems="flex-start">
                <TextField
                  error={!!inputError}
                  helperText={inputError || ' '}
                  fullWidth
                  size='small'
                  placeholder="Write a test case in plain English"
                  variant="outlined"
                  sx={{ mr: 1 }}
                  onChange={handleInputChange}
                />
                <Button variant="contained" sx={{ ml: 1, size: 'small', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '6px 14px' }} onClick={handleNewTestCase}>Create Test</Button>
              </Box>
              <Divider orientation='horizontal' originalDescription='OR' />
            </>
          }
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <Select
              id="priority"
              value={ priority }
              onChange={(e) => { setPriority(e.target.value) }}
              style={{ marginRight: '10px' }}
              sx={{
                '& .MuiSelect-select': {
                  padding: '8.5px 14px'
                }
              }}
            >
              {['P0', 'P1', 'P2', 'P3', 'P4', 'P5'].map((priority, index) => (
                <MenuItem key={index} value={index}>{priority}</MenuItem>
              ))}
            </Select>

            <TextField fullWidth size='small' placeholder="Write a description" variant="outlined" value={ description } onChange={(e) => { setDescription(e.target.value) }} />
          </Box>
          <Paper style={{ backgroundColor: '#333', color: '#fff', borderRadius: '5px', marginBottom: '20px' }}>
            <Box sx={{ backgroundColor: '#333', color: '#fff', borderRadius: '5px' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ maxHeight: 25, padding: '0 10px', borderBottom: '1px solid #555' }}
              >
                <Typography variant="body1" style={{ flexGrow: 1, fontFamily: 'monospace', fontSize: '11px' }}>
                  javascript
                </Typography>
              </Box>
              <Typography variant="body2" style={{ flexGrow: 1, padding: '5px 10px', fontFamily: 'monospace', color: '#999' }}>
                { `// Add your test case below this line` }
              </Typography>
              <TextField
                fullWidth
                multiline
                minRows={1}
                variant="outlined"
                value={lhs}
                onChange={(e) => { setLhs(e.target.value) }}
                InputProps={{
                  style: {
                    backgroundColor: '#333', 
                    color: '#fff', 
                    borderColor: '#555',
                    borderRadius: '5px',
                    border: 'none',
                    padding: '5px 10px',
                    fontFamily: 'monospace'
                  },
                  sx: {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    }
                  }
                }}
              />
            </Box>
          </Paper>
          <Box sx={{ position: 'relative' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="h6">Evaluation</Typography>
              <Box>
                <Button size="small" variant="outlined" sx={{ mx: 0.5 }} onClick={handleUpdateTest} disabled={!isSaveButtonEnabled()}>Save Test</Button>
                <Button size="small" variant="outlined" sx={{ mx: 0.5 }} onClick={handleTestCaseExecution} disabled={!lhs}>Run Test</Button>
              </Box>
            </Box>
            <List>
              {evaluations.map((evl, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <Box display="flex" justifyContent="space-between" width="70%">
                      <Typography variant="body2" width="20%">{evl.date}</Typography>
                      <Typography variant="body2" width="20%">{evl.time}</Typography>
                      <Typography variant="body2" width="60%">{typeof evl.status === "boolean" ? (evl.status ? 'True' : 'False' ) : evl.status }</Typography>
                    </Box>
                  </ListItem>
                  {index < evaluations.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
            <LoadingOverlay isLoading={isExecutingTest} />
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box pl={2} sx={{ width: '30%' }}>
          <Typography variant="h6">Variables</Typography>
          {Object.entries(accordionContent).map(([key, value], index) => (
            <Accordion key={key} expanded={expandedPanel === key} onChange={handleChange(key)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`${key}-header`}>
                <Typography>{`{{ ${key} }}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SyntaxHighlighter language="json" style={materialDark}>
                  {value}
                </SyntaxHighlighter>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <LoadingOverlay isLoading={isLoading} />
      </Box>
    </Modal>
  );
}

export default TestModal;
