import React, { useState, useEffect } from 'react';
import { Card, CardContent, Tabs, Tab, Switch, TableContainer, Box, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { fetchVariables, updateEntity } from '../../redux-store/currentUserActions';
import { useDispatch, useSelector } from 'react-redux';
import TestCases from './TestCases';
import LoadingOverlay from '../Common/LoadingOverlay';

const TestResponse = ({ entity }) => {
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);
  const [selectedTab, setSelectedTab] = useState('variables');
  const [showVariable, setShowVariable] = useState(selectedSidebarState?.enable_variables);
  const [entityVariables, setEntityVariables] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSidebarState) {
      setShowVariable(selectedSidebarState.enable_variables);
      if (showVariable) fetchEntityVariables();
    }
  }, [selectedSidebarState, showVariable]);

  const fetchEntityVariables = async () => {
    setIsLoading(true);
    try {
      const state = {
        id: selectedSidebarState.id,
      };
      const res = await dispatch(fetchVariables(state));
      if (res?.payload) {
        setEntityVariables(res?.payload);
      }
    } catch (error) {
      console.error("Failed to fetch entity variables:", error);
    }
    setIsLoading(false);
  };

  const handleSwitchToggle = async () => {
    setIsLoading(true);
    try {
      const newState = !showVariable;
      const res = await dispatch(updateEntity({
        id: selectedSidebarState.id,
        enable_variables: newState,
      }));
    
      if (res?.payload?.entity?.enable_variables === newState) {
        setShowVariable(newState);
        if (selectedTab === 'variables') {
          await fetchEntityVariables();
        }
      }
    } catch (error) {
      console.error("Failed to toggle switch:", error);
    }
    setIsLoading(false);
  };

  const hiddenScrollbarStyle = {
    overflowY: 'auto',
    maxHeight: 'calc(50vh)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    'msOverflowStyle': 'none',
    'scrollbarWidth': 'none'
  };

  return (
    <div style={{ width: '100%', padding: '0px 11px', position: 'relative' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => {
            setSelectedTab(newValue);
            // dispatch(setSelectedResponseSubTab(newValue === 'test_cases' ? 0 : 1));
          }}
          indicatorColor="primary"
          sx={{ height: '32px', minHeight: '32px', border: '1px solid #e9e9e9', borderRadius: '5px' }}
        >
          {/* <Tab style={tabStyle} label="Test Cases" value="test_cases" /> */}
          <Tab style={tabStyle} label="Variables" value="variables" />
          <Tab style={tabStyle} label="Settings" disabled />
        </Tabs>
        {/* <Box display="flex" alignItems="center">
          <IconButton color="primary">
            <Search />
          </IconButton>
          <IconButton color="primary">
            <ContentCopyRounded />
          </IconButton>
        </Box> */}
      </Box>

      {selectedTab === 'test_cases' && <TestCases entity={ entity } />}

      {selectedTab === 'variables' && (
        <Card sx={{ ...hiddenScrollbarStyle, display: 'flex', flexDirection: 'column', marginTop: '10px', minHeight: '250px', width: '100%', overflowY: 'auto', paddingBottom: "50px" }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <Typography sx={{fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"}}>{showVariable ? "Enable Response as Variables" : "Disable"}</Typography>
              <Switch
                checked={showVariable}
                onChange={handleSwitchToggle}
                size='small'
                sx={{ marginLeft: '10px' }}
              />
            </Box>
            {showVariable && (
              <TableContainer sx={{ mt: 2, border: '1px solid #e9e9e9', borderRadius: '5px', marginBottom: '100px' }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px" }}>Key</TableCell>
                      <TableCell sx={{ fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px" }}>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entityVariables.map((variable, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ wordBreak: 'break-word', whiteSpace: 'normal', fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px" }}>{variable?.key}</TableCell>
                        <TableCell sx={{ wordBreak: 'break-word', whiteSpace: 'normal', fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px" }}>{variable?.initial_value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      )}
      <LoadingOverlay isLoading={isLoading} />
    </div>
  );
};

const tabStyle = { height: '32px', minHeight: '32px', textTransform: 'none', color: '#575757', backgroundColor: 'transparent', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "11px" };

export default TestResponse;
