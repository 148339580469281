import React, { useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Grid, Card, Box, Button, TextField, FormControlLabel, Checkbox, InputLabel, Autocomplete } from '@mui/material';
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import {
  createOrganisation,
  createProject,
} from "../../redux-store/currentUserActions";
import "../../styles/App.scss"
import "./index.css";
import axios from "axios";

const HomeScreen = () => {
  const history = useHistory();
  const _dispatch = useDispatch();
  const timeZones = moment.tz.names();
  const [state, setState] = useReducer(reducer, {
    companyName: "",
    companyUrl: "",
    companyLocation: "",
    projectName: "",
    projectUrl: "",
    flexCheck: "",
    Checked: false,
    isLoading: false,
    errors: {},
  });

  useEffect(() => {
    const fetchUserTimezone = async () => {
      const timezone = await getUserTimezone();
      if (timezone) {
        setState({
          type: "SET_STATE",
          payload: { companyLocation: timezone },
        });
      }
    };

    fetchUserTimezone();
  }, []);

  const handleInputChange = (e, value, reason) => {
    console.log(e, value, reason);
    if (reason === 'selectOption' || reason === 'clear') {
      setState({
        type: "SET_STATE",
        payload: { companyLocation: value },
      });
    } else if (e && e.target) {
      const { name, value } = e.target;
      setState({
        type: "SET_STATE",
        payload: { [name]: value },
      });
    }
};

  const handlecheckChange = async (e) => {
    const { name, checked } = e.target;

    const newState = {
      type: "SET_STATE",
      payload: { [name]: checked },
    };

    if (name == "flexCheck" && checked && state.flexCheck !== true) {
      newState.payload.projectName = state.companyName;
    }

    if (name == "flexCheck1" && checked && state.flexCheck1 !== true) {
      newState.payload.projectUrl = state.companyUrl;
    }

    if (name == "flexCheck" && checked == false) {
      newState.payload.projectName = "";
    }

    if (name == "flexCheck1" && checked == false) {
      newState.payload.projectUrl = "";
    }

    await setState(newState);
  };

  const getUserTimezone = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/json?token=ea5265807ee5a1`);
      const timezone = response.data.timezone;

      return timezone;
    } catch (error) {
      console.error('Error fetching user timezone:', error);
      return null;
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!state.companyName) errors.companyName = "Please enter company name.";
    if (!state.companyUrl) errors.companyUrl = "Please enter company URL.";
    if (!state.companyLocation) errors.companyLocation = "Please select time zone.";
    if (!state.projectName) errors.projectName = "Please enter project name.";
    if (!state.projectUrl) errors.projectUrl = "Please enter project URL.";
    return errors;
  };
  
  const handleSubmit = async () => {
    const errors = validateForm();
    setState({ type: "SET_STATE", payload: { errors } });
  
    if (Object.keys(errors).length === 0) {
      setState({ type: "SET_STATE", payload: { isLoading: true } });
  
      const res = await _dispatch(createOrganisation(state));
      if (res.payload?.organisation?.id) {
        const newValue = { organisationId: res.payload.organisation.id };
        const newState = Object.assign({}, state, newValue);
        await _dispatch(createProject(newState));
        history.push("/dashboard?showGuide=1");
      }
    }
    else {
      setState({ type: "SET_STATE", payload: { isLoading: false } });
    }
  };  

  const onFormSubmit = async () => {
    await handleSubmit();
  };  

  const { user } = useSelector((state) => state.user);

  const _errors = useSelector((state) => state.user.errors) || {};
  var email_error = "";
  var username_error = "";

  if (
    _errors &&
    _errors !== "Unvalidated User" &&
    Object.keys(_errors).length > 0
  ) {
    _errors?.map((err) => {
      if (err.split(" ")[0] == "Email") {
        email_error = err;
      }
      if (err.split(" ")[0] == "Username") {
        username_error = err;
      }
    });
  }

  return (
    <Container maxWidth="md" className="full-height">
      <Grid container spacing={2} className="d-flex justify-content-center align-items-center h-100">
        <Grid item xs={12} md={6} className="column-margin">
          <Card className="company-grid-card">
            <div className="right-column">
              <Box className="heading-container">
                <Box className="create-account-title">
                  Add your company details
                </Box>
              </Box>
              <Box>
                <InputLabel htmlFor="email" className="input-label">Company Name</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="companyName"
                  value={state.companyName}
                  onChange={handleInputChange}
                  error={Boolean(state.errors["companyName"])}
                  helperText={state.errors["companyName"] || ' '}
                />
                <InputLabel htmlFor="email" className="input-label">Company URL</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="companyUrl"
                  value={state.companyUrl}
                  onChange={handleInputChange}
                  error={Boolean(state.errors["companyUrl"])}
                  helperText={state.errors["companyUrl"] || ' '}
                />
                <InputLabel htmlFor="email" className="input-label">Time Zone</InputLabel>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={state.companyLocation}
                  onChange={handleInputChange}
                  options={timeZones}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label=""
                      error={Boolean(state.errors["companyLocation"])}
                      helperText={state.errors["companyLocation"] || ' '}
                    />
                  )}
                />
                <InputLabel htmlFor="email" className="input-label">Project Name</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="projectName"
                  value={state.projectName}
                  onChange={handleInputChange}
                  error={Boolean(state.errors["projectName"])}
                  helperText={state.errors["projectName"]}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="flexCheck"
                      color="primary"
                      checked={state.flexCheck}
                      onChange={handlecheckChange}
                    />
                  }
                  label="Same as company name"
                />
                <InputLabel htmlFor="email" className="input-label">Project Url</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="projectUrl"
                  value={state.projectUrl}
                  onChange={handleInputChange}
                  error={Boolean(state.errors["projectUrl"])}
                  helperText={state.errors["projectUrl"]}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="flexCheck1"
                      color="primary"
                      checked={state.flexCheck1}
                      onChange={handlecheckChange}
                    />
                  }
                  label="Same as company url"
                />
              </Box>
              <div className="btn_login">
                <Button
                  variant="contained"
                  onClick={onFormSubmit}
                  size="medium"
                  style={{ padding: '10px 0', width: '100%' }}
                >
                  Save
                </Button>
              </div>
              <div style={{ marginTop: "8px" }}></div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeScreen;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
};
