import { apiAgent } from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateLoadError, setSavingState } from './testSuiteScenarioSlice';

const attachScenariosWithSuites = createAsyncThunk(
  'attachScenariosWithSuites',
  async ({ testSuiteIds, testScenarioIds }, { dispatch }) => {
    await dispatch(setSavingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_suite_scenarios/attach`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          test_suite_ids: testSuiteIds,
          test_scenario_ids: testScenarioIds,
        },
      });
      if (response.status === 201) {
        console.log(response?.data);
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const detachScenarioFromSuite = createAsyncThunk(
  'detachScenarioFromSuite',
  async ({ testScenarioId, testSuiteId }, { dispatch }) => {
    await dispatch(setSavingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_suite_scenarios/detach`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          test_scenario_id: testScenarioId,
          test_suite_id: testSuiteId,
        },
      });
      if (response.status === 204) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export { attachScenariosWithSuites, detachScenarioFromSuite };
