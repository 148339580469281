import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Paper, Typography, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import TestModal from './AdvancedTestCaseEditModal';
import LoadingOverlay from '../Common/LoadingOverlay';
import { useDispatch } from 'react-redux';
import { convertEnglishToTestCase, deleteTestCase } from '../../redux-store/testCaseActions';
import { getEntityTestCases, updateEntity, getResponseApiTests } from '../../redux-store/currentUserActions';
import { useSnackbar } from '../../contexts/CustomSnackbarContext';

const AdvancedTestCase = ({ entity, openAddTestModal, handleOpenAddTestModal, handleCloseAddTestModal, generatedTestCases = [], setGeneratedTestCases }) => {
  const dispatch = useDispatch();
  const currentTestResponse = entity?.response || {};
  const [testInput, setTestInput] = useState('');
  const [testCases, setTestCases] = useState(generatedTestCases);
  const [selectedTestCase, setSelectedTestCase] = useState(null);
  const [apiTest, setApiTests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchTestCases() {
      setIsLoading(true);

      try {
        const response = await dispatch(getEntityTestCases({ id: entity?.id, testType: 'advanced' }));
        setTestCases(response?.payload || []);
      } catch (error) {
        console.error('Error fetching test cases:', error);
      }

      setIsLoading(false);
    }

    async function fetchResponseApiTests() {
      setIsLoading(true);

      try {
        const response = await dispatch(getResponseApiTests({response_id: currentTestResponse?.id}))
        if(response?.payload?.api_tests){
          setApiTests(response?.payload?.api_tests);
        }
      } catch (error) {
        console.error('Error fetching api tests:', error);
      }

      setIsLoading(false);
    }

    if (entity?.id){
      fetchTestCases();
      fetchResponseApiTests();
    }
  }, [entity?.id]);

  useEffect(() => {
    if (!openAddTestModal){
      setSelectedTestCase(null);
    }
  }, [openAddTestModal]);

  useEffect(() => {
    setTestCases(generatedTestCases);
  }, [generatedTestCases]);

  const handleInputChange = (event) => {
    setTestInput(event.target.value);
  };

  const handleCreateTestCase = async (statement = undefined) => {
    try {
      setIsLoading(true);
      const response = await dispatch(convertEnglishToTestCase({
        entityId: entity.id, 
        englishStatement: statement || testInput
      }));

      if (response && response?.payload) {
        if (response.payload.test_case) {
          const newTestCase = response.payload.test_case;
          const updatedTestCases = [...testCases, newTestCase];

          setTestCases(updatedTestCases);
          setSelectedTestCase(newTestCase);

          // When at least one `advanced` test is created, mark the entity's test_type as `advanced`
          await updateEntityTestType();
        } else if (response.payload.error) {
          openSnackbar({ message: response.payload.error, severity: 'error' });
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error creating test:', error);
    }
  };
  
  const updateEntityTestType = async () => {
    try {
      const response = await dispatch(updateEntity({ 
        id: entity?.id, 
        testType: 'advanced' 
      }));
  
      if (response && response?.payload && response.payload?.entity) {
        // TODO: Post-entity-update action?
      }
    } catch (error) {
      console.error('Error updating entity test type:', error);
    }
  };  

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
  };

  const handleDeleteTestCase = (index, row) => {
    if (row.id) {
      dispatch(deleteTestCase(row.id))
        .then((action) => {
          if (action.payload && action.payload.success) {
            const updatedRows = [...testCases];
            updatedRows.splice(index, 1);
            setTestCases(updatedRows);
            setGeneratedTestCases(updatedRows);
          }
        })
        .catch((error) => {
          console.error("Failed to delete test case:", error);
        });
    } else {
      const updatedRows = [...testCases];
      updatedRows.splice(index, 1);
      setTestCases(updatedRows);
      setGeneratedTestCases(updatedRows);
    }
  }

  const TestListItem = ({ testCase, index }) => {
    const findApiTestStatus = (testCaseId) => {
      const matchingApiTest = apiTest.find(apiTest => apiTest.test_case_id === testCaseId);
      return matchingApiTest?.status;
    };

    const status = findApiTestStatus(testCase.id);

    return (
      <Box sx={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
        <Box flex={1}>
          <Box>
            <Typography variant="body1" style={{ marginTop: 5, marginBottom: 5 }}>
              <strong>{`P${testCase.priority || 0}: `}</strong>{testCase.description}
            </Typography>
          </Box>
          <Box key={index} sx={{ backgroundColor: '#333', color: '#fff', borderRadius: '5px' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ maxHeight: 25, padding: '0 10px', borderBottom: '1px solid #555' }}
            >
              <Typography variant="body1" style={{ flexGrow: 1, fontFamily: 'monospace', fontSize: '11px' }}>
                javascript
              </Typography>
              {
                testCase.lhs &&
                <IconButton onClick={() => { if (testCase.id) { setSelectedTestCase(testCase); handleOpenAddTestModal(); } }} size="small" style={{ color: 'white', marginRight: '8px' }}>
                  <EditIcon style={{ fontSize: '0.8rem' }} />
                  <Typography variant="caption" style={{ marginLeft: '4px' }}>Edit</Typography>
                </IconButton>
              }
              { testCase.lhs &&
                <IconButton onClick={() => { if (testCase.id) { handleCopyCode(testCase.lhs); } }} size="small" style={{ color: 'white' }}>
                  <ContentCopyIcon style={{ fontSize: '0.8rem' }} />
                  <Typography variant="caption" style={{ marginLeft: '4px' }}>Copy</Typography>
                </IconButton>
              }
            </Box>
            <Box>
              { !testCase.lhs &&
                <Typography variant="body2" style={{ flexGrow: 1, padding: '5px 10px', fontFamily: 'monospace' }}>
                  {`// Replace with your test case`}
                </Typography>
              }
              <SyntaxHighlighter language="javascript" style={dark} customStyle={{ background: '#333', margin: 0, border: 0, boxShadow: 'none', padding: '5px 10px' }}>
                {testCase.lhs}
              </SyntaxHighlighter>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: status === true ? 'green' : status === false ? 'red' : 'orange', marginRight: '10px' }}>
          { testCase.lhs &&
            <Typography variant="body1">{ status === true ? 'Passed' : status === false ? 'Failed' : 'Running' }</Typography>
          }
        </Box>
        <Box sx={{ width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          { testCase.lhs &&
            <IconButton onClick={() => handleDeleteTestCase(index, testCase)} size="small">
              <DeleteIcon />
            </IconButton>
          }
        </Box>
      </Box>
    );
  }

  const TestList = () => {
    return (
      <Paper style={{ marginTop: '10px', border: 'none', boxShadow: 'none' }}>
        {
          testCases.length > 0 &&
          testCases.map((testCase, index) => <TestListItem testCase={ testCase } index={ index } />)
        }
        <TestListItem testCase={{}} index={ 'dummy' } />
        <LoadingOverlay isLoading={ isLoading } />
      </Paper>
    );
  };

  return (
    <Box sx={{ marginTop: '10px' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TextField
          placeholder="Write a test case in plain English"
          variant="outlined"
          value={testInput}
          onChange={handleInputChange}
          fullWidth
          size='small'
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => { handleCreateTestCase(); }}
          sx={{
            ml: 2,
            size: 'small',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '6px 14px'
          }}
        >
          Create Test
        </Button>
      </Box>

      <TestList />

      <TestModal
        entity={entity}
        selectedTestCase={selectedTestCase}
        open={openAddTestModal}
        handleClose={handleCloseAddTestModal}
        handleCreateTestCase={handleCreateTestCase}
        setSelectedTestCase={setSelectedTestCase}
      />
    </Box>
  );
};

export default AdvancedTestCase;
