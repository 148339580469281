import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import TestCaseSetBody from './TestCaseSetBody';
import TestCaseSetHeader from './TestCaseSetHeader';
import LoadingOverlay from "../Common/LoadingOverlay";

import { fetchFlatCollectionEntities, getEntity } from '../../redux-store/currentUserActions';
import { createTestCaseSet, updateTestCaseSet, cloneTestCaseSet } from "../../redux-store/testCaseSetReducers/testCaseSetActions";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

import "../../styles/components/TestScenarios/TestCaseSet.scss";

const TestCaseSet = function({ detail, onExpand, isExpanded, onClickDelete, onClickEdit, updateList }) {
  const dispatch = useDispatch();
  const [collection, setCollection] = useState(detail.collection || null);
  const [entity, setEntity] = useState(detail.entity || null);
  const [testCaseDetail, setTestCaseDetail] = useState(detail);
  const { openSnackbar } = useSnackbar();
  const isLoading = useSelector(state => state.testCaseSet.isLoading);
  const [isSaving, setIsSaving] = useState(false);
  const selectedTestScenarioDetail = useSelector(state => state.testScenario.selectedTestScenarioDetail);
  const collections = useSelector(state => (state.user.collections));
  const allCollectionEntities = useSelector(state => (state.user.flatCollectionEntities));
  const testCaseSetList = useSelector(state => (state.testCaseSet.testCaseSetList));

  useEffect(() => {
    // console.log(detail);
  }, []);

  const getEntities = async (collectionId) => {
    if (!allCollectionEntities || !allCollectionEntities[collectionId])
      await dispatch(fetchFlatCollectionEntities(collectionId));
  }

  const onCollectionChange = async (event, newValue) => {
    setCollection(newValue);
    setEntity(null);
    if (newValue) {
      getEntities(newValue.id);
    }
    setTestCaseSet({collection: newValue, entity: null});
  }

  const onEntityChange = async (event, newValue) => {
    if (collection?.id) getEntities(collection.id);
    setEntity(newValue);
    if (newValue) {
      onSave(newValue);
    }
  }

  const onClickClone = async () => {
    if (!detail.isNew) {
      const response = await dispatch(cloneTestCaseSet({testCaseSetId: detail.id, title: `${detail?.title} - clone`}))
      updateList([...testCaseSetList, response?.payload]);
      openSnackbar({message : "Test case cloned successfully and added at the end.", severity: "success"});
    }
  }

  const onSave = async (entity) => {
    setIsSaving(true);
    if (testCaseDetail.isNew) {
        const newTestCaseData = await dispatch(createTestCaseSet({
            testScenarioId: selectedTestScenarioDetail.id,
            entityId: entity.id,
            title: testCaseDetail.title
        }));
        setIsSaving(false);
        if (newTestCaseData?.payload) {
          openSnackbar({message: "Test case created successfully", severity: "success"});
        } else {
          openSnackbar({message: "Test case creation failed! Please check if entity is valid.", severity: "error"});
          return;
        }
        setTestCaseSet({
            isNew: false,
            ...newTestCaseData.payload
        }, true);
    } else {
        const newTestCaseData = await dispatch(updateTestCaseSet({
            testCaseSetId: testCaseDetail.id,
            entityId: entity.id
        }));
        setIsSaving(false);
        if (newTestCaseData?.payload) {
          openSnackbar({message: "Test case updated successfully", severity: "success"});
        } else {
          openSnackbar({message: "Test case update failed! Please check if entity is valid.", severity: "error"});
          return;
        }
        setTestCaseSet({
            isNew: false,
            ...newTestCaseData.payload
        });
    }
  }

  const onSaveTitle = (title) => {
    if (testCaseDetail.isNew) {
      setTestCaseSet({title: title});
    } else {
      updateTestCaseSetDetail({title: title});
    }
  }

  const updateTestCaseSetDetail = async (data) => {
    setIsSaving(true);
    const newTestCaseData = await dispatch(updateTestCaseSet({
      testCaseSetId: testCaseDetail.id,
      ...data
    }));
    setIsSaving(false);
    if (newTestCaseData?.payload) {
      openSnackbar({message : "Test case updated successfully.", severity: "success"});
    } else {
      openSnackbar({message : "Test case update failed!", severity: "error"});
    }

    setTestCaseSet({
      isNew: false,
      ...newTestCaseData?.payload
    })
  }

  const setTestCaseSet = async (data, isNewCreated) => {
    const testCaseIndex = testCaseSetList.findIndex(testCase => testCase.id === testCaseDetail.id);
    let newList = [...testCaseSetList];
    newList[testCaseIndex] = {
        ...newList[testCaseIndex],
        ...data,
        test_case_set_response: data?.test_case_set_response || {}
    }
    setTestCaseDetail(state => (
        { ...state, ...data }
    ));
    updateList([...newList], isNewCreated);
  }

  let collectionEntities = [];
  if (collection && allCollectionEntities && allCollectionEntities[collection.id]) {
    collectionEntities = allCollectionEntities[collection.id];
    // console.log(collectionEntities);
  }

  return (
    <Box className="testCaseSet">
      <Accordion expanded={isExpanded} sx={{backgroundColor: "#FAFAFA"}} className="testCaseSetAccordion">
        <AccordionSummary
          aria-controls="panel1bh-content"
          id={testCaseDetail.id}
          sx={{ '&.Mui-focusVisible': { backgroundColor: "transparent" }}}
          className={`testCaseSetSummary ${isExpanded && "expanded"}`}
          onClick={(e) => {if (!testCaseDetail.isNew) onExpand(testCaseDetail.id)}}
        >
          {!isExpanded && (<LoadingOverlay isLoading={isSaving} />)}
          <TestCaseSetHeader isExpanded={isExpanded} disableActions={!collection || !entity} entities={collectionEntities} onSaveTitle={onSaveTitle} collections={collections || []} collection={collection}
            entity={entity} onCollectionChange={onCollectionChange} onEntityChange={onEntityChange} testCaseSet={testCaseDetail} onClickEdit={onClickEdit} onClickDelete={onClickDelete} onClickClone={onClickClone} />
        </AccordionSummary>
        <AccordionDetails>
          {
            isExpanded &&
            (<TestCaseSetBody testCase={testCaseDetail} onSave={updateTestCaseSetDetail} isExpanded={isExpanded} isDisabledActions={!collection || !entity || testCaseDetail.isNew} collection={collection} />)
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default TestCaseSet;
