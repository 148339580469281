import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const RouteRenderer = (route) => {
  const user = useSelector(state => state.user.current_user) || {};
  const token = localStorage.getItem('token');
  const isLoggedIn = () => {
    if (!user.id) {
      const storedUser = localStorage.getItem("user");
      return token && storedUser;
    }
    return token && user.id;
  }

  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        (route.path === '/login' || route.path === '/') && isLoggedIn() ?
          <Redirect to="/collections" />
          :
          <route.layout isPostAuth={route.isPostAuth}>
            <route.component {...props} {...route.props} routes={route.routes} />
          </route.layout>
      )}
    />
  );
};

export default RouteRenderer;
