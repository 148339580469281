import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
// import JoditEditor from "jodit-pro-react";

const config = {
  readonly: false,
  placeholder: "",
  // uploader: {
  //   url: 'https://xdsoft.net/jodit/finder/?action=fileUpload'
  // },
  // filebrowser: {
  //   ajax: {
  //     url: 'https://xdsoft.net/jodit/finder/'
  //   },
  //   height: 580,
  // }
};

const TextEditor = ({initialContent="", onChange}) => {
  const [content, setContent] = useState("");
	const editorRef = useRef(null);

  useEffect(() => {
    setContent(initialContent);
  }, []);

  const handleChange = (content) => {
    try{
      setContent(content);
      onChange(content);
    } catch(e) {
      console.log(e);
    }
  }

	return (
		<JoditEditor
      ref={editorRef}
      value={content}
      config={config}
      tabIndex={1}
      onBlur={handleChange}
      onChange={handleChange}
    />
	);
};

export default TextEditor;
