import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem as MuiMenuItem,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Modal,
  Typography,
  Button,
  Divider
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "../Common/LoadingOverlay";
import { fetchResourceRunsTriggered, fetchRunsTriggeredApis, fetchProjectCollections, getEntity } from "../../redux-store/currentUserActions";
import Samples from "../Samples";
import { setSidebarState } from "../../redux-store/currentUserSlice";
import { VisibilityOutlined } from "@mui/icons-material";

const RunsTriggered = ({ resource, selectedProject }) => {
  const [selectedCollection, setSelectedCollection] = useState(resource ? resource : "all");
  const [collections, setCollections] = useState([]);
  const [collectionResource, setCollectionResource] = useState(null);
  const [runsTriggered, setRunsTriggered] = useState();
  const [runsTriggeredApis, setRunsTriggeredApis] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationMetadata, setPaginationMetadata] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showTestOnly, setShowTestOnly] = useState(false);
  const [currentApiDetails, setCurrentApiDetails] = useState(null);
  const state = useSelector((state) => state.user.selectedState);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCollections = async () => {
      const res = await dispatch(fetchProjectCollections(selectedProject.id));
      if (res?.payload?.data?.collections) {
        setCollections(res.payload.data.collections);
      }
    };

    if (selectedProject) {
      fetchCollections();
    }
  }, [selectedProject, dispatch]);

  useEffect(() => {
    const selectedResource = collections.find(c => c.id === selectedCollection);
    if (selectedResource && selectedResource !== collectionResource) {
      setCollectionResource(selectedResource);
    }
  }, [selectedCollection, collections]);

  useEffect(() => {
    const getRunsTriggered = async () => {
      if (!selectedProject?.id) return;

      setIsLoading(true);
      let state = { project_id: selectedProject?.id, id: collectionResource?.id };
  
      if (collectionResource) {
        if (collectionResource?.project_id) {
          state = { ...state, name: "collections" };
        } else {
          state = { ...state, name: "folders" };
        }
      }
      
      const res = await dispatch(fetchResourceRunsTriggered({ 
        page: page + 1,
        perPage: rowsPerPage,
        resource: state
      }));

      if (res?.payload?.data) {
        setRunsTriggered(res?.payload?.data);
        setPaginationMetadata(res?.payload?.meta);
      }
      setIsLoading(false);
    };
  
    if (collectionResource || selectedCollection === "all") {
      getRunsTriggered();
    }
  }, [selectedProject, collectionResource, selectedCollection, page, rowsPerPage, dispatch]);

  const handleOpenModal = async (api, testOnly = false) => {
    setCurrentApiDetails(null);
    setOpenModal(true);
    setIsLoading(true);
    setShowTestOnly(testOnly);

    try {
      const response = await dispatch(getEntity({ id: api.entity_id }));

      if (response?.payload) {
        await dispatch(setSidebarState(response.payload));
        setCurrentApiDetails(response.payload);
      }
    } catch (error) {
      console.error('Failed to fetch API record details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCollectionChange = (event) => {
    setSelectedCollection(event.target.value);

    const selectedResource = collections.find(c => c.id === event.target.value);
    setCollectionResource(selectedResource);
  };

  const handleRowClick = (index, runner) => {
    if (selectedRow === index) {
      setSelectedRow(null);
      setRunsTriggeredApis(null);
    } else {
      setSelectedRow(index);
      getRunsTriggeredAPIs(runner);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getRunsTriggeredAPIs = async (runner) => {
    setIsLoading(true);
    if (runner) {
      const res = await dispatch(
        fetchRunsTriggeredApis({ id: runner?.id })
      );
      if (res?.payload?.data) {
        setRunsTriggeredApis(res?.payload?.data);
      }
    }
    setIsLoading(false);
  };

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 190px)',
    overflow: 'hidden',
    position: 'relative',
    '& .MuiTableContainer-root': {
      overflowY: 'auto',
      scrollbarColor: '#dcdff1 #eef0f6',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#dcdff1',
        outline: '1px solid #eef0f6',
      },
    },
    '& .MuiPaper-root': {
      backgroundColor: '#fafafa',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
    },
  };
  
  const tableStyle = {
    backgroundColor: '#ffffff',
    '& .MuiTableCell-head': {
      color: '#333',
      fontWeight: 'bold',
      backgroundColor: '#f0f2f5',
      padding: '12px 16px',
    },
    '& .MuiTableRow-root:hover': {
      backgroundColor: '#f5f5f5',
    },
    '& .MuiTableCell-body': {
      color: '#333',
      padding: '6px 16px',
    },
  };
  
  const paginationStyle = {
    '& .MuiToolbar-root': {
      color: '#333',
    },
    '& .Mui-selected': {
      backgroundColor: '#eef0f6',
    },
    '& .MuiSvgIcon-root': {
      color: '#333',
    },
    backgroundColor: '#f0f2f5',
    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
      color: '#333',
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '90%',
    overflow: 'auto',
    borderRadius: '5px'
  };
  return (
    <Box sx={{ padding: '20px' }} className='runs-triggered-component'>
      <FormControl
        variant="outlined"
        style={{ width: "25%", marginBottom: "20px" }}
      >
        <InputLabel size="small">Select Collection</InputLabel>
        <Select
          size="small"
          value={selectedCollection}
          onChange={handleCollectionChange}
          label="Select Collection"
        >
          <MuiMenuItem value="all">
            <em>All Collections</em>
          </MuiMenuItem>
          {collections.map((collection) => (
            <MuiMenuItem key={collection.id} value={collection.id}>
              {collection.name}
            </MuiMenuItem>
          ))}
        </Select>
      </FormControl>

      <LoadingOverlay isLoading={isLoading} />
      <Box sx={ containerStyles }>
        <Paper sx={{ height: '99%', overflow: 'auto', '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none' }}>
          <TableContainer sx={{ width: '100%', height: 'calc(100% - 2px)', overflowY: 'auto', flexGrow: 1 }}>
            <Table aria-label="simple" sx={ tableStyle }>
              <TableHead>
                <TableRow sx={{alignItems: 'center' }}>
                  <TableCell sx={{ textAlign: 'center', textTransform: 'uppercase' }}>Entity</TableCell>
                  <TableCell sx={{ textAlign: 'center', textTransform: 'uppercase' }}>Start Time</TableCell>
                  <TableCell sx={{ textAlign: 'center', textTransform: 'uppercase' }}>Source</TableCell>
                  <TableCell sx={{ textAlign: 'center', textTransform: 'uppercase' }}>Avg. Latency</TableCell>
                  <TableCell sx={{ textAlign: 'center', textTransform: 'uppercase' }}>All Tests</TableCell>
                  <TableCell sx={{ textAlign: 'center', textTransform: 'uppercase' }}>Passed</TableCell>
                  <TableCell sx={{ textAlign: 'center', textTransform: 'uppercase' }}>Failed</TableCell>
                  <TableCell sx={{ textAlign: 'center', textTransform: 'uppercase' }}>Skipped</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {runsTriggered?.map((runner, index) => (
                  <>
                    <TableRow key={runner.id} selected={selectedRow === index}>
                      <TableCell>
                        <IconButton onClick={() => handleRowClick(index, runner)} sx={{ padding: '8px 0' }}>
                          {selectedRow === index ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                        {runner?.entity}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {runner?.start_time}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {runner?.source}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {runner?.avg_response_time}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {runner?.all_tests}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {runner?.tests_passed > 0 ? <span style={{ color: 'green', fontSize: '0.875rem' }}>{ runner?.tests_passed }</span> : runner?.tests_passed}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {runner?.tests_failed > 0 ? <span style={{ color: '#F44336', fontSize: '0.875rem' }}>{ runner?.tests_failed }</span> : runner?.tests_failed}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {runner?.tests_skipped}
                      </TableCell>
                    </TableRow>
                    {selectedRow === index && (
                      <>
                        {runsTriggeredApis?.map((api) => (
                          <>
                            <TableRow key={api.id}>
                              <TableCell>
                                <Button color="primary" onClick={() => handleOpenModal(api)} variant="text">
                                  {api?.entity}
                                </Button>
                                <IconButton color="primary" onClick={() => handleOpenModal(api, true)}>
                                  <VisibilityOutlined fontSize="10" />
                                </IconButton>
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                {api?.start_time}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                {api?.source}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                {api?.average_response_time}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                {api?.all_tests}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                { api?.tests_passed > 0 ? <span style={{ color: 'green' }}>{ api?.tests_passed }</span> : api?.tests_passed }
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                { api?.tests_failed > 0 ? <span style={{ color: '#F44336' }}>{ api?.tests_failed }</span> : api?.tests_failed }
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                {api?.tests_skipped}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        component="div"
        count={paginationMetadata.total_items || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={ paginationStyle }
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          { isLoading ? (
            <LoadingOverlay isLoading={isLoading} />
          ) : currentApiDetails ? (
            <Box flex={1}>
              <Typography variant="h6" sx={{ margin: '10px 16px' }}>{ currentApiDetails ? currentApiDetails?.name : '' }</Typography>
              <Samples state={state} api={currentApiDetails} showTestOnly={ showTestOnly } />
            </Box>
          ) : (
            <Typography variant="body1" sx={{ p: 2 }}>
              Unable to load details.
            </Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default RunsTriggered;
