import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Typography, Checkbox, Divider, Button, Tabs, Tab, IconButton, RadioGroup, Radio, FormControlLabel, InputLabel, Select, MenuItem, TextField, InputAdornment, Grid, List, ListItem, ListItemText, ListItemSecondaryAction, Chip } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResourceRunners, runResourceApis, createRunSchedule, getRunSchedules, deleteRunSchedule, fetchProjectMembers } from '../../redux-store/currentUserActions';
import CustomSnackbar from '../Common/CustomSnackbar';
import ManualIcon from '@mui/icons-material/Handyman';
import WebhookIcon from '@mui/icons-material/Link';
import ScheduleIcon from '@mui/icons-material/Schedule';
import InfoOutlinedIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useSnackbar } from '../../contexts/CustomSnackbarContext';
import { createSettings, fetchSettings, updateSettings } from '../../redux-store/settingsAction';

const Runners = ({resource}) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const selectedProject = useSelector(state => state.user.selectedProject);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [collectionApis, setCollectionApis] = useState();
  const [schedules, setSchedules] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [activeField, setActiveField] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formData, setFormData] = useState({
    scheduleName: '',
    runFrequency: '',
    hourlyOptions: '',
    days: [],
    time: ''
  });
  const [notificationSettings, setNotificationSettings] = useState({
    id: null,
    send_to_members: [],
    cons_failures: 3
  });
  const scheduleNameRef = useRef(null);
  const runFrequencyRef = useRef(null);
  const hourlyOptionsRef = useRef(null);
  const daysRef = useRef(null);
  const timeRef = useRef(null);
  
  useEffect(() => {
    if(resource){
      getRunners()

      // Assuming the webhook url is coming in the resource object (either it's Collection or Folder)
      if (resource?.webhook_url) setWebhookUrl(resource?.webhook_url)
    }
  }, [resource]);

  useEffect(() => {
    fetchSchedules();
    
    async function fetchMembers () {
      const res = await dispatch(fetchProjectMembers(selectedProject.id));
      if (res?.payload) {
        setTeamMembers(res?.payload.map(member => ({
          id: member.user_id,
          name: member.username
        })));
      }
    }

    async function fetchSettingsData() {
      const res = await dispatch(fetchSettings({ projectId: selectedProject.id, entityableId: resource?.id, entityableType: resource?.project_id ? 'Collection' : 'Folder' }));

      if (res?.payload && res?.payload.length === 1) {
        const _setting = res.payload[0];

        setNotificationSettings({
          id: _setting.id,
          send_to_members: _setting.send_to_members,
          cons_failures: _setting.cons_failures
        });
      }
    }

    if (selectedProject){
      fetchMembers();
      fetchSettingsData();
    }
  }, []);

  const getRunners = async () => {
    const state = setState();
    const res = await dispatch(fetchResourceRunners(state));
    if(res?.payload?.runners){
      setCollectionApis(res?.payload?.runners);
    }
  };

  const setState = () => {
    let state = {};
    if(resource?.project_id){
      state = {
        resource: 'collection', 
        id: resource?.id,
      };
    }else{
      state = {
        resource: 'folder', 
        id: resource?.id,
      };
    }
    return state;
  };

  const resetState = () => {
    setErrors({});
    setFormData({
      scheduleName: '',
      runFrequency: '',
      hourlyOptions: '',
      days: [],
      time: ''
    });
  }

  const fetchSchedules = async () => {
    try {
      const data = { project_id: resource?.project_id || selectedProject.id, resource_id: resource?.id };
      const response = await dispatch(getRunSchedules(data));

      if (response?.payload?.schedules) {
        setSchedules(response?.payload?.schedules);
      }
    } catch (error) {
      console.error('Failed to fetch schedules:', error);
    }
  };

  const handleApiCall = async () => {
    const state = setState();
    const res = await dispatch(runResourceApis(state));

    if(res?.payload === ""){
      openSnackbar({ message: 'Runs manually triggered successfully', severity: 'success'});
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  const handleMemberChange = (event) => {
    const { value } = event.target;
    setNotificationSettings(prevState => ({
      ...prevState,
      send_to_members: value
    }));
  };  
  
  const handleFailureCountChange = (event) => {
    const { value } = event.target;
    setNotificationSettings(prevState => ({
      ...prevState,
      cons_failures: value,
    }));
  };

  const handleSaveNotifications = async () => {
    const settingsData = {
      ...(notificationSettings.id && { id: notificationSettings.id }),
      entityable_id: resource?.id,
      entityable_type: resource?.project_id ? 'Collection' : 'Folder',
      send_to_members: notificationSettings.send_to_members,
      cons_failures: notificationSettings.cons_failures,
    };
  
    try {
      const response = notificationSettings.id ?
        await dispatch(updateSettings({ settings: settingsData, id: notificationSettings.id, projectId: selectedProject.id }))
        :
        await dispatch(createSettings({ settings: settingsData, projectId: selectedProject.id}));

      if (response?.payload?.setting?.id) {
        openSnackbar({ message: 'Notification settings updated successfully', severity: 'success' });
      } else {
        openSnackbar({ message: 'Failed to update notification settings', severity: 'error' });
      }
  
    } catch (error) {
      console.error('Failed to update notification settings:', error);
      openSnackbar({ message: 'Failed to update notification settings', severity: 'error' });
    }
  };  

  const handleDeleteSchedule = async (id) => {
    try {
      await dispatch(deleteRunSchedule({ id: id }));
      fetchSchedules();
    } catch (error) {
      console.error('Failed to delete schedule:', error);
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.scheduleName.trim()) {
      newErrors.scheduleName = 'Schedule Name is required';
    }
    if (!formData.runFrequency.trim()) {
      newErrors.runFrequency = 'Run Frequency is required';
    }
    if (formData.runFrequency === 'hourly' && !formData.hourlyOptions.toString().trim()) {
      newErrors.hourlyOptions = 'Hourly Option is required';
    }
    if (formData.runFrequency === 'weekly' && formData.days.length === 0) {
      newErrors.days = 'At least one day is required';
      if (!formData.time.trim()) newErrors.time = 'Time is required';
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const mapFormDataToBackend = () => {
    const { scheduleName, runFrequency, hourlyOptions, days, time } = formData;

    return {
      schedule: {
        schedule_name: scheduleName,
        run_frequency: runFrequency,
        hourly_options: hourlyOptions,
        time,
        days,
        resource_type: resource?.project_id ? 'Collection' : 'Folder',
        resource_id: resource?.id
      },
      project_id: resource?.project_id
    };
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const data = mapFormDataToBackend();
        const response = await dispatch(createRunSchedule(data));
        if(response?.payload?.id){
          fetchSchedules();
          setSnackbarOpen(true);
          resetState();
        }
      } catch (error) {
        console.error('Schedule creation failed:', error);
      }
    } else {
      console.error('Validation failed');
    }
  };

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setActiveField(name);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  return(
    <Box display="flex">
      <Box flex="1" paddingRight="10px" sx={{ maxHeight: '650px', overflowY: 'auto' }}>
        {collectionApis?.map((item) => (
          <Box key={item.id} display="flex" alignItems="center" m={1} mt={2}>
            <FolderOutlinedIcon />
            <KeyboardArrowRightIcon />
            {item?.entityable_type === 'Folder' && resource?.project_id && (
              <>
                <FolderOutlinedIcon />
                <KeyboardArrowRightIcon />
              </>
            )}
            <Typography variant="body1" sx={{ marginLeft: '5px', color: 'green' }}>
              {item?.request?.request_type}
            </Typography>
            <Typography variant="body1" sx={{ marginLeft: '20px' }}>
              {item?.name}
            </Typography>
          </Box>
        ))}
      </Box>

      <Divider orientation="vertical" flexItem style={{ height: '50vw' }} />

      <Box flex="2" paddingLeft="10px">
        <Tabs value={selectedTabIndex} onChange={handleTabChange} aria-label="functional and notifications tabs">
          <Tab label="Functional" />
          <Tab label="Notifications" />
        </Tabs>

        <TabPanel value={selectedTabIndex} index={0}>
          <Box>
            <Box display="flex" alignItems="center" justifyContent="flex-start" mb={2}>
              <Box display="flex" alignItems="center">
                <ManualIcon color="primary" fontSize='small' />
                <Typography sx={{ ml: 1, fontSize: '1rem' }}>
                  Run Manually
                </Typography>
              </Box>
              <Button variant="text" onClick={handleApiCall} sx={{ ml: 2 }}>
                <Typography sx={{ textDecoration: 'underline', fontSize: '0.875rem', fontWeight: '500' }}>
                  Click Here
                </Typography>
              </Button>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Box display="flex" alignItems="center" mb={2}>
              <WebhookIcon color="primary" fontSize='small' />
              <Typography sx={{ ml: 1, fontSize: '1rem' }}>
                Run via Webhook
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" mb={1}>
              <InputLabel htmlFor="webhookUrl" sx={{ fontSize: '0.875rem' }}>
                Webhook URL
              </InputLabel>
              <TextField
                size='small'
                name="webhookUrl"
                value={webhookUrl}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(webhookUrl);
                        }}
                      >
                        <FileCopyOutlined fontSize='small' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '0px'
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '7px 9px'
                  },
                  '& .MuiInputLabel-root': {
                    marginTop: '-3px'
                  },
                  marginTop: '5px',
                  marginBottom: '5px'
                }}
              />
            </Box>

            <Divider sx={{ my: 1 }} />

            <Box display="flex" alignItems="center" mb={2}>
              <ScheduleIcon color="primary" fontSize='small' />
              <Typography sx={{ ml: 1, fontSize: '1rem' }}>
                Schedule Runs
              </Typography>
            </Box>
            
            <Box>
              <InputLabel htmlFor="scheduleName" sx={{ fontSize: '0.875rem' }}>Schedule Name</InputLabel>
              <TextField
                autoFocus={activeField === 'scheduleName'}
                fullWidth
                inputRef={scheduleNameRef}
                name="scheduleName"
                value={formData.scheduleName}
                variant="outlined"
                size='small'
                error={!!errors.scheduleName}
                helperText={errors.scheduleName || ' '}
                onChange={handleInputChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '0px'
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '7px 9px'
                  },
                  '& .MuiInputLabel-root': {
                    marginTop: '-3px'
                  },
                  marginTop: '5px',
                }}
              />

              <InputLabel htmlFor="runFrequency" sx={{ fontSize: '0.875rem' }}>Run Frequency</InputLabel>
              <Select
                autoFocus={activeField === 'runFrequency'}
                fullWidth name="runFrequency" value={formData.runFrequency} onChange={handleInputChange} variant="outlined" size='small'
                error={!!errors.runFrequency} helperText={errors.runFrequency || ' '}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '0px'
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '7px 9px'
                  },
                  '& .MuiInputLabel-root': {
                    marginTop: '-3px'
                  },
                  marginTop: '5px',
                }}
              >
                <MenuItem value="hourly">Hourly</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
              </Select>

              {formData.runFrequency === 'hourly' && (
                <Box mt={2}>
                  <InputLabel htmlFor="hourlyOptions"  sx={{ fontSize: '0.875rem' }}>Hourly Option</InputLabel>
                  <Select
                    autoFocus={activeField === 'hourlyOptions'}
                    fullWidth name="hourlyOptions" variant="outlined" onChange={handleInputChange} size='small'
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 225
                        },
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        padding: '0px'
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '7px 9px'
                      },
                      '& .MuiInputLabel-root': {
                        marginTop: '-3px'
                      },
                      marginTop: '5px',
                    }}
                    value={formData.hourlyOptions}
                    error={!!errors.hourlyOptions}
                    helperText={errors.hourlyOptions || ' '}
                  >
                    {[1, 2, 4, 8, 12, 16, 20, 24].map(hour => (
                      <MenuItem value={hour} key={hour}>{`Every ${hour} hour${hour > 1 ? 's' : ''}`}</MenuItem>
                    ))}
                  </Select>
                </Box>
              )}

              {formData.runFrequency === 'weekly' && (
                <Grid container mt={2} columnSpacing={2}>
                  <Grid item xs={6}>
                    <InputLabel htmlFor="days" sx={{ fontSize: '0.875rem' }}>Days</InputLabel>
                    <Select
                      autoFocus={activeField === 'days'}
                      fullWidth name="days" multiple value={formData.days} variant="outlined" onChange={handleInputChange} size='small'
                      error={!!errors.days} helperText={errors.days || ' '}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          padding: '0px'
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '7px 9px'
                        },
                        '& .MuiInputLabel-root': {
                          marginTop: '-3px'
                        },
                        marginTop: '5px',
                      }}
                    >
                      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                        <MenuItem value={day} key={day}>{day}</MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel htmlFor="time" sx={{ fontSize: '0.875rem' }}>Time</InputLabel>
                    <Select
                      autoFocus={activeField === 'time'}
                      fullWidth name="time" variant="outlined" onChange={handleInputChange} size='small'
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 225
                          },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          padding: '0px'
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '7px 9px'
                        },
                        '& .MuiInputLabel-root': {
                          marginTop: '-3px'
                        },
                        marginTop: '5px',
                      }}
                      value={formData.time}
                      error={!!errors.time}
                      helperText={errors.time || ' '}
                    >
                      {[...Array(24).keys()].map(hour => {
                        const isPM = hour >= 12;
                        const formattedHour = hour % 12 || 12;
                        const formattedTime = `${formattedHour}${isPM ? 'pm' : 'am'}`;
                        return (
                          <MenuItem value={formattedTime} key={hour}>
                            {formattedTime}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              )}
            </Box>

            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                Save
              </Button>
            </Box>

            { schedules.length > 0 &&
              <Box mt={2}>
                <Divider sx={{ my: 1 }} />
                <Typography style={{ color: '#4f4f4f', fontSize: '1.075rem', fontWeight: '500' }}>Schedules</Typography>
                <List>
                  {schedules.map((schedule) => (
                    <ListItem key={schedule.id}>
                      <ListItemText
                        primary={schedule.schedule_name}
                        secondary={`Frequency: ${schedule.run_frequency}`}
                      />
                      <ListItemSecondaryAction
                        onClick={() => {
                        }}
                      >
                        <IconButton onClick={() => handleDeleteSchedule(schedule.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            }
          </Box>
        </TabPanel>

        <TabPanel value={selectedTabIndex} index={1}>
          <Box>
            <Box display="flex" alignItems="center" mb={2}>
              <EmailOutlinedIcon color="primary" fontSize='small' />
              <Typography sx={{ ml: 1, fontSize: '1rem' }}>
                Email Notifications
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
                Notification recipients
              </Typography>
              <InfoOutlinedIcon fontSize="small" />
            </Box>
            <InputLabel htmlFor="send_to_members" sx={{ fontSize: '0.875rem' }}>
              You can add up to 5 team members.
            </InputLabel>
            <Select
              size="small"
              id="notification-recipients"
              multiple
              name="send_to_members"
              value={notificationSettings.send_to_members}
              onChange={handleMemberChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={ teamMembers.find(t => t.id === value)?.name } />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
              fullWidth
            >
              {teamMembers.map((member) => (
                <MenuItem key={member.id} value={member.id}>
                  <Checkbox checked={notificationSettings.send_to_members.includes(member.id)} />
                  <ListItemText primary={member.name} />
                </MenuItem>
              ))}
            </Select>

            {/* <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Add team member"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{ marginBottom: 2 }}
            /> */}
            <InputLabel htmlFor="cons_failures" sx={{ fontSize: '0.875rem', marginTop: 2 }}>
              Stop notifications after
            </InputLabel>
            <TextField
              // autoFocus
              size="small"
              type="number"
              name="cons_failures"
              value={notificationSettings.cons_failures}
              onChange={handleFailureCountChange}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">consecutive failures</InputAdornment>,
              }}
              sx={{ width: 'auto', marginBottom: 2 }}
            />

            <Button variant="contained" color="primary" onClick={handleSaveNotifications} sx={{ mt: 1, display: 'block' }}>
              Save
            </Button>
          </Box>
        </TabPanel>
      </Box>

      {/* <Box flex="1" paddingLeft="10px">
        <Box display="flex" mb={1}>
          <Typography style={{ color: '#4f4f4f', fontSize: '1.075rem', fontWeight: '500' }}>
            Functional
          </Typography>
        </Box>
      </Box> */}

      <CustomSnackbar
        open={snackbarOpen}
        handleClose={() => setSnackbarOpen(false)}
        message="Schedule created successfully"
        severity="success"
        verticalPos='top'
        horizontalPos='right'
      />
    </Box>
  )
}


export default Runners;



// Commented Code for later use
{/* <Typography variant="h6" sx={{ marginTop: '20px' }}>Choose how to run the entity</Typography>
<Box sx={{ marginLeft: '10px', marginTop: '10px' }}>
  <Typography variant="h7">Run Manually
    <Link onClick={handleApiCall} sx={{ color: '#0BA3F8', fontSize: 'smaller', cursor: 'pointer', marginLeft: '10px' }}>
      click here
    </Link>
  </Typography>
  <Typography sx={{ marginTop: '5px' }}>Run this collection in the Collection Runner.</Typography>
</Box>
<Box sx={{ marginLeft: '10px', marginTop: '20px' }}>
  <Typography variant="h7">Run via Webhook</Typography>
  <Typography sx={{ marginTop: '5px' }}>
    Run this using webhook URL
    <Link sx={{ color: '#0BA3F8', fontSize: 'smaller', cursor: 'pointer', marginLeft: '5px' }}>
      qodex.ai/folder/12345/run?token=32323
    </Link>
  </Typography>
  <Box sx={{ marginTop: '20px' }}>
    <Box>
      <IconButton >
        <KeyboardArrowDown />
      </IconButton>
      <Typography variant="h7">Advanced Settings</Typography>
    </Box>
    <Box sx={{ marginLeft: '10px', marginTop: '10px' }}>
      <Box display="flex" alignItems="center">
        <Checkbox
          icon={<CheckBoxIcon style={{ color: '#1976d2' }} />}
          checkedIcon={<CheckBoxOutlineBlankIcon style={{ color: '#1976d2' }} />}
        />
        <Typography variant="body1" sx={{ marginLeft: '10px' }}>
          Stop run if an error occurs
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          icon={<CheckBoxIcon style={{ color: '#1976d2' }} />}
          checkedIcon={<CheckBoxOutlineBlankIcon style={{ color: '#1976d2' }} />}
        />
        <Typography variant="body1" sx={{ marginLeft: '10px' }}>
          Keep variable values
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          icon={<CheckBoxIcon style={{ color: '#1976d2' }} />}
          checkedIcon={<CheckBoxOutlineBlankIcon style={{ color: '#1976d2' }} />}
        />
        <Typography variant="body1" sx={{ marginLeft: '10px' }}>
          Run collection without using stored values
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          icon={<CheckBoxIcon style={{ color: '#1976d2' }} />}
          checkedIcon={<CheckBoxOutlineBlankIcon style={{ color: '#1976d2' }} />}
        />
        <Typography variant="body1" sx={{ marginLeft: '10px' }}>
          Save Cookies after collection run
        </Typography>
      </Box>
    </Box>
  </Box>
</Box> */}