import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams, Switch, Redirect, Route } from "react-router-dom";
import { updateCurrentUser, userLogOut } from './redux-store/currentUserSlice';
import './styles/App.scss';
import RouteRenderer from './routes/routeRenderer';
import routes from './routes';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider } from './contexts/CustomSnackbarContext';
import CustomSnackbar from './components/Common/CustomSnackbar';
import { GuestUserProvider } from './contexts/GuestUserContext';
import { fetchCollectionBySlug } from './redux-store/collectionActions';
import { Helmet } from 'react-helmet';

function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.current_user) || {};
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const forkCollectionId = queryParams.get('fork-collection');
  if (forkCollectionId) {
    localStorage.setItem("forkCollectionId", forkCollectionId);
  }

  const [collectionExists, setCollectionExists] = useState(false);
  const collectionSlug = location.pathname.split('/')[2];

  const validateUser = useCallback(() => {
    setIsLoading(true);
    if (token) {
      const tokenData = JSON.parse(atob(token.split(".")[1]));
      const expirationTime = tokenData.exp;
      const currentTime = Math.floor(Date.now() / 1000);

      if (currentTime > expirationTime) {
        handleLogout();
        setIsLoading(false);
        return;
      }
    }

    // If user data is not in Redux store, load from local storage
    if (!user.id) {
      const storedUser = localStorage.getItem("user");

      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        dispatch(updateCurrentUser(parsedUser));
      } else {
        handleLogout();
      }
    }
    setIsLoading(false);
  }, [token, dispatch, user.id]);

  const isLoggedIn = () => {
    if (!user.id) {
      const storedUser = localStorage.getItem("user");
      return token && storedUser;
    }

    return token && user.id;
  };

  useEffect(() => {
    async function fetchCollectionData() {
      setIsLoading(true);

      const res = await dispatch(fetchCollectionBySlug({ collectionSlug }));
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);

      if (res.payload?.error) {
        history.push('/login?colPublicErr=true');
      } else {
        setCollectionExists(true);
        history.push(`/collections/${collectionSlug}`);
      }
    }

    if (!isLoggedIn() && location.pathname === `/collections/${collectionSlug}`) {
      fetchCollectionData();
    }
  }, [dispatch, history]);

  const handleLogout = () => {
    if (location.pathname !== '/login' && location.pathname !== '/' && location.pathname !== '/confirm-email') {
      localStorage.clear();
      history.push('/login');
      dispatch(userLogOut());
    }
  };

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <SnackbarProvider>
      <GuestUserProvider>
        <div className="textTitle">
          <Switch>
            {routes.map((route, index) => (
              <RouteRenderer {...route} key={index} />
            ))}
            <Route
              path="/terms_condition"
              exact
              render={() => {
                window.location.href = 'https://www.qodex.ai/termsandconditions';
                return null;
              }}
            />
            <Redirect from="*" to={isLoggedIn() ? "/collections" : "/"} />
          </Switch>
          <Helmet>
            {process.env.ENVIRONMENT !== "production" && (<meta name="robots" content="noindex"></meta>)}
          </Helmet>
        </div>
      </GuestUserProvider>
      <CustomSnackbar />
    </SnackbarProvider>
  );
}

export default App;