import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import {
  updateEntity,
  destroyEntity,
  duplicateResource,
} from '../../redux-store/currentUserActions';
import {
  pushCollectionEntitiesIfExist,
  pushFolderEntitiesIfExist,
} from '../../redux-store/currentUserSlice';
import { removeTab } from '../../redux-store/tabSlice';
import { useGuestUser } from '../../contexts/GuestUserContext';
import { Box, IconButton, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// Constants
import { EntityableTypes } from '../../config/constants';
// Styles and assets
import '../../components/Dashboard/index.css';
import _ from 'lodash';

const EntityComponent = ({
  entity,
  folder,
  collection,
  refreshCollections,
  handleDelete,
  onEntityClick,
}) => {
  const { isGuestUser } = useGuestUser();
  const [dottedEntity, setDottedEntity] = useState();
  const [showEditOn, setShowEditOn] = useState();
  const [nameToUpdate, setNameToUpdate] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenNodeId, setMenuOpenNodeId] = useState(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleRenameClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleRenameClickOutside);
    };
  }, [nameToUpdate]);

  useEffect(() => {
    if (showEditOn) {
      setNameToUpdate(showEditOn.name);
    }
  }, [showEditOn]);

  const onEntityMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setMenuOpenNodeId(menuOpenNodeId === entity.id ? null : entity.id);

    if (menuOpen && dottedEntity?.id === entity.id) {
      setMenuOpen(false);
      setAnchorEl(null);
      setDottedEntity(null);
    } else {
      setAnchorEl(event.currentTarget);
      setDottedEntity(entity);
      setMenuOpen(true);
    }
  };

  const renameCall = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setShowEditOn(entity);
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      rename();
    } else if (event.key === 'Escape') {
      setShowEditOn();
    }
  };

  const renameEntity = (value) => {
    setNameToUpdate(value);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    await dispatch(destroyEntity({ id: dottedEntity.id }));
    dispatch(removeTab(dottedEntity.id));
    handleDelete(
      dottedEntity.slug,
      dottedEntity.entityable_type,
      collection?.id,
      folder?.id
    );
    handleDeleteDialogClose();
  };

  const duplicate = async () => {
    const response = await dispatch(
      duplicateResource({
        resource_id: dottedEntity?.id,
        resource: 'entity',
      })
    );

    if (response?.payload) {
      const duplicatedEntity = response.payload;

      if (duplicatedEntity.entityable_type === EntityableTypes.COLLECTION)
        dispatch(
          pushCollectionEntitiesIfExist({
            entities: [duplicatedEntity],
            collectionId: collection?.id,
          })
        );

      if (duplicatedEntity.entityable_type === EntityableTypes.FOLDER)
        dispatch(
          pushFolderEntitiesIfExist({
            entities: [duplicatedEntity],
            folderId: folder?.id,
          })
        );

      onEntityClick({ entity: duplicatedEntity, folder, collection });
    }

    // refreshCollections();

    // FIXME :: Keep this commented for now
    // fetchApis(dottedEntity?.entityable_id);
  };

  const handleRenameClickOutside = async (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      rename();
    }
  };

  const rename = async () => {
    let body = {
      name: nameToUpdate,
      id: dottedEntity?.id,
      collection: collection,
      // collectionApi: isCollectionApi,
      // folderApi: isFolderApi,
      folder: folder,
    };

    await dispatch(updateEntity(body));
    refreshCollections();
    setShowEditOn();
  };

  const handleClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
    setMenuOpenNodeId(null);
  };

  const replaceFolderName = (folderName) => {
    const methodRegex = /^(GET|POST|PATCH|PUT|DELETE)\s/;
    return _.truncate(folderName?.replace(methodRegex, ''), { length: 22 });
  };

  const getMethodColor = (method) => {
    if (method === 'GET') {
      return 'green';
    } else if (['POST', 'PATCH', 'PUT'].includes(method)) {
      return 'orange';
    } else if (method === 'DELETE') {
      return 'red';
    }

    return 'inherit';
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        '&:hover .more-icon': {
          visibility: 'visible',
        },
        '.more-icon': {
          visibility: menuOpenNodeId === entity.id ? 'visible' : 'hidden',
        },
        padding: "4px 0px"
      }}
    >
      {/* <FiberManualRecordIcon
        fontSize="small"
        sx={{
          fontSize: '10px',
          marginTop: "2px",
          color:
            entity?.tests_failed_count && entity?.tests_failed_count > 0 > 0
              ? 'error.main'
              : 'success.main',
          marginRight: '4px',
          marginLeft: entity.entityable_type === 'Folder' ? '8px' : '0px',
        }}
      /> */}
      {showEditOn === entity ? (
        <input
          type="text"
          ref={inputRef}
          onChange={(event) => renameEntity(event.target.value)}
          onClick={(event) => event.stopPropagation()}
          onKeyUp={handleKeyDown}
          placeholder={entity.name}
          value={nameToUpdate}
          style={{
            width: '100%',
            border: '1px solid #e9e9e9',
            borderRadius: '5px',
            padding: '5px',
            height: '25px',
            fontSize: '12px',
            fontFamily: "Inter, Sans-serif"
          }}
        />
      ) : (
        <>
          <Typography
            variant="body2"
            className="truncate-1-lines"
            sx={{
              flexGrow: 1,
              fontSize: '13px',
              ...(isGuestUser && { marginTop: '3px', marginBottom: '3px' }),
              fontWeight: "500",
              fontFamily: "Inter, Sans-serif",
              wordBreak: "break-all"
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: "10px",
                background: getMethodColor(entity?.request?.request_type),
                borderRadius: "3px",
                color: "white",
                padding: "1px 4px"
              }}
            >
              {entity?.request?.request_type}
            </span>
            &nbsp;
            {replaceFolderName(entity?.name)}
          </Typography>
          {!isGuestUser && (
            <IconButton
              size="small"
              onClick={(event) => onEntityMenuClick(event)}
              className="more-icon"
            >
              <MoreHorizIcon fontSize="inherit" />
            </IconButton>
          )}
        </>
      )}

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-icon-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {[
          { label: 'Duplicate', action: duplicate },
          { label: 'Rename', action: renameCall, condition: entity.can_update },
          {
            label: 'Delete',
            action: handleDeleteDialogOpen,
            color: 'red',
            condition: entity.can_delete,
          },
        ]
          .filter((item) => item.condition !== false)
          .map((item, index) => (
            <MenuItem
              key={index}
              onClick={(event) => {
                if (item.label === 'Delete') event.stopPropagation();
                item.action(event);
                setMenuOpen(false);
              }}
              style={{
                fontSize: '12px',
                fontWeight: "550",
                fontFamily: "Inter, Sans-serif",
                color: item.color || 'inherit',
              }}
            >
              {item.label}
            </MenuItem>
          ))}
      </Menu>

      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirm}
        title="Confirm Delete"
        content="Are you sure you want to delete?"
      />
    </Box>
  );
};

export default EntityComponent;
