import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiAgent } from '../config/axiosConfig';

export const updateTestCase = createAsyncThunk(
  "updateTestCase",
  async (_, { dispatch, rejectWithValue , history}) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_cases/${_.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json"
        },
        data: {
          test_case: _?.testCase
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTestCase = createAsyncThunk(
  "deleteTestCase",
  async (testCaseId, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_cases/${testCaseId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json"
        },
      });

      if (res.status === 200) {
        return { success: true, testCaseId };
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const convertEnglishToTestCase = createAsyncThunk(
  "convertEnglishToTestCase",
  async (_, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${_.entityId}/test_cases/convert_english_to_test_case`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          english_statement: _.englishStatement
        }
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }

    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : { message: 'An unknown error occurred' });
    }
  }
);

export const executeSampleTestCase = createAsyncThunk(
  "executeSampleTestCase",
  async (_, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");

      const res = await apiAgent({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${_.entityId}/test_cases/execute_sample`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        params: { js_statement: _.jsStatement, response_id: _.responseId }
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }

    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : { message: 'An unknown error occurred' });
    }
  }
);
