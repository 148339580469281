import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import TabsComponent from "./TabsComponent";
import JsonBody from "../Common/JsonBody";
import EntityStatus from "./EntityStatus";
import KeyValueTable from "../Common/KeyValueTable";

import coreUtils from "../../utils/coreUtils";

const EntityVariables = ({ variables = {} }) => {
  const selectedTestScenarioDetail = useSelector(
    (state) => state.testScenario.selectedTestScenarioDetail
  );
  const tabLabels = ["Response", "Global"];
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, tabIndex) => {
    setActiveTab(tabIndex);
  };
  const renderTabContent = () => {
    let parsedVariables;
    switch (activeTab) {
      case 0:
        parsedVariables = coreUtils.parseObjectToKeyValueArray(variables);
        return <KeyValueTable allItems={parsedVariables} sxCell={{textAlign: "left"}} json={true} isReadOnly />;
      case 1:
        let variablesJson = selectedTestScenarioDetail?.variables_json || {};
        parsedVariables = coreUtils.parseObjectToKeyValueArray(variablesJson);
        return <KeyValueTable allItems={parsedVariables} sxCell={{textAlign: "left"}} json={true} isReadOnly />;
      default:
        return null;
    }
  };
  return (
    <Box sx={{ py: 2 }}>
      <TabsComponent
        tabLabels={tabLabels}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        customTabStyle={{ width: "auto", fontSize: "12px", fontWeight: 600 }}
        tabsStyle={{ border: "none" }}
        activeTabStyle={{ backgroundColor: "transparent" }}
      />
      <Box sx={{ height: "500px", overflow: "auto" }}>{renderTabContent()}</Box>
    </Box>
  );
};

export default EntityVariables;
