import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import AspectRatioOutlined from '@mui/icons-material/AspectRatioOutlined';
import { useDispatch, useSelector } from "react-redux";
import { getEntityTestCases, getResponseApiTests } from '../../../redux-store/currentUserActions';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import LoadingOverlay from '../../Common/LoadingOverlay';

const TestCasesView = ({ entity, response }) => {
  const dispatch = useDispatch();
  const [testCases, setTestCases] = useState([]);
  const [apiTests, setApiTests] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRHS, setSelectedRHS] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);

  useEffect(() => {
    const fetchTestCases = async () => {
      setIsLoading(true);
      if (selectedSidebarState && entity) {
        const res = await dispatch(getEntityTestCases({ id: selectedSidebarState.id, testType: entity.test_type }));
        if (res.payload) {
          setTestCases(res.payload);
        }
      }
      setIsLoading(false);
    };

    fetchTestCases();
  }, [dispatch, selectedSidebarState, entity]);

  useEffect(() => {
    const fetchResponseApiTests = async () => {
      setIsLoading(true);
      const res = await dispatch(getResponseApiTests({ response_id: response?.id }));
      if (res?.payload?.api_tests) {
        setApiTests(res.payload.api_tests);
      }
      setIsLoading(false);
    };

    if (entity?.response?.id) {
      fetchResponseApiTests();
    }
  }, [dispatch, entity, response]);

  const handleOpenDialog = (rhs) => {
    const parsedData = JSON.parse(rhs);
    const formattedData = JSON.stringify(parsedData, null, 2);

    setSelectedRHS(formattedData);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const findApiTest = (testCaseId) => {
    const matchingApiTest = apiTests.find(apiTest => apiTest.test_case_id === testCaseId);
    return matchingApiTest;
  };

  return (
    <Box sx={{ position: 'relative' }}>
      { entity?.test_type === 'basic' ?
        <TableContainer sx={{ backgroundColor: '#fff', borderRadius: '5px' }}>
          <Table size='small' aria-label="simple" sx={{ border: '1px solid' }}>
            <TableHead>
              <TableRow>
                <TableCell>LHS</TableCell>
                <TableCell>Comparison</TableCell>
                <TableCell>RHS</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testCases.map((testCase, index) => {
                const apiTest = findApiTest(testCase.id);

                return (
                  <TableRow key={index}>
                    <TableCell>{testCase?.lhs}</TableCell>
                    <TableCell>{testCase?.comparison}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography noWrap sx={{ maxWidth: 300 }}>
                          {testCase?.rhs}
                        </Typography>
                        {['{{response_body}}', '{{response_headers}}', '{{response_body_keys}}', '{{response_headers_keys}}'].includes(apiTest?.lhs) && (
                          <Button onClick={() => handleOpenDialog(testCase?.rhs)} size="small">
                            <AspectRatioOutlined />
                          </Button>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      { apiTest?.status === true ?
                        <Typography variant="body2" sx={{ color: 'green' }}>Passed</Typography>
                        : apiTest?.status === false ? <Typography variant="body2" sx={{ color: 'red' }}>Failed</Typography>
                          : <Typography variant="body2" sx={{ color: 'orange' }}>Running</Typography>
                      }
                    </TableCell>
                  </TableRow>
                );
              })
            }
            </TableBody>
          </Table>
        </TableContainer>
      :
        <Paper style={{ marginTop: '10px', border: 'none', boxShadow: 'none' }}>
        {testCases.length > 0 ?
          testCases.map((testCase, index) => {
            const apiTest = findApiTest(testCase.id);

            return (
              <Box sx={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <Box flex={1}>
                  <Box>
                    <Typography variant="body1" style={{ marginTop: 5, marginBottom: 5 }}>
                      {apiTest?.description || testCase?.description}
                    </Typography>
                  </Box>
                  <Box key={index} sx={{ backgroundColor: '#333', color: '#fff', borderRadius: '5px' }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ maxHeight: 25, padding: '0 10px', borderBottom: '1px solid #555' }}
                    >
                      <Typography variant="body1" style={{ flexGrow: 1, fontFamily: 'monospace', fontSize: '11px' }}>
                        javascript
                      </Typography>
                    </Box>
                    <Box>
                      { !apiTest?.lhs &&
                        <Typography variant="body2" style={{ flexGrow: 1, padding: '5px 10px', fontFamily: 'monospace' }}>
                          {`// Replace with your test case`}
                        </Typography>
                      }
                      <SyntaxHighlighter language="javascript" style={dark} customStyle={{ background: '#333', margin: 0, border: 0, boxShadow: 'none', padding: '5px 10px' }}>
                        {apiTest?.lhs || testCase?.lhs}
                      </SyntaxHighlighter>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: apiTest?.status ? 'green' : 'red', marginRight: '10px' }}>
                  { (apiTest?.lhs || testCase?.lhs) &&
                    <Typography variant="body1">{ apiTest?.status ? 'Passed' : 'Failed' }</Typography>
                  }
                </Box>
              </Box>
            );
          })
          : <Typography variant='body1'>No test records found.</Typography>
        }
        </Paper>
      }
      <LoadingOverlay isLoading={isLoading} />

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle id="json-dialog-title">JSON</DialogTitle>
        <DialogContent>
          <pre>{selectedRHS}</pre>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TestCasesView;
