import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Chip, Box } from '@mui/material';
import { Add, LocalOffer, Close, FiberManualRecord } from '@mui/icons-material';

import MainLayout from '../../layouts/MainLayout';
import TestSuitesList from './TestSuitesList';
import SearchWithIcon from '../Common/SearchWithIcon';
import CustomButton from '../Common/CustomButton';
import CustomFilter from '../Common/CustomFilter';
import TestPlanAttach from "./TestPlanAttach";

import {
  createTestSuite,
  getAllTestSuites,
  deleteTestSuite,
  getTestSuiteTags,
  updateTestSuite,
} from '../../redux-store/testSuiteReducers/testSuiteActions';
import { getOwners } from '../../redux-store/currentUserActions';
import { getAllVariableSets } from '../../redux-store/variableSetReducers/variableSetActions';
import useSearchParams from '../../hooks/useSearchParams';
import useDebounce from '../../hooks/useDebounce';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const defaultTestSuiteFilters = {
  tags: [],
  statuses: [],
  environmentSetId: null,
  buildStatus: null,
  ownerIds: [],
};
const statusObj = {
  Draft: 'draft',
  Action: 'active',
  Archived: 'archived',
};
const statuses = Object.keys(statusObj);
const buildStatuses = ['pass', 'fail'];

const TestSuiteListPage = ({ project }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');
  const selectedTestSuites = useSelector(
    (state) => state.testSuite.selectedTestSuites
  );

  const isLoading = useSelector((state) => state.testSuite.isLoading);
  const tags = useSelector((state) => state.testSuite.testSuiteTags);
  const owners = useSelector((state) => state.user.owners);
  const environmentSets = useSelector(
    (state) => state.variableSet.allVariableSets
  );

  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [searchText, setSearchText] = useState('');
  const [testSuiteList, setTestSuiteList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalTestSuites, setTotalTestSuites] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState(defaultTestSuiteFilters);
  const lastFetchedPage = useRef(pageNumber);
  const isInitialMount = useRef(true);
  const debouncedSearchText = useDebounce(searchText, 1000);
  const { openSnackbar } = useSnackbar();

  const hasAppliedFilters =
    appliedFilters.tags.length ||
    appliedFilters.statuses.length ||
    appliedFilters.environmentSetId ||
    appliedFilters.buildStatus ||
    appliedFilters.ownerIds.length;

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    // keep page query-param in sync with state
    window.history.pushState('', '', `?page=${pageNumber}`);
  }, [pageNumber]);

  useEffect(() => {
    if (
      lastFetchedPage.current === pageNumber &&
      pageNumber > 1 &&
      !isInitialMount.current
    ) {
      // start to fetch from page#1 if filters or search text updates
      setPageNumber(1);
    } else {
      getTestSuiteList();
      isInitialMount.current = false;
    }
  }, [pageNumber, appliedFilters, debouncedSearchText]);

  const getTestSuiteList = async () => {
    const testSuiteListResponse = await dispatch(
      getAllTestSuites({
        ...appliedFilters,
        statuses: appliedFilters.statuses.map((status) => statusObj[status]),
        projectId: project.id,
        searchText: debouncedSearchText || null,
        pageNumber,
      })
    );

    if (testSuiteListResponse?.payload?.test_suites) {
      setTestSuiteList(testSuiteListResponse.payload.test_suites || []);
      setTotalPages(testSuiteListResponse.payload?.total_pages);
      setTotalTestSuites(testSuiteListResponse.payload?.total);
    }

    lastFetchedPage.current = pageNumber;
  };

  const getFilters = async () => {
    await Promise.all([
      dispatch(getTestSuiteTags({ projectId: project.id })),
      dispatch(getAllVariableSets({ projectId: project.id, isEnvironment: true, pageNumber: 1 })),
      dispatch(getOwners({ projectId: project.id })),
    ]);
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const onDeleteTestSuite = async (testSuiteId) => {
    await dispatch(deleteTestSuite({ testSuiteId }));
    openSnackbar({
      message: "Test suite deleted successfully.",
      severity: "success",
    });

    if (testSuiteList.length === 1 && pageNumber !== 1) {
      onPageChange('', pageNumber - 1 > 0 ? pageNumber - 1 : 1);
    } else {
      getTestSuiteList();
    }
  };

  const onArchiveTestSuite = async (testSuiteId) => {
    await dispatch(updateTestSuite({ testSuiteId, data: { status: 'archived' } }));
    openSnackbar({
      message: "Test suite archived successfully.",
      severity: "success",
    });

    getTestSuiteList();
  };

  const createNewTestSuite = async () => {
    const title = `Test Suite ${totalTestSuites ? totalTestSuites + 1 : 1}`;
    const testSuiteResponse = await dispatch(
      createTestSuite({ projectId: project.id, title: title })
    );
    if (testSuiteResponse?.payload?.id) {
      openSnackbar({
        message: `Test suite ${title} created successfully.`,
        severity: "success",
      });
      history.push(`/test-suites/${testSuiteResponse.payload.id}`);
    } else {
      openSnackbar({
        message: `Test suite creation failed!`,
        severity: "error",
      });
    }
  };

  const onResetFilters = () => setAppliedFilters(defaultTestSuiteFilters);

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="Test Suites"
      subtitleText={`Showing ${totalTestSuites || 0} test suite(s)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
      rightSideContent={
        <>
          <SearchWithIcon
            placeholder="Search test suites"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{marginRight: "16px"}}
          />
          <CustomButton startIcon={<Add />} onClick={createNewTestSuite}>
            Create Test Suite
          </CustomButton>
        </>
      }
    >
      <Stack sx={{ paddingX: "20px" }} direction="row" spacing={1}>
        <CustomFilter
          id="tags-filter"
          label="Tags"
          icon={<LocalOffer />}
          searchInputPlaceholder="Select one or more options"
          options={tags || []}
          appliedFilters={appliedFilters.tags}
          setAppliedFilters={(tags) =>
            setAppliedFilters((prev) => ({ ...prev, tags }))
          }
        />

        <CustomFilter
          id="status-filter"
          label="Status"
          icon={<FiberManualRecord />}
          options={statuses || []}
          appliedFilters={appliedFilters.statuses}
          setAppliedFilters={(statuses) =>
            setAppliedFilters((prev) => ({ ...prev, statuses }))
          }
        />

        <CustomFilter
          id="env-filter"
          label="Last Run Result"
          type="radio"
          icon={<FiberManualRecord />}
          options={environmentSets || []}
          additionalOptions={buildStatuses || []}
          appliedFilter={appliedFilters.environmentSetId}
          setAppliedFilter={(environmentSetId) =>
            setAppliedFilters((prev) => ({ ...prev, environmentSetId }))
          }
          additionalAppliedFilter={appliedFilters.buildStatus}
          setAdditionalAppliedFilter={(buildStatus) =>
            setAppliedFilters((prev) => ({ ...prev, buildStatus }))
          }
        />

        <CustomFilter
          id="owner-filter"
          label="Owner"
          icon={<FiberManualRecord />}
          options={owners || []}
          appliedFilters={appliedFilters.ownerIds}
          setAppliedFilters={(ownerIds) =>
            setAppliedFilters((prev) => ({ ...prev, ownerIds }))
          }
        />

        {hasAppliedFilters ? (
          <Chip
            label="Reset all filters"
            deleteIcon={<Close />}
            onClick={onResetFilters}
            onDelete={onResetFilters}
            size="small"
            sx={{background: "rgb(229 229 229)", border: hasAppliedFilters ? "1px solid #6241d4" : "none", borderRadius: "4px", fontWeight: 500, padding: "14px 0"}}
          />
        ) : null}
        {selectedTestSuites && selectedTestSuites?.length > 0 ? (
          <TestPlanAttach projectId={project.id} />
        ) : null}
      </Stack>

      <Box sx={{maxHeight: "calc(100vh - 210px)", overflow: "auto"}}>
        <TestSuitesList
          testSuiteList={testSuiteList}
          isLoading={isLoading}
          pageNumber={pageNumber}
          onDelete={onDeleteTestSuite}
          onArchive={onArchiveTestSuite}
        />
      </Box>

    </MainLayout>
  );
};

export default TestSuiteListPage;
