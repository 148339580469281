
import { useState, useEffect, useContext } from 'react';
import { Box, Tabs, Tab, Menu, MenuItem, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Divider } from '@mui/material';
import { Search, ExpandMore, ContentCopyRounded, DescriptionOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import LoadableOverlay from '../Common/LoadingOverlay'
// import { setResponse } from '../../redux-store/currentUserSlice';
import AppContext from '../../AppContext';
// import ReactJson from 'react-json-view';


const ResponseBodyComponent = ({ entity }) => {
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);
  const [currentResponse, setCurrentResponse] = useState(entity?.response?.body) || {};
  const responseStatus = entity?.response?.status || 200;
  const responseTime = entity?.response?.time || 0;
  
  const [format, setSelectedFormat] = useState('JSON');
  const [selectedTab, setSelectedTab] = useState('pretty');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { sendClicked, setSendClicked } = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentResponse(entity?.response?.body || {});
  }, [entity]);

  useEffect(() => {
    // TODO :: This is a hack. We should update response in a better way.
    // setTimeout(() => {
    //   getLatestSuccessfulResponse({ id: selectedSidebarState?.id });
    // }, 1000);

    if (sendClicked) {
      setSendClicked(false);
    }
  }, [sendClicked, selectedSidebarState]);
  
  const tabsData = [
    { label: "Pretty", component: "pretty" },
    { label: "Raw", component: "raw" },
    { label: "Preview", component: "preview" },
    // { label: "Visualize", component: "visualize" },
    { label: "Response Headers", component: "response_headers" }
  ];

  // TODO :: If plan is to continue to keep responses in the state, then we should probably update the response in the state as well.
  const getLatestSuccessfulResponse = async ({ id }) => {
    setIsLoading(true);

    try {
      if (entity?.response) {
        setCurrentResponse(entity?.response?.body);
        // await dispatch(setResponse({ apiId: selectedSidebarState?.id, response: entity?.response }));
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const handleFormatClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFormatClose = (option) => {
    setSelectedFormat(option);
    setAnchorEl(null);
  };  

  const renderTabContent = () => {
    switch (format) {
      case 'JSON':
        // return <ReactJson src={ currentResponse } name={false} displayObjectSize={false} displayDataTypes={false} />
        return <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', wordBreak: 'break-all', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px" }}>{JSON.stringify(currentResponse, null, 2)}</pre>;
        return <SyntaxHighlighter language="json" style={docco}>
          {JSON.stringify(currentResponse, null, 2)}
        </SyntaxHighlighter>;
      case 'XML':
      case 'Text':
      case 'HTML':
        return <pre style={{fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"}}>{JSON.stringify(currentResponse, null, 2)}</pre>;
      case 'Auto':
        try {
          return <SyntaxHighlighter language="json" style={{...docco, fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"}}>
            {JSON.stringify(currentResponse, null, 2)}
          </SyntaxHighlighter>;
        } catch (error) {
          return <pre style={{fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"}}>{JSON.stringify(currentResponse, null, 2)}</pre>;
        }
      default:
        return <SyntaxHighlighter language="json" style={{...docco, fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"}}>
          {JSON.stringify(currentResponse, null, 2)}
        </SyntaxHighlighter>;
    }
  };

  const ResponseHeadersTable = () => {
    const headers = entity?.response?.headers;

    return (
      <TableContainer sx={{ borderRadius: '5px', marginBottom: 5 }}>
        <Table size='small' aria-label="simple" sx={{ border: '1px solid' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"}}>Name</TableCell>
              <TableCell sx={{fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"}}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {headers && Object.entries(headers).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px"}}>{key}</TableCell>
                <TableCell sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px"}}>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box style={{ width: '100%', padding: '0px 11px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            sx={{ height: '32px', minHeight: '32px', border: '1px solid #e9e9e9', borderRadius: '5px' }}
          >
            {tabsData.map((tab) => (
              <Tab 
                key={tab.component}
                label={tab.label}
                value={tab.component}
                sx={{
                  textTransform: 'capitalize',
                  height: '32px',
                  minHeight: '32px',
                  color: '#575757 !important',
                  backgroundColor: 'transparent',
                  fontSize: "12px",
                  fontFamily: "Inter, Sans-serif"
                }}
              />
            ))}
          </Tabs>

          {/* <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleFormatClose(format)}
          >
            {['JSON', 'XML', 'HTML', 'Text', 'Auto'].map((option) => (
              <MenuItem key={option} onClick={() => handleFormatClose(option)} style={{ fontSize: '10px' }}>
                {option}
              </MenuItem>
            ))}
          </Menu> */}

          {/* {selectedTab === 'pretty' && (
            <Button
              endIcon={<ExpandMore />}
              onClick={handleFormatClick}
              style={{
                color: '#575757',
                borderColor: '#e9e9e9',
                marginLeft: '10px'
              }}
            >
              {format}
            </Button>
          )} */}

          {/* <IconButton>
            <DescriptionOutlined style={{ fontSize: '15px' }} />
          </IconButton> */}
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body2" sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px"}}>
            Status Code: {responseStatus}
          </Typography>
          
          <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 'auto', height: '20px', borderColor: 'gray' }} />
          
          <Typography variant="body2" sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px"}}>
            Time: {responseTime} ms
          </Typography>

          {/* <IconButton color="primary">
            <Search />
          </IconButton>
          <IconButton color="primary">
            <ContentCopyRounded />
          </IconButton> */}
        </Box>
      </Box>

      <Box mt={2} style={{ minHeight: '35vh', maxHeight: '35vh', width: '100%', overflowY: 'auto' }}>
        {selectedTab === 'pretty' && currentResponse && renderTabContent()}
        {selectedTab === 'raw' && <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', wordBreak: 'break-all', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px" }}>{JSON.stringify(currentResponse, null, 2)}</pre>}
        {selectedTab === 'preview' && <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', wordBreak: 'break-all', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px" }}>{JSON.stringify(currentResponse, null, 2)}</pre>}
        {selectedTab === 'response_headers' && <ResponseHeadersTable />}
      </Box>

      <LoadableOverlay isLoading={isLoading} />
    </Box>
  );
}

export default ResponseBodyComponent;