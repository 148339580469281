import axios from 'axios';
import Cookies from 'universal-cookie';
import APPLICATION_CONFIG from './applicationConfig.js';

const default_options = {
  baseURL: APPLICATION_CONFIG.API_URL,
  responseType: 'json',
  timeout: 60000,
  headers: {}
};

const axiosClient = (options = {}) => {
  const headers = {
    ...default_options.headers,
    ...options.headers
  };

  return axios.create({ ...default_options, ...options, headers });
};

const apiAgent = (args) => {
  const cookies = new Cookies();
  const api_key = cookies.get('api_key');
  const team_id = cookies.get('team_id');
  const project = localStorage.getItem('lastSelectedProject');
  const parsedProject = project ? JSON.parse(project) : {};

  if (args.params === null || args.params === undefined) {
    args.params = {};
  }

  args.params.api_key = api_key;
  if (!args.params?.team_id) args.params.team_id = team_id;

  return axiosClient({
    signal: args.signal,
    headers: { 'project_id': parsedProject?.id }
  })(args);
}

export { axiosClient, apiAgent };

export const COOKIE_SETTINGS = {
  "AUTH_DURATION": 60*60*24*365
};
