import { createSlice } from '@reduxjs/toolkit';
import { fetchStats, fetchLineChartStats, fetchBuggyApis, fetchSlowApis } from './dashboardActions';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    stats: [],
    buggyApis: [],
    slowApis: [],
    loadingStats: false,
    loadingBuggyApis: false,
    loadingSlowApis: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStats.pending, (state) => {
        state.loadingStats = true;
      })
      .addCase(fetchStats.fulfilled, (state, action) => {
        state.loadingStats = false;
        state.stats = action.payload;
      })
      .addCase(fetchStats.rejected, (state) => {
        state.loadingStats = false;
      })

      .addCase(fetchLineChartStats.pending, (state) => {
        state.loadingStats = true;
      })
      .addCase(fetchLineChartStats.fulfilled, (state, action) => {
        state.loadingStats = false;
        state.lineChartStats = action.payload;
      })
      .addCase(fetchLineChartStats.rejected, (state) => {
        state.loadingStats = false;
      })

      .addCase(fetchBuggyApis.pending, (state) => {
        state.loadingBuggyApis = true;
      })
      .addCase(fetchBuggyApis.fulfilled, (state, action) => {
        state.loadingBuggyApis = false;
        state.buggyApis = action.payload;
      })
      .addCase(fetchBuggyApis.rejected, (state) => {
        state.loadingBuggyApis = false;
      })

      .addCase(fetchSlowApis.pending, (state) => {
        state.loadingSlowApis = true;
      })
      .addCase(fetchSlowApis.fulfilled, (state, action) => {
        state.loadingSlowApis = false;
        state.slowApis = action.payload;
      })
      .addCase(fetchSlowApis.rejected, (state) => {
        state.loadingSlowApis = false;
      });
  },
});

export default dashboardSlice.reducer;
