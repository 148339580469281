import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { Grid, Paper, Typography, List, ListItem, Divider } from '@mui/material';

import { fetchStats, fetchLineChartStats, fetchBuggyApis, fetchSlowApis } from '../../redux-store/dashboardActions';
import MetricsComponent from '../Collections/MetricsComponent';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { stats, lineChartStats, buggyApis, slowApis, loadingStats, loadingBuggyApis, loadingSlowApis } = useSelector((state) => ({
    stats: state.dashboard.stats,
    lineChartStats: state.dashboard.lineChartStats,
    buggyApis: state.dashboard.buggyApis,
    slowApis: state.dashboard.slowApis,
    loadingStats: state.dashboard.loadingStats,
    loadingBuggyApis: state.dashboard.loadingBuggyApis,
    loadingSlowApis: state.dashboard.loadingSlowApis,
  }));

  useEffect(() => {
    dispatch(fetchStats());
    dispatch(fetchLineChartStats());
    dispatch(fetchBuggyApis());
    dispatch(fetchSlowApis());
  }, [dispatch]);

  const renderListSkeletons = (count) => {
    return Array.from(new Array(count)).map((_, index) => (
      <ListItem key={index} sx={{ py: 1 }}>
        <Grid container>
          <Grid item xs={2.4} sx={{ textAlign: 'center' }}>
            <Skeleton variant="text" width="80%" />
          </Grid>
          <Grid item xs={7.2}>
            <Skeleton variant="text" width="80%" />
          </Grid>
          <Grid item xs={2.4} sx={{ textAlign: 'center' }}>
            <Skeleton variant="text" width="80%" />
          </Grid>
        </Grid>
        {index !== count - 1 && <Divider />}
      </ListItem>
    ));
  };

  const buggyAPIsListItem = (api, index) => (
    <ListItem key={index} sx={{ py: 1 }}>
      <Grid container>
        <Grid item xs={2.4} sx={{ textAlign: 'center' }}>
          <Typography variant="body2">{api.method}</Typography>
        </Grid>
        <Grid item xs={7.2}>
          <Typography variant="body2">{api.endpoint}</Typography>
        </Grid>
        <Grid item xs={2.4} sx={{ textAlign: 'center' }}>
          <Typography variant="body2">{api.percentage}</Typography>
        </Grid>
      </Grid>
      {index !== buggyApis.length - 1 && <Divider />}
    </ListItem>
  );

  const slowAPIsListItem = (api, index) => (
    <ListItem key={index} sx={{ py: 1 }}>
      <Grid container>
        <Grid item xs={9.6}>
          <Typography variant="body2">{api.endpoint}</Typography>
        </Grid>
        <Grid item xs={2.4} sx={{ textAlign: 'center' }}>
          <Typography variant="body2">{api.time}</Typography>
        </Grid>
      </Grid>
      {index !== slowApis.length - 1 && <Divider />}
    </ListItem>
  );

  return (
    <div style={{ height: 'calc(100vh - 56px)', overflow: 'auto', padding: "40px 38px" }}>
      <Grid container spacing={2} padding={2}>
        <Grid item container spacing={2} xs={12}>
          {loadingStats
            ? Array.from(new Array(6)).map((_, index) => (
                <Grid item xs={6} sm={3} key={index}>
                  <Skeleton variant="rectangular" height={70} />
                </Grid>
              ))
            : stats.map((stat, index) => (
                <Grid item xs={6} sm={3} key={index}>
                  <Paper elevation={1} sx={{ padding: 2 }}>
                    <Typography color="textSecondary">{stat.label}</Typography>
                    <Typography variant="h5">{stat.stat}</Typography>
                  </Paper>
                </Grid>
              ))
          }
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper elevation={1} style={{ paddingBottom: 20 }}>
            <MetricsComponent testData={lineChartStats} responseTimeData={lineChartStats} />
          </Paper>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
              Buggy APIs
            </Typography>
            <List dense>
              {loadingBuggyApis
                ? renderListSkeletons(3)
                : buggyApis.map(buggyAPIsListItem)
              }
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
              Slow APIs
            </Typography>
            <List dense>
              { loadingSlowApis
                ? renderListSkeletons(3)
                : slowApis.map(slowAPIsListItem)
              }
            </List>
          </Paper>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Dashboard;
