import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiAgent } from '../config/axiosConfig';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchOrganizations = createAsyncThunk(
  'dashboard/fetchOrganizations',
  async (_, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/dashboard`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const fetchStats = createAsyncThunk(
  'dashboard/fetchStats',
  async (_, { rejectWithValue }) => {
    try {
      // await delay(5000);
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/dashboard/stats`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const fetchLineChartStats = createAsyncThunk(
  'dashboard/fetchLineChartStats',
  async (_, { rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/dashboard/chart_stats`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const fetchBuggyApis = createAsyncThunk(
  'dashboard/fetchBuggyApis',
  async (_, { rejectWithValue }) => {
    try {
      // await delay(5000);
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/dashboard/buggy_apis`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const fetchSlowApis = createAsyncThunk(
  'dashboard/fetchSlowApis',
  async (_, { rejectWithValue }) => {
    try {
      // await delay(5000);
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/dashboard/slow_apis`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);
