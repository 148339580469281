import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Add} from "@mui/icons-material";
import MainLayout from "../../layouts/MainLayout.jsx";
import FunctionsList from "./FunctionsList.jsx";
import CustomButton from '../Common/CustomButton.jsx';
import FunctionModal from "./FunctionModal.jsx";

import { useSnackbar } from '../../contexts/CustomSnackbarContext';
import {getFunctionsList, createFunction, updateFunction} from "../../redux-store/functionReducers/functionActions";

const FunctionsPage = ({projectId}) => {
  const dispatch = useDispatch();
  const {openSnackbar} = useSnackbar();
  const isLoading = useSelector(state => state.function.isLoading);
  const totalPages = useSelector(state => state.function.totalPages);
  const total = useSelector(state => state.function.total);
  const [pageNumber, setPageNumber] = useState(1);
  const [actionType, setActionType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionableData, setActionableData] = useState({});
  
  useEffect(() => {
    getFunctions();
  }, []);
  
  useEffect(() => {
    getFunctions();
  }, [pageNumber]);
  
  const handleCreateFunction = () => {
    setActionableData({});
    setActionType("create");
    setIsModalOpen(true);
  }

  const handleAction = (actionType, data) => {
    console.log(data);
    setActionType(actionType);
    setActionableData({...data});
    setIsModalOpen(true);
  }

  const handleConfirm = async (actionType, data, id) => {
    if (actionType === "edit") {
      const response = await dispatch(updateFunction({...data, functionId: actionableData.id}));
      if (response?.payload) {
        openSnackbar({ message: "Function updated successfully.", severity: 'success' });
        handleModalClose();
        setActionableData({});
        getFunctions(1);
      } else {
        openSnackbar({ message: "Function creation failed! Please make sure name is not duplicated!", severity: 'error' });
      }
    } else {
      const response = await dispatch(createFunction({...data, projectId: projectId}));
      console.log(response);
      if (response?.payload && !response?.error) {
        openSnackbar({ message: "Function created successfully.", severity: 'success' });
        handleModalClose();
        setActionableData({});
        setPageNumber(1);
        getFunctions(1);
      } else {
        const errorMsg = response?.payload?.error;
        openSnackbar({ message: errorMsg || "Function creation failed! Please make sure name is not duplicated!", severity: 'error' });
      }
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
    setActionType("");
  }
  
  const getFunctions = async (page) => {
    await dispatch(getFunctionsList({ projectId: projectId, pageNumber: page || pageNumber, pageSize: 10 }));
  }

  return (
    <MainLayout
      isLoading={isLoading}
      sx={{ width: "calc(100% - 100px)" }}
      headerText="JS Functions"
      subtitleText={`Create/ edit/ duplicate a js function (count - ${total})`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={(e, value) => {setPageNumber(value);}}
      contentStyle={{p: "8px 16px"}}
      rightSideContent={
        <CustomButton startIcon={<Add />} onClick={handleCreateFunction}>
          Add new function
        </CustomButton>
      }
    >
      <FunctionsList pageNumber={pageNumber} onAction={handleAction} />
      <FunctionModal detail={actionableData} isOpen={isModalOpen} actionType={actionType} onClose={handleModalClose} onConfirm={handleConfirm} />
    </MainLayout>
  )
}

export default FunctionsPage;
