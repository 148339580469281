import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import { updateResourceSummary, generateResourceOverview } from "../../redux-store/currentUserActions";
import { setResourceSummary } from "../../redux-store/currentUserSlice";
import CustomSnackbar from "../Common/CustomSnackbar";
import TextEditor from "../Common/TextEditor/TextEditor.jsx";
import { useCallback } from "react";

const OverviewComponent = ({ resource }) => {
  const [editorData, setEditorData] = useState('');
  const [showEditor, setShowEditor] = useState(false);
  const [isSourceCodeMode, setIsSourceCodeMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarState, setSnackbarState] = useState('success');
  let selectedSummary = useSelector((state) => state.user.selectedSummary);
  const dispatch = useDispatch();

  const handleToggleEditor = () => {
    setShowEditor(prevShowEditor => !prevShowEditor);
  };

  const handleGenerateAction = async () => {
    setLoading(true);
    let type = resource?.type === "collection" ? "collections" : (resource?.type === "folder" ? "folders" : "entities");
    try {
      const response = await dispatch(generateResourceOverview({ type: type, id: resource?.id }));
      setLoading(false);
      if (response && response?.payload && response?.payload?.overview) {
        setEditorData(response.payload.overview);
        setSnackbarState('success');
        setSnackbarMessage('Overview generated successfully');
        setSnackbarOpen(true);
      } else {
        setSnackbarState('error');
        setSnackbarMessage('Failed to generate overview');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setSnackbarState('error');
      setSnackbarMessage('Error occurred during generation');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    setSnackbarOpen(false);
  };  

  const handleToggleSourceCodeMode = () => {
    setIsSourceCodeMode(!isSourceCodeMode);
  };

  const handleSave = async () => {
    setShowEditor(false);
    setIsSourceCodeMode(false);
    const data = {
      id: selectedSummary?.id,
      summary: {
        overview: editorData,
      }
    };

    const res = await dispatch(updateResourceSummary(data));
    if (res.payload.summary) {
      await dispatch(setResourceSummary(res.payload.summary));
    }
  };

  useEffect(() => {
    setEditorData(selectedSummary?.overview || '');
  }, [selectedSummary]);

  const onChange = useCallback((content) => {
    setEditorData(content);
  }, []);

  return (
    <Box sx={{maxHeight: "calc(100vh - 250px)", overflow: "auto"}}>
      {showEditor ? (
        <div>
          {isSourceCodeMode ? (
            <TextField
              fullWidth
              multiline
              minRows={10}
              value={editorData}
              onChange={(e) => setEditorData(e.target.value)}
              variant="outlined"
              margin="normal"
            />
          ) : (
            <TextEditor initialContent={editorData} onChange={onChange} />
          )}
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#6241d4', marginRight: '10px' }}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{ marginRight: '10px' }}
              onClick={handleToggleSourceCodeMode}
            >
              {isSourceCodeMode ? 'Switch to Editor' : 'View Source Code'}
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleToggleEditor}
            >
              Close Editor
            </Button>
          </div>
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: editorData || 'Add overview to preview here.' }} />
      )}
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      )}
      {!showEditor && (
        <>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: '#6241d4', marginTop: '10px', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "11px" }}
            onClick={handleToggleEditor}
          >
            Edit
          </Button>
          { resource &&
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#6241d4', marginTop: '10px', marginLeft: 10, paddingLeft: '20px', paddingRight: '20px', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "11px" }}
              onClick={handleGenerateAction}
            >
              Generate with AI Bot
            </Button>
          }
        </>
      )}

      <CustomSnackbar open={snackbarOpen} message={snackbarMessage} handleClose={handleSnackbarClose} severity={snackbarState} />
    </Box>
  );
};

export default OverviewComponent;
