import React, { useEffect, useState } from 'react';
import { Divider, Typography, Box } from '@mui/material';
import { ArrowOutward } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateResourceSummary } from '../../redux-store/currentUserActions';
import ApiKeyAuthorization from './ApiKeyAuthorization';
import BearerTokenAuthorization from './BearerTokenAuthorization';
import BasicAuthorization from './BasicAuthorization';
import AWSAuthorization from './AWSAuthorization';

const AuthorizationComponent = ({ collection }) => {
  const selectedSummary = useSelector((state) => state.user.selectedSummary);
  const dispatch = useDispatch();
  const authTypes = [
    'No Auth',
    'Bearer Token',
    'Api Key',
    'Basic Auth',
    'Aws Signature',
  ];
  if (!collection?.project_id) {
    authTypes.unshift('Inherit auth from parent');
  }
  const [selectedType, setSelectedType] = useState('No Auth');

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const toSnakeCase = (str) => {
    return str
      .replace(/\s+/g, '_')
      .replace(/([A-Z])/g, (match, letter) => `${letter.toLowerCase()}`)
      .toLowerCase();
  };

  const toNormalCase = (str) => {
    return str
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  useEffect(() => {
    if (selectedSummary?.authorization_type) {
      setSelectedType(toNormalCase(selectedSummary?.authorization_type));
    } else {
      setSelectedType('No Auth');
    }
  }, [selectedSummary]);

  const handleApiCall = async () => {
    if (
      selectedType === 'No Auth' ||
      selectedType === 'Inherit auth from parent'
    ) {
      const state = {
        id: selectedSummary?.id,
        summary: {
          authorization_type: toSnakeCase(selectedType),
          authorization_value: {},
        },
      };
      const res = await dispatch(updateResourceSummary(state));
      if (res.payload.summary) {
        setSelectedType(toNormalCase(res.payload.summary.authorization_type));
      }
    }
  };

  return (
    <Box style={{ width: '100%', overflowX: 'hidden' }}>
      <Typography
        style={{ fontSize: '12px', fontWeight: '400', color: '#6b6b6b', fontSize: "12px",
          fontFamily: "Inter, Sans-serif" }}
      >
        This authorization method will be used for every request in this
        collection. You can override this by specifying one in the request.
      </Typography>
      <Box
        style={{
          marginTop: '25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ marginRight: '10px', fontSize: "13px",
    fontFamily: "Inter, Sans-serif" }}>Type</div>
        <select
          className="form-select"
          id="authType"
          name="authType"
          value={selectedType}
          onChange={handleTypeChange}
          onBlur={handleApiCall}
          style={{ width: '200px', backgroundColor: '#eef0f6', fontSize: "13px",
            fontFamily: "Inter, Sans-serif" }}
        >
          {authTypes.map((auth) => (
            <option key={auth} value={auth}>
              {auth}
            </option>
          ))}
        </select>
      </Box>
      {selectedType === 'No Auth' && (
        <>
          <Divider sx={{ borderBottom: 'outset', marginTop: '2%' }} />
          <div>
            <p
              style={{
                marginTop: '10px',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: '400',
                color: '#6b6b6b',
                fontSize: "12px",
                fontFamily: "Inter, Sans-serif"
              }}
            >
              This collection does not use any authorization. Learn more about
              <a>
                {' '}
                <u> authorization</u>
              </a>
              <ArrowOutward style={{ fontSize: '17px' }}></ArrowOutward>
            </p>
          </div>
        </>
      )}
      {selectedType === 'Api Key' && (
        <ApiKeyAuthorization
          selectedType={toSnakeCase(selectedType)}
          collection={collection}
        ></ApiKeyAuthorization>
      )}
      {selectedType === 'Bearer Token' && (
        <BearerTokenAuthorization
          selectedType={toSnakeCase(selectedType)}
          collection={collection}
        ></BearerTokenAuthorization>
      )}
      {selectedType === 'Basic Auth' && (
        <BasicAuthorization
          selectedType={toSnakeCase(selectedType)}
          collection={collection}
        ></BasicAuthorization>
      )}
      {selectedType === 'Aws Signature' && (
        <AWSAuthorization
          selectedType={toSnakeCase(selectedType)}
          collection={collection}
        ></AWSAuthorization>
      )}
    </Box>
  );
};

export default AuthorizationComponent;
