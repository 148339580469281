import React, { useState, useEffect, useRef } from 'react';
import { TableContainer, Box, Table, TableHead, TableRow, TableCell, TableBody, TextField, IconButton, FormControl,
  Select,
  MenuItem } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CustomCheckbox from "../Common/CustomCheckbox.jsx";

const getNewRow = () => {
  const newRow = { name: '', value: '', description: '', required: false, datatype: '' };
  return {...newRow};
}

const FormDataComponent = ({ body, onBodyChange, hideExtraFields }) => {
  const [rows, setRows] = useState(body || [{...getNewRow()}]);
  const tableRef = useRef(null);

  const handleInputChange = (index, field, value) => {
    let updatedRows = [...rows];
    if (field === "required") {
      console.log(value);
      updatedRows[index] = { ...updatedRows[index], [field]: value ? true : false };
    } else {
      updatedRows[index] = { ...updatedRows[index], [field]: value };
    }

    updatedRows = updatedRows.filter((row) => {
      return row.name || row.value;
    });

    updatedRows.push({...getNewRow()});
    setRows(updatedRows);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    onBodyChange(updatedRows);
  };

  const handleOutsideClick = async (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      let filteredRows = rows.filter((row) => {
        return row.name;
      });
      onBodyChange(filteredRows);
    }
  };

  useEffect(() => {
    setRows([...(body || []), {...getNewRow()}]);
  }, [body]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [rows]);

  const rowTableCellSx = {
    padding: '5px 10px', margin: 0, fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"
  }
  const inputTableCellSx = {
    // padding: '5px 10px',
    fontSize: '12px',
    fontFamily: "Inter, Sans-serif",
    fontWeight: "450"
  }

  return (
    <Box sx={{ padding: '1rem' }}>
      <TableContainer sx={{ borderRadius: '5px' }}>
        <Table size="small" aria-label="form-data" sx={{ border: '1px solid #ccc' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={rowTableCellSx}>Key</TableCell>
              <TableCell sx={rowTableCellSx}>Value</TableCell>
              <TableCell sx={rowTableCellSx}>Description</TableCell>
              {
                !hideExtraFields && (
                  <>
                    <TableCell sx={rowTableCellSx}>Required</TableCell>
                    <TableCell sx={rowTableCellSx}>Data Type</TableCell>
                  </>
                )
              }
              <TableCell sx={rowTableCellSx}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={tableRef}>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={row.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                    sx={{
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={row.value}
                    onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                    sx={{
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={row.description}
                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                    sx={{
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                {
                (
                  !hideExtraFields && 
                  <>
                    <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }}>
                      <CustomCheckbox
                        key={`required-${index}`}
                        checked={row.required}
                        label=""
                        onChange={(e) => handleInputChange(index, "required", e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled', readOnly: row.readOnly }}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="datatype-simple-select-label"
                          id="datatype-simple-select"
                          value={row.datatype}
                          label=""
                          size="small"
                          placeholder="datatype"
                          onChange={e => handleInputChange(index, 'datatype', e.target.value)}
                          sx={{...inputTableCellSx, py: 0, textTransform: "capitalize"}}
                        >
                          {["array", "object", "string", "number", "boolean", "null"].map((type) => {
                            return (
                              <MenuItem value={type} sx={{fontFamily: "Inter, Sans-serif", textTransform: "capitalize"}}>{type}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </>
                  )
                }
                <TableCell sx={{ textAlign: 'center' }}>
                  {index !== rows.length && (
                    <IconButton size="small" aria-label="Delete row" onClick={() => handleDeleteRow(index)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FormDataComponent;
