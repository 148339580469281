import React, { useState } from 'react';
import { Tabs, Tab, Modal, TextField, Button, IconButton, Box, FormControlLabel, Checkbox } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { updateEntity } from '../../redux-store/currentUserActions';
import { useDispatch } from "react-redux";
import Audit from '../Collections/Audit';
import { useGuestUser } from '../../contexts/GuestUserContext';

const ApiOptions = ({ selectedIcon, api }) => {
  const { isGuestUser } = useGuestUser();
  const lastProject = JSON.parse(localStorage.getItem('lastSelectedProject'));
  const [selectedOption, setSelectedOption] = useState("option2");
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [settings, setSettings] = useState({
    healthCheck: api?.health_check || false,
    autoDocumentation: api?.auto_documentation || false,
    enableMonitoring: api?.enable_monitoring || false,
  });
  const dispatch = useDispatch();

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    selectedIcon(option);
  };

  const handleSettingsChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings({ ...settings, [name]: type === 'checkbox' ? checked : value });
  };

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen);
  };

  const handleSettingsSubmit = (e) => {
    e.preventDefault();
    dispatch(updateEntity({ entity: { health_check: settings?.healthCheck, auto_documentation: settings.autoDocumentation, enable_monitoring: settings.enableMonitoring }, id: api.id }));
    toggleSettingsModal();
  };

  const tabs = [
    { value: "option1", label: "Overview", disabled: false },
    // { value: "option3", label: "Metrics", disabled: false },
    { value: "option2", label: "Request", disabled: false },
    { value: "option4", label: "Collected Samples", disabled: false }
  ];

  lastProject?.audit_enabled && tabs.push({ value: "option5", label: "Audit", disabled: false });

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    p: 4,
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
        <Tabs
          className='tab-style'
          value={selectedOption}
          onChange={(event, newValue) => handleOptionChange(newValue)}
          TabIndicatorProps={{ style: { display: "none" }}}
          sx={{ marginLeft: '11px', minHeight: '32px', height: '32px' }}
        >
          {tabs.map(tab => (
            <Tab
              disabled={ isGuestUser && tab.value === "option4" }
              key={tab.value}
              value={tab.value}
              label={tab.label}
              sx={{
                minHeight: '32px',
                height: '32px',
                textTransform: 'none',
                color: selectedOption === tab.value ? "#fff !important" : "#575757",
                backgroundColor: selectedOption === tab.value ? '#6241D4' : '#e9e9e9',
                fontSize: "12px",
                fontFamily: "Inter, Sans-serif"
              }}
            />
          ))}
        </Tabs>
        { api?.id && (
          <IconButton onClick={toggleSettingsModal}>
            <SettingsIcon />
          </IconButton>
        )}
      </Box>
      <Modal open={isSettingsModalOpen} onClose={toggleSettingsModal}>
        <Box sx={modalStyle}>
          <form onSubmit={handleSettingsSubmit}>
            <FormControlLabel
              control={<Checkbox checked={settings.healthCheck} onChange={handleSettingsChange} name="healthCheck" />}
              label="Health Check"
              sx={{ marginTop: '20px', span : {fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px"} }}
            />
            <FormControlLabel
              control={<Checkbox checked={settings.autoDocumentation} onChange={handleSettingsChange} name="autoDocumentation" />}
              label="Auto Documentation"
              sx={{ marginTop: '20px', span : {fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px"} }}
            />
            <FormControlLabel
              control={<Checkbox checked={settings.enableMonitoring} onChange={handleSettingsChange} name="enableMonitoring" />}
              label="Enable Monitoring"
              sx={{ marginTop: '20px', span : {fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "12px"} }}
            />
            <Button type="submit" variant="contained" style={{ marginTop: '20px', display: 'block', width: '100%', fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "11px" }}>
              Save Settings
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ApiOptions;
