import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
  Typography,
  Box,
  Stack,
  IconButton,
  MenuList,
  MenuItem,
  Avatar,
  Tabs,
  Tab,
} from '@mui/material';
import { CancelOutlined, MoreVert } from '@mui/icons-material';
import MainLayout from '../../layouts/MainLayout';
import DeleteDialog from '../Common/DeleteDialog';
import CustomTable from '../Common/CustomTable';
import CustomMenu from '../Common/CustomMenu';
import CustomChip from '../Common/CustomChip';
import CustomTabPanel from '../Common/CustomTabPanel';
import BuildSuite from './BuildSuite';
import EditDetail from './EditDetail';
import LoadingOverlay from '../Common/LoadingOverlay';
import TestSuiteBuildsDrawer from './TestSuiteBuildsDrawer';
import TestSuiteHeader from "./TestSuiteHeader";

import {
  getTestSuite,
  getTestScenariosForSuite,
  getTestSuiteTags,
} from '../../redux-store/testSuiteReducers/testSuiteActions';
import { detachScenarioFromSuite } from '../../redux-store/testSuiteScenarioReducers/testSuiteScenarioActions';
import { getAllVariableSets } from '../../redux-store/variableSetReducers/variableSetActions';
import { getOwners } from '../../redux-store/currentUserActions';
import useSearchParams from '../../hooks/useSearchParams';
import { a11yProps } from '../../utils/tabHelpers';
import { STATUS_TO_ICON, BUILD_STATUS_TO_COLOR } from '../../config/constants';

const drawerItems = [
  { icon: 'article', title: 'Details', id: 'detail' },
  { icon: 'play', title: 'Run', id: 'run' },
  { icon: 'search', title: 'Debug', id: 'debug', disabled: true },
  { icon: 'build', title: 'Builds', id: 'builds' }
];

const TestSuite = function ({ project, testSuiteId }) {
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');
  const isLoading = useSelector((state) => state.testSuite.isLoading);
  const [selectedItemContent, setSelectedItemContent] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [attachedScenarios, setAttachedScenarios] = useState([]);
  const [totalTestScenarios, setTotalTestScenarios] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openDetachScenarioId, setOpenDetachScenarioId] = useState('');
  const [anchorEls, setAnchorEls] = useState({});

  useEffect(() => {
    getFilters()
    getTestSuiteDetail();
  }, []);

  useEffect(() => {
    fetchAttachedTestScenarios();

    // keep page query-param in sync with state
    window.history.pushState('', '', `?page=${pageNumber}`);
  }, [pageNumber]);

  const getFilters = async () => {
    await Promise.all([
      dispatch(getTestSuiteTags({ projectId: project.id })),
      dispatch(getAllVariableSets({ projectId: project.id, isEnvironment: true, pageNumber: 1 })),
      dispatch(getOwners({ projectId: project.id })),
    ]);
  };

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const getTestSuiteDetail = async () => {
    await dispatch(getTestSuite({ testSuiteId }));
  };

  const fetchAttachedTestScenarios = async () => {
    const response = await dispatch(
      getTestScenariosForSuite({
        projectId: project.id,
        testSuiteId,
        pageNumber,
        pageSize: 100000
      })
    );

    setAttachedScenarios(response?.payload?.test_scenarios);
    setTotalTestScenarios(response?.payload?.total);
    setTotalPages(response?.payload?.total_pages);
  };

  const onDetachTestScenario = async () => {
    if (openDetachScenarioId) {
      await dispatch(
        detachScenarioFromSuite({
          testScenarioId: openDetachScenarioId,
          testSuiteId,
        })
      );
      setAttachedScenarios(
        attachedScenarios.filter(
          (testScenario) => testScenario.id !== openDetachScenarioId
        )
      );
      setOpenDetachScenarioId('');
      if (attachedScenarios.length === 1) setTotalPages(0);
    }
  };

  const onDetachTestScenarioClick = (testScenarioId) => setOpenDetachScenarioId(testScenarioId);
  const renderDrawerItem = ({selectedItem, onDrawerClose}) => {
    const props = { projectId: project.id, testSuiteId: testSuiteId };
    switch (selectedItem) {
      case 'run':
        setSelectedItemContent(<BuildSuite {...props} closeDrawer={onDrawerClose} />);
        return false;
      case 'debug':
        return <></>;
      case 'builds':
        setSelectedItemContent(<TestSuiteBuildsDrawer {...props} closeDrawer={onDrawerClose} />);
        return false;
      default:
        setSelectedItemContent(<EditDetail {...props} closeDrawer={onDrawerClose} />);
        return false;
    }
  };

  const renderAttachedTestScenarios = () => {
    if (isLoading) {
      return (<LoadingOverlay isLoading={isLoading} />);
    }
    if (!attachedScenarios?.length || attachedScenarios.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No test scenario is attached.</h3>
          <h6>Go to test scenario's list and select test scenarios to attach with test suite.</h6>
        </Box>
      )
    }

    return (
      <CustomTable
        headers={[
          {
            id: 'code',
            type: 'text',
            text: 'Code',
          },
          {
            id: 'title',
            type: 'text',
            text: 'Title',
          },
          {
            id: 'status',
            type: 'text',
            text: 'Status',
          },
          {
            id: 'tags',
            type: 'text',
            text: 'Tags',
          },
          {
            id: 'owner',
            type: 'text',
            text: 'Owner',
          },
          {
            id: 'lastRunResult',
            type: 'text',
            text: 'Last Run Result',
          },
          {
            id: 'actions',
            type: 'text',
            text: 'Actions',
            align: 'center',
          },
        ]}
        rows={attachedScenarios.map(
          ({
            id,
            code,
            title,
            description,
            status,
            tags,
            owner,
            test_scenario_statuses,
          }) => ({
            id,
            cells: [
              {
                id: 'code-' + id,
                type: 'text',
                text: code,
                sx: { width: '100px' },
              },
              {
                id: 'title-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title">
                    <Typography variant="body1" className="truncate-1-lines" title={title}>{title}</Typography>
                    <Typography
                      variant="body2"
                      color="gray"
                      className="truncate-1-lines"
                      title={description}
                    >
                      {description}
                    </Typography>
                  </Stack>
                ),
                sx: { width: '300px' },
              },
              {
                id: 'status-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1">
                    {STATUS_TO_ICON[status] || ''} {status || ''}
                  </Typography>
                ),
                sx: { width: '120px' },
              },
              {
                id: 'tags-' + id,
                type: 'component',
                content: (
                  <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1} className="chips">
                    {(tags || []).map((tag) => (
                      <CustomChip key={tag} label={tag} />
                    ))}
                  </Stack>
                ),
                sx: { width: '150px' },
              },
              {
                id: 'owner-' + id,
                type: 'component',
                content: owner ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar
                      alt={owner.name}
                      src={owner.avatar}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body1" className="truncate-2-lines">{owner.name}</Typography>
                  </Stack>
                ) : null,
                sx: { width: '140px' },
              },
              {
                id: 'scenario-status-' + id,
                type: 'component',
                content: (
                  <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1} className="chips lastrun">
                    {(test_scenario_statuses || []).map(
                      (
                        {
                          environment_set: env,
                          status: buildStatus,
                          last_run_at: lastRunAt,
                        },
                        i
                      ) => (
                        <CustomChip
                          key={env?.id || `build-status-${i}`}
                          className={buildStatus}
                          title={buildStatus}
                          label={
                            <span>
                              {env?.title || '-'}:{' '}
                              <span
                                style={{
                                  color:
                                    BUILD_STATUS_TO_COLOR[buildStatus] ||
                                    'black',
                                }}
                              >
                                {lastRunAt
                                  ? dayjs(lastRunAt * 1000).fromNow() // multiply timestamp with 1000 to make it js compatible
                                  : buildStatus || '-'}
                              </span>
                            </span>
                          }
                        />
                      )
                    )}
                  </Stack>
                ),
                sx: { width: '150px' },
              },
              {
                id: 'actions-' + id,
                type: 'component',
                align: 'center',
                content: (
                  <>
                    <IconButton
                      id={`actions-btn-${id}`}
                      aria-label="actions"
                      onClick={(e) => handleClick(e, id)}
                    >
                      <MoreVert />
                    </IconButton>

                    <CustomMenu
                      labelledBy={'actions-btn-' + id}
                      anchorEl={anchorEls[id]}
                      onClose={() => handleClose(id)}
                    >
                      <MenuList sx={{ minWidth: '100px' }}>
                        <MenuItem
                          onClick={() => {
                            onDetachTestScenarioClick(id);
                            handleClose(id);
                          }}
                        >
                          Detach
                        </MenuItem>
                      </MenuList>
                    </CustomMenu>
                  </>
                ),
                sx: { width: '120px' },
              },
            ],
          })
        )}
      />
    );
  };

  const renderDetachScenarioConfirmModal = () => {
    return (
      <DeleteDialog
        title="Confirm Detach"
        open={!!openDetachScenarioId}
        onClose={() => setOpenDetachScenarioId('')}
        deleteBtnLabel="Detach"
        secondaryBtnLabel="Cancel"
        secondaryBtnIcon={<CancelOutlined />}
        onDeleteAction={onDetachTestScenario}
        onSecondaryAction={() => setOpenDetachScenarioId('')}
      >
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Are you sure you want to detach this test scenario?
        </Typography>
      </DeleteDialog>
    );
  };

  return (
    <MainLayout
      sx={{ width: 'calc(100% - 100px)' }}
      isLoading={isLoading}
      drawerItems={drawerItems}
      renderDrawerItem={renderDrawerItem}
      enableSideDrawer={true}
      selectedItemContent={selectedItemContent}
      withPagination={false}
      sxLeftSide={{maxWidth: "70%"}}
      renderHeader={() => (<TestSuiteHeader testSuiteId={testSuiteId} />)}
    >
      <Tabs
        value={tabIndex}
        onChange={(e, newIndex) => setTabIndex(newIndex)}
        sx={{padding: "0 24px"}}
      >
        <Tab label="Test Scenarios" {...a11yProps(0)} />
      </Tabs>
      <Box sx={{maxHeight: "calc(100vh - 200px)", overflow: "auto"}}>
        <CustomTabPanel value={tabIndex} index={0}>
          {renderAttachedTestScenarios()}
        </CustomTabPanel>
      </Box>
      {renderDetachScenarioConfirmModal()}
    </MainLayout>
  );
};

export default TestSuite;
