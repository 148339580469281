import { apiAgent } from "../../config/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateLoadError,
  setLoadingState,
  setDataSetList,
  setTotal,
  setTotalPages
} from "./dataSetSlice";

const createDataSet = createAsyncThunk(
  "createDataSet",
  async ({entityId, testCaseSetId, ...data}, { dispatch, rejectWithValue }) => {
    console.log(data);
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/v1/data_sets/`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          ...data,
          test_case_set_id: testCaseSetId,
          entity_id: entityId
        },
      });
      if (response.status === 201) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response?.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const updateDataSet = createAsyncThunk(
  "updateDataSet",
  async (data, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    const requestData = {
      body: data.body,
      body_type: data.bodyType,
      params: data.params,
      headers: data.headers,
      title: data.title
    };
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/api/v1/data_sets/${data.id}/`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: requestData,
      });
      if (response.status === 201) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const createPrivateDataSet = createAsyncThunk(
  "createPrivateDataSet",
  async (data, {dispatch, rejectWithValue}) => {
    await dispatch(setLoadingState(true));
    const requestData = {
      body: data.body,
      body_type: data.bodyType,
      params: data.params,
      headers: data.headers,
      entity_id: data.entityId,
      test_case_set_id: data.testCaseSetId
    }
    if (data?.title?.trim()) {
      requestData.title = data.title.trim();
    }

    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/v1/data_sets/create_private`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: requestData
      });
      if (response?.status === 201) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
)

const getDataSet = createAsyncThunk(
  "getDataSet",
  async ({dataSetId}, {dispatch, rejectWithValue}) => {
    await dispatch(setLoadingState(true));

    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/v1/data_sets/${dataSetId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
      if (response?.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
)

const getDataSetList = createAsyncThunk(
  "getDataSetList",
  async ({projectId, pageNumber, pageSize}, {dispatch, rejectWithValue}) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/api/v1/data_sets/`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          project_id: projectId,
          page: pageNumber,
          page_size: pageSize
        }
      });
      if (response?.status === 200) {
        await dispatch(setDataSetList({dataSetList: response?.data?.data_sets}));
        await dispatch(setTotal({total: response?.data?.total}));
        await dispatch(setTotalPages({totalPages: response?.data?.total_pages}));
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
)

const deleteDataSet = createAsyncThunk(
  "deleteDataSet",
  async ({dataSetId}, {dispatch, rejectWithValue}) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/api/v1/data_sets/${dataSetId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
      if (response?.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
)

export {
  createDataSet,
  updateDataSet,
  createPrivateDataSet,
  getDataSet,
  getDataSetList,
  deleteDataSet
};
