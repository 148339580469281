import { Box, Tab, Tabs } from '@mui/material';
import { useState, useEffect } from 'react';
import OverviewComponent from '../Collections/OverviewComponent';
import FolderAuthorizationTabComponent from "./FolderAuthorizationTabComponent";
import RunnerComponent from '../Runner/RunnerComponent';
import VariablesComponent from '../Common/VariablesComponent';
import MetricsComponent from '../Collections/MetricsComponent';
import { fetchStats } from '../../redux-store/currentUserActions';
import { useDispatch } from 'react-redux';
import Audit from '../Collections/Audit';
import { fetchAuditData, fetchAuditDataDetails } from '../../redux-store/collectionActions';

const FolderScreen = ({state, folder}) => {
  const lastProject = JSON.parse(localStorage.getItem('lastSelectedProject'));
  const [activeTab, setActiveTab] = useState(0);
  const [stats, setStats] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [auditDataDetails, setAuditDataDetails] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (folder?.id) {
      dispatch(fetchStats({ folderId: folder?.id })).then((resp) => {
        if (resp && resp.payload) {
          setStats(resp.payload);
        }
      });

      // dispatch(fetchAuditData({ resourceName: 'folders', collectionId: folder?.id })).then((res) => {
      //   if (res?.payload) {
      //     setAuditData(res.payload.data);
      //   }
      // });

      // dispatch(fetchAuditDataDetails({ resourceName: 'folders', collectionId: folder?.id })).then((res) => {
      //   if (res?.payload) {
      //     setAuditDataDetails(res.payload.data);
      //   }
      // });
      
    }
  }, [folder?.id, dispatch]);

  const tabs = [
    {
      label: "Overview",
      component: <OverviewComponent resource={folder || state.selectedFolder} />
    },
    // {
    //   label: "Metrics",
    //   component: <MetricsComponent testData={stats} responseTimeData={stats} />
    // },
    {
      label: "Authorization",
      component: <FolderAuthorizationTabComponent folder={folder || state.selectedFolder}/>
    },
    {
      label: "Variables",
      component: <VariablesComponent resource={folder} resourceType={'Folder'} />
    },
    {
      label: "Runner",
      component: <RunnerComponent resource={folder || state.selectedFolder}/>
    },
  ];

  lastProject?.audit_enabled &&
  tabs.push({
    label: "Audit",
    component: <Audit resource={folder} auditData={auditData} detailedData={auditDataDetails} />
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    setActiveTab(0);
  }, [folder]);

  return (
    <Box sx={{ backgroundColor: '#fff', flexGrow: 1, height: 'calc(100vh - 100px)' }}>
      <Box sx={{ marginTop: '10px', marginLeft: '-5px' }}>
        <Tabs 
          className='tab-style' 
          value={activeTab} 
          onChange={handleTabChange} 
          TabIndicatorProps={{ style: { display: "none" }}}
        >
          {tabs.map((tab, index) => (
            <Tab 
              key={tab?.label}
              label={tab?.label} 
              style={{ 
                minHeight: '32px', 
                height: '32px',
                textTransform: 'none', 
                color: activeTab === index ? '#fff' : '#575757', 
                backgroundColor: activeTab === index ? '#6241D4' : '#e9e9e9'
                , fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"
              }} 
            />
          ))}
        </Tabs>
        {/* FIXME :: Something still wrong. Temporarily fixing with maxHeight */}
        <Box sx={{ height: 'calc(100% - 50px)', maxHeight: '900px', padding: '15px', paddingBottom: 10, overflowY: 'auto' }}>
          {tabs[activeTab].component}
        </Box>
      </Box>
    </Box>
  );
}

export default FolderScreen;
