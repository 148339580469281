import {
  Typography,
  Box,
  Toolbar,
  AppBar,
  Grid,
  Tabs,
  Tab,
  IconButton,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTab, removeTab } from '../../redux-store/tabSlice';
import CustomSnackbar from '../Common/CustomSnackbar';
import { truncate } from 'lodash';
// Constants
import { ResourceTypes } from '../../config/constants';

const CollectionNavbar = ({
  state,
  collection,
  folder,
  api,
  onCollectionClick,
  onFolderClick,
  onEntityClick,
  addNewRequestToResource,
  setIsUserInitiatedTabChange,
}) => {
  const dispatch = useDispatch();
  // const tabs = useSelector((state) => state.user.tabs);
  const { tabs, activeTabId } = useSelector((state) => state.tabs);
  const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTabId);
  const selectedSidebarState = useSelector(
    (state) => state.user.selectedSidebarState
  );
  const [activeTab, setActiveTab] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (selectedSidebarState) {
      // Find the index of the selectedSidebarState in the tabs array
      const index = tabs.findIndex((tab) => tab.id === selectedSidebarState.id);
      // If the selectedSidebarState is found in the tabs array, set it as the active tab
      if (index !== -1) {
        setActiveTab(index);
      }
    }
  }, [selectedSidebarState, tabs]);

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    const tabId = tabs[newValue].id;
    if (tabId) selectTab(tabId);
  };

  const handleTabClick = (tab) => {
    dispatch(selectTab(tab?.id));

    if (!tab?.resource) return;

    let type = tab?.resource?.type;

    if (!type && tab.resource?.entityable_type) {
      type = "entity";
    }

    switch (type) {
      case ResourceTypes.COLLECTION:
        const { resource: collection } = tab;

        onCollectionClick({ collection });
        break;
      case ResourceTypes.FOLDER:
        const { resource: folder } = tab;

        onFolderClick({
          folder,
          collection: folder.collection,
          withLoading: false,
        });
        break;
      case ResourceTypes.ENTITY:
        const { resource: entity } = tab;

        onEntityClick({
          entity,
          folder: entity.folder,
          collection: entity.collection,
          withLoading: false,
        });
        break;
    }
  };

  const handleNewTabClick = () => {
    setIsUserInitiatedTabChange(true);
    addNewRequestToResource();
  };

  const handleCloseTabClick = (event, index) => {
    event.stopPropagation();

    const isClosingActiveTab = index === activeTab;
    const nextActiveTabIndex = index === tabs.length - 1 ? index - 1 : index;
    const nextActiveTab = tabs[nextActiveTabIndex] || null;

    dispatch(removeTab(tabs[index].id));

    if (isClosingActiveTab && nextActiveTab) {
      setActiveTab(nextActiveTabIndex);
      dispatch(selectTab(nextActiveTab));
    } else if (index < activeTab) {
      setActiveTab(activeTab - 1);
    }

    if (!nextActiveTab) {
      setActiveTab(0);
      dispatch(selectTab({}));
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }} style={{ width: '100%' }}>
      <AppBar
        position="static"
        style={{ boxShadow: 'none', background: '#fff' }}
      >
        <Toolbar
          variant="dense"
          style={{ padding: 0, height: '37px', minHeight: '37px' }}
        >
          <Grid container style={{ border: '1px solid #e9e9e9' }}>
            <Tabs
              variant="scrollable"
              value={activeTabIndex}
              onChange={handleTabChange}
              indicatorColor="#6241d4"
              textColor="inherit"
              sx={{
                maxWidth: 'calc(100% - 50px)',
                marginLeft: 0,
                minHeight: '37px',
                height: '37px',
                backgroundColor: '#fff',
                borderLeft: 'none',
                '& .MuiTabs-indicator': { top: 0 },
                '& .MuiTab-root': {
                  minHeight: '37px',
                  height: '37px',
                  borderTop: '2px solid #e9e9e9',
                  borderRight: '1px solid #e9e9e9',
                },
                '& .Mui-selected': {
                  minHeight: '37px',
                  height: '37px',
                  borderTop: '2px solid #6241d4'
                },
              }}
            >
              {tabs?.map((tab, index) => (
                <Tab
                  key={tab?.id}
                  onClick={() => handleTabClick(tab)}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: '12px', fontFamily: "Inter, Sans-serif", fontWeight: "500", color: activeTabIndex === index ? "#6241d4" : '#333', textTransform: "none" }}>
                        {truncate(tab?.name || '', { length: 22 })}
                      </Typography>
                      <IconButton
                        size="small"
                        color="inherit"
                        sx={{ '& .MuiSvgIcon-root': { fontSize: '0.9em' } }}
                        onClick={(event) => handleCloseTabClick(event, index)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  }
                  sx={{
                    color: '#333',
                  }}
                />
              ))}
            </Tabs>
            <Tooltip title="Add Entity/Request" placement="top-start">
              <IconButton color="lightgrey" onClick={handleNewTabClick} >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Toolbar>
      </AppBar>
      <CustomSnackbar
        open={snackbarOpen}
        handleClose={closeSnackbar}
        message="Please select a collection or folder first!"
        severity="warning"
      />
    </Box>
  );
};

export default CollectionNavbar;
