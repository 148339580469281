import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Checkbox,
  TextField,
  Select,
  Card,
  CardContent,
  MenuItem,
  Button,
  IconButton,
  Grid,
  Autocomplete,
  Popover,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  InputAdornment,
  Tabs,
  Tab
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import AddOutlined from "@mui/icons-material/AddOutlined";
import { AspectRatioOutlined, AutorenewOutlined, InfoOutlined } from "@mui/icons-material";
import { createEntityTestCase, regenerateTestCaseFromQuery, generateTestCaseFromQuery, getEntityTestCases, getResponseApiTests, getPastResponses, updateTestCase, deleteTestCase, fetchVariables, fixTestCase, updateEntity, getEntity } from "../../redux-store/currentUserActions";
import { updateEntity as updateLocalEntity } from "../../redux-store/currentUserSlice";
import LoadingOverlay from "../Common/LoadingOverlay";
import AdvancedTestCase from './AdvancedTestCase';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

SyntaxHighlighter.registerLanguage('json', json);

const JsonDisplay = ({ jsonString, highlightKeys }) => {
  const highlightJson = (jsonString, highlightKeys) => {
    try {
      let jsonStringified = JSON.stringify(JSON.parse(jsonString), null, 2);

      if (Array.isArray(highlightKeys) && highlightKeys.length > 0) {
        highlightKeys.forEach((key) => {
          const regex = new RegExp(`(\\s*")(${key}")`, 'g');
          jsonStringified = jsonStringified.replace(regex, (match, p1, p2) => {
            return `${p1}<span style="background-color: #ffcccb;">${p2}</span>`;
          });
        });
      }

      return jsonStringified;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return jsonString;
    }
  };

  const highlightedJsonString = highlightJson(jsonString, highlightKeys);

  return (
    <div style={{ fontFamily: 'monospace' }}>
      <pre dangerouslySetInnerHTML={{ __html: highlightedJsonString }} style={{ whiteSpace: 'pre-wrap' }} />
    </div>
  );
};


const TestCases = ({ entity }) => {
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);
  const currentTestResponse = entity?.response || {};

  const [rows, setRows] = useState([{ enable: true, lhs: "", comparison: "", rhs: "", priority: 0 }]);
  const [lhs, setLhs] = useState('');
  const [comparison, setComparison] = useState('');
  const [rhs, setRhs] = useState('');
  const [priority, setPriority] = useState(0);
  const [apiTests, setApiTests] = useState();
  const [updatedTest, setUpdatedTest] = useState();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const boundaryRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [variables, setVariables] = useState([]);
  const [testCaseQuery, setTestCaseQuery] = useState('');
  const [testCaseQueryError, setTestCaseQueryError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openFixConfirmation, setOpenFixConfirmation] = useState(false);
  const [testToBeFixed, setTestToBeFixed] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openJsonDialog, setOpenJsonDialog] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [openFailureDialog, setOpenFailureDialog] = useState(false);
  const [failureData, setFailureData] = useState(null);
  const [openAddTestModal, setOpenAddTestModal] = useState(false);
  const [generatedAdvanceTestCases, setGeneratedAdvanceTestCases] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isGeneratingTestCases, setIsGeneratingTestCases] = useState(false);
  const [isReloadingTestCases, setIsReloadingTestCases] = useState(false);
  const { openSnackbar } = useSnackbar();

  const tabsData = [
    { label: "Basic", component: '<ResponseBodyComponent entity={entity} />' },
    { label: "Advanced", component: '<TestResponse entity={entity} />' },
  ];

  const tabStyles = (isActive) => ({
    color: isActive ? '#fff' : '#575757',
    backgroundColor: isActive ? '#6241D4' : '#e9e9e9',
    height: '32px',
    minHeight: '32px',
    textTransform: 'none'
  });

  const [activeTab, setActiveTab] = useState(entity?.test_type === 'basic' ? 0 : 1);

  const open = Boolean(anchorEl);

  const testCaseQueryExamples = [
    "response_code should be equal to 200",
    "my.variable.name should be less than 1000",
    "response_time should be greater than 100"
  ];

  useEffect(() => {
    if(selectedSidebarState){
      fetchEntityTestCases();
      fetchResourceVariables();
    }
  }, []);

  useEffect(() => {
    if (currentTestResponse?.id) {
      fetchResponseApiTests()
    }
  }, [currentTestResponse, dispatch]);

  useEffect(() => {
    if (activeTab === 0) {
      fetchEntityTestCases();
      fetchResponseApiTests();
    }
  }, [activeTab]);

  const isReadOnly = (value) => {
    return ["{{response_code}}", "{{response_time}}", "{{response_body}}", "{{response_body_keys}}", "{{response_headers}}", "{{response_headers_keys}}"].includes(value)
  };

  const fetchResponseApiTests = async () => {
    const res = await dispatch(getResponseApiTests({response_id: currentTestResponse?.id}))
    if(res?.payload?.api_tests){
      setApiTests(res?.payload?.api_tests)
    }
  }

  const fetchEntityTestCases = async () => {
    const res = await dispatch(
      getEntityTestCases({
        id: selectedSidebarState?.id, ...(activeTab === 1  && { testType: 'advanced' })
      })
    );

    let result = res?.payload || []

    if (activeTab === 0) {
      const defaultPlaceholder = { enable: true, lhs: "", comparison: "", rhs: "", priority: 0 };
      if (result.length === 0 || result[result.length - 1].lhs !== "") {
        result.push(defaultPlaceholder);
      }

      setRows(result);
    } else {
      if (result.length === 1 && result[0].lhs === "") {
        result = [];
      }

      setGeneratedAdvanceTestCases(result);
    }
  }

  const fetchResourceVariables = async () => {
    if (selectedSidebarState?.id) {
      dispatch(fetchVariables({ id: selectedSidebarState.id }))
        .then((res) => {
          if (res.payload) {
            const prependedData = [
              { key: 'response_body' },
              { key: 'response_body_key' },
              { key: 'response_time' },
              { key: 'response_code' },
              { key: 'response_headers' },
              ...res.payload
            ];
            setVariables(prependedData);
          }
        });
    }
  }

  const generateTestCases = () => {
    setIsGeneratingTestCases(true);
  
    dispatch(regenerateTestCaseFromQuery({
      id: selectedSidebarState?.id,
      ...(activeTab === 1 && { testType: 'advanced' })
    }))
    .then((action) => {
      console.log(action);
      setIsGeneratingTestCases(false);
  
      if (action?.payload?.error) {
        openSnackbar({ message: action.payload.error, severity: 'error' });
      } else if (action?.payload?.message) {
        openSnackbar({ message: action.payload.message, severity: 'success' });
      }
  
      setTimeout(() => {
        fetchEntityTestCases();
        if (currentTestResponse?.id) fetchResponseApiTests();
      }, 1000);
    })
    .catch((error) => {
      setIsGeneratingTestCases(false);
      openSnackbar({ message: "An unknown error occurred", severity: 'error' });
    });
  };  

  const addNewTestCase = () => {
    if (activeTab === 0) {
      const newRow = { enable: true, lhs: "", comparison: "", rhs: "", priority: 0 };
      setRows(prevRows => [...prevRows, newRow]);
    } else if (activeTab === 1) {
      handleOpenAddTestModal();
    }
  };

  const handleChange = (index, field, value) => {
    if (field === 'lhs' && value) {
      if (!value.startsWith('{{') && !value.endsWith('}}')) {
        value = `{{${value}}}`;
      }
    }

    let updatedValue = value;
    if (field === 'lhs') {
      switch (value) {
        case 'Response Code':
          updatedValue = '{{response_code}}';
          break;
        case 'Response Body':
          updatedValue = '{{response_body}}';
          break;
        case 'Response Body Keys':
          updatedValue = '{{response_body_keys}}';
          break;
        case 'Response Time':
          updatedValue = '{{response_time}}';
          break;
        case 'Response Header':
          updatedValue = '{{response_headers}}';
          break;
        case 'Response Header Keys':
          updatedValue = '{{response_headers_keys}}';
          break;
        case 'Variable':
          updatedValue = '{{variable_name}}';
          break;
        default:
          break;
      }
    }

    let updatedRows = rows.map((row, i) => {
      if (i === index) {
        let updatedRow = { ...row, [field]: value };
        setUpdatedTest(updatedRow)
        return updatedRow;
      }
      return row;
    });

    if (index === rows.length - 1 && (value && value.trim() !== '')) {
      const newRow = { enable: true, lhs: "", comparison: "", rhs: "", priority: 0 };
      updatedRows.push(newRow)
    }

    if (field === 'lhs'){
      setLhs(updatedValue)
    } else if(field === 'comparison'){
      setComparison(value)
    } else if(field === 'rhs'){
      setRhs(value)
    } else if (field === 'priority'){
      setPriority(value);
    }
    setRows(updatedRows || []);
  };

  const handleCheckboxChange = async (index, row) => {
    const updatedRows = [...rows];
    updatedRows[index].enable = !updatedRows[index].enable;
    setRows(updatedRows || []);
    if(row?.id){
      const state = {
        id: row?.id,
        testCase: {
          enable: updatedRows[index].enable
        }
      }
      const res = await dispatch(updateTestCase(state))
    }
  };

  const handleOutsideClick = async (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      if(updatedTest?.id){
        updateApiTestCase();
      }else{  
        const state = setState();
        if(lhs && rhs && comparison){
          const res = await dispatch(createEntityTestCase(state))
          if(res.payload?.test_case){
            resetStates();
            fetchEntityTestCases();
          }
        }
      }
    }
  };

  const handleDelete = (index, row) => {
    if (row.id) {
      dispatch(deleteTestCase(row.id))
        .then((action) => {
          if (action.payload && action.payload.success) {
            const updatedRows = [...rows];
            updatedRows.splice(index, 1);
            setRows(updatedRows || []);
          }
        })
        .catch((error) => {
          console.error("Failed to delete test case:", error);
        });
    } else {
      const updatedRows = [...rows];
      updatedRows.splice(index, 1);
      setRows(updatedRows || []);
    }
  };

  const handleOpenJsonDialog = (data) => {
    try {
      const parsedData = JSON.parse(data);
      const formattedData = JSON.stringify(parsedData, null, 2);
      setJsonData(formattedData);
    } catch (e) {
      setJsonData(data);
    }
    setOpenJsonDialog(true);
  };

  const handleFixTest = async (testId) => {
    const resp = await dispatch(fixTestCase(testId));
    fetchEntityTestCases();
    fetchResponseApiTests();
  };

  const handleOpenFailureDialog = (apiTest) => {
    try {
      const failureReason = apiTest.failure_reason;
      let { expected, actual, key_differences } = failureReason;

      // if (typeof expected === 'string') {
      //   expected = JSON.parse(expected);
      // }
      // if (typeof actual === 'string') {
      //   actual = JSON.parse(actual);
      // }
  
      setFailureData({ expected, actual, key_differences });
    } catch (e) {
      console.error('Failed to parse failure reason:', e);
      setFailureData(null);
    }
    setOpenFailureDialog(true);
  };  

  const handleFixConfirmation = (testId) => {
    setTestToBeFixed(testId);
    setOpenFixConfirmation(true);
  };

  const confirmFixTest = async () => {
    if (testToBeFixed) {
      await handleFixTest(testToBeFixed);
      setTestToBeFixed(null);
    }
    setOpenFixConfirmation(false);
  };

  const updateApiTestCase = async () => {
    const updatedState =  setUpdatedTestState();
    const res = await dispatch(updateTestCase(updatedState))
    if(res?.payload?.test_case){
      resetStates();
      fetchEntityTestCases();
      if (currentTestResponse?.id) fetchResponseApiTests();
    }
  }
  
  const setUpdatedTestState = () => {
    const state = {
      id: updatedTest?.id,
      testCase: {
        lhs: updatedTest?.lhs,
        comparison: updatedTest?.comparison,
        rhs: updatedTest?.rhs,
        priority: updatedTest?.priority
      }
    }
    return state;
  }

  const setState = () => {
    const state = {
      id: selectedSidebarState?.id,
      test_case: {
        lhs: lhs,
        comparison: comparison,
        rhs: rhs,
        priority: priority
      }
    }
    return state;
  }

  const resetStates = () => {
    setUpdatedTest();
    setLhs();
    setComparison();
    setRhs();
    setPriority();
  }

  const validateTestCaseQuery = (query) => {
    const regex = /(.*?) (should be|is|is not|is equal to|is less than|is greater than) (equal to|less than|greater than)? ?([\d\w\s]+)/;
    return regex.test(query);
  };

  const handleTestCaseQuerySubmit = () => {
    if (!validateTestCaseQuery(testCaseQuery)) {
      setTestCaseQueryError("Invalid test case query format. Please follow the examples.");
      return;
    }

    setIsLoading(true);

    dispatch(generateTestCaseFromQuery({
      id: selectedSidebarState?.id,
      query: testCaseQuery
    })).then((response) => {
      if (response.payload && response.payload.test_case) {
        setTestCaseQuery('');
        setTestCaseQueryError('');
      }

      setTimeout(() => {
        fetchEntityTestCases();
        if (currentTestResponse?.id) fetchResponseApiTests();
        setIsLoading(false);
      }, 1000);
    });
  };

  const handleRegenerateTests = () => {
    setOpenDialog(true);
  };

  const handleReloadTests = async () => {
    setIsReloadingTestCases(true);
  
    try {
      await Promise.all([
        fetchEntityTestCases(),
        currentTestResponse?.id ? fetchResponseApiTests() : Promise.resolve(),
      ]);
    } catch (error) {
      console.error('Error reloading test cases:', error);
    }    

    setIsReloadingTestCases(false);
  };  
  
  const confirmRegenerateTests = () => {
    generateTestCases();
    setOpenDialog(false);
  };
  
  const cancelRegenerateTests = () => {
    setOpenDialog(false);
  };

  const handleOpenAddTestModal = () => {
    setOpenAddTestModal(true);
  }

  const handleCloseAddTestModal = () => {
    fetchEntityTestCases();
    if (currentTestResponse?.id) fetchResponseApiTests();

    setOpenAddTestModal(false);
  }

  useEffect(() => {
    if(rows)
      document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [rows]);

  const updateEntityTestType = async (testType) => {
    try {
      const response = await dispatch(updateEntity({ 
        id: entity?.id, 
        testType: testType
      }));
  
      if (response && response?.payload && response.payload?.entity) {
        dispatch(updateLocalEntity({ id: entity?.id, testType: testType }));
      }
    } catch (error) {
      console.error('Error updating entity test type:', error);
    }
  };

  const handleTabChange = (event, newTab) => {
    if (newTab !== activeTab) {
      // `rows` arrays always have at least one placeholder item
      if ((activeTab === 0 && rows.length > 1) || (activeTab === 1 && generatedAdvanceTestCases.length > 0)) {
        setConfirmDialogOpen(true);
      } else {
        let _testType = newTab === 0 ? 'basic' : 'advanced';

        setActiveTab(newTab);

        // Assuming there are zero tests present right now
        updateEntityTestType(_testType);
      }
    }
  };  

  return (
    <Card sx={{ overflowY: 'auto', maxHeight: 'calc(55vh - 150px)', display: 'flex', flexDirection: 'column', marginTop: '10px',  width: '100%', '&::-webkit-scrollbar': { display: 'none' }, 'msOverflowStyle': 'none', 'scrollbarWidth': 'none' }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box>
            <Button variant="outlined" onClick={ handleReloadTests } size="small">
              <AutorenewOutlined />
            </Button>
            <Button variant="outlined" onClick={ handleRegenerateTests } sx={{ marginLeft: '5px' }} size="small">
              Regenerate Tests
            </Button>
            <Button variant="outlined" onClick={ addNewTestCase } sx={{ marginLeft: '5px' }} size="small">
              Add Test Case
            </Button>
          </Box>
          <Tabs
            orientation="horizontal"
            value={activeTab}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{ borderRadius: '5px', minHeight: '32px', height: '32px' }}
          >
            {tabsData.map((tab, index) => (
              <Tab
                key={index}
                style={{ ...tabStyles(activeTab === index) }}
                label={ tab.label }
              />
            ))}
          </Tabs>
        </Box>
        {/* <Box sx={{ marginBottom: '20px' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <TextField
                fullWidth
                label="Test Case Query"
                variant="outlined"
                size="small"
                color="primary"
                value={testCaseQuery}
                onChange={(e) => setTestCaseQuery(e.target.value)}
                error={!!testCaseQueryError}
                helperText={testCaseQueryError}
              />
            </Grid>
            <Grid item>
              <Button 
                size="small"
                variant="outlined" 
                onClick={(event) => setAnchorEl(event.currentTarget)}
                disabled={ isLoading }
              >
                <ListOutlinedIcon />
              </Button>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box sx={{ padding: '10px' }}>
                  {testCaseQueryExamples.map((example, index) => (
                    <Typography 
                      key={index} 
                      sx={{ padding: '5px', cursor: 'pointer' }}
                      onClick={() => {
                        setTestCaseQuery(example);
                        setAnchorEl(null);
                      }}
                    >
                      {example}
                    </Typography>
                  ))}
                </Box>
              </Popover>
            </Grid>
            <Grid item xs={1}>
              <Button variant="outlined" color="primary" onClick={ handleTestCaseQuerySubmit } size="small" disabled={ isLoading }>
                <AddOutlined />
              </Button>
            </Grid>
          </Grid>
        </Box> */}

        { activeTab === 0 ?
        <Box ref={boundaryRef}>
          <Box sx={{ position: 'relative', width: '100%', marginTop: '10px', marginLeft: '-10px' }}>
            <Table ref={tableRef} size="small" sx={{ width: '100%', alignItems: 'center', marginTop: '10px' }}>
              {/* <TableHead>
                <TableRow>
                  <TableCell sx={{ border: 'none' }}>Test</TableCell>
                  <TableCell sx={{ border: 'none' }}></TableCell>
                  <TableCell sx={{ border: 'none' }}></TableCell>
                  <TableCell sx={{ border: 'none' }}></TableCell>
                  <TableCell sx={{ border: 'none' }}>Result</TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                  {rows?.map((row, index) => (
                    <TableRow key={index} sx={{width: '400px', justifyContent: 'center', margin: '10px 0' }}>
                      {/* <TableCell sx={{ border: 'none', padding: 0 }}>
                        <Checkbox 
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          onChange={() => handleCheckboxChange(index, row)}
                          checked={row?.enable}
                          size="medium"
                        />
                      </TableCell> */}
                      <TableCell sx={{ border: 'none', padding: '0 10px', textAlign: 'center' }}>
                        <Select
                          id="priority"
                          value={row?.priority || 0}
                          onChange={(e) => handleChange(index, 'priority', e.target.value)}
                          style={{ backgroundColor: '#eef0f6', width: '100%' }}
                          sx={{
                            '& .MuiSelect-select': {
                              padding: '8.5px 14px'
                            }
                          }}
                        >
                          {['P0', 'P1', 'P2', 'P3', 'P4', 'P5'].map((priority, index) => (
                            <MenuItem key={index} value={index}>{priority}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell sx={{ border: 'none', padding: '0 10px', width: '20vw' }}>
                        <Autocomplete
                          freeSolo={true}
                          value={row?.lhs}
                          onChange={(event, newValue) => handleChange(index, 'lhs', newValue)}
                          options={variables.map(variable => variable.key)}
                          getOptionLabel={(option) => option}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              onBlur={(e) => {
                                const value = e.target.value;
                                if (value && !value.startsWith('{{') && !value.endsWith('}}')) {
                                  e.target.value = `{{${value}}}`;
                                  handleChange(index, 'lhs', e.target.value);
                                }
                              }}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: isReadOnly(row?.lhs),
                              }}
                              style={{ backgroundColor: '#eef0f6', width: '100%' }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell sx={{ border: 'none', padding: '0 10px', textAlign: 'center' }}>
                        <Select
                          size="small"
                          style={{ backgroundColor: '#eef0f6', width: '100%' }}
                          value={row?.comparison}
                          onChange={(e) => handleChange(index, 'comparison', e.target.value)}
                        >
                          <MenuItem value="equal">{'='}</MenuItem>
                          <MenuItem value="not_equal">{'!='}</MenuItem>
                          <MenuItem value="greater_than">{'>'}</MenuItem>
                          <MenuItem value="less_than">{'<'}</MenuItem>
                          <MenuItem value="greater_than_or_equal">{'>='}</MenuItem>
                          <MenuItem value="less_than_or_equal">{'<='}</MenuItem>
                          <MenuItem value="json_validate">{'json'}</MenuItem>
                          <MenuItem value="json_subset">{'json includes'}</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell sx={{ border: 'none', padding: '0 10px', width: '20vw' }}>
                        <TextField
                          className="test-case-rhs-input"
                          size="small"
                          style={{ backgroundColor: '#eef0f6', width: '100%' }}
                          value={row?.rhs}
                          onChange={(e) => handleChange(index, "rhs", e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {['{{response_body}}', '{{response_headers}}', '{{response_body_keys}}', '{{response_headers_keys}}'].includes(row?.lhs) && (
                                  <Button onClick={() => handleOpenJsonDialog(row?.rhs)}>
                                    <AspectRatioOutlined />
                                  </Button>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ border: 'none', padding: 0, textAlign: 'center' }}>
                        {apiTests?.find(apiTest => 
                          apiTest.test_case_id === row?.id
                        )?.status === true ? (
                          <Typography variant="body2" sx={{ color: 'green' }}>Passed</Typography>
                        ) : apiTests?.find(apiTest => 
                          apiTest.test_case_id === row?.id
                        )?.status === false ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body2" sx={{ color: 'red' }}>Failed</Typography>
                            <IconButton
                              size="small"
                              onClick={() => handleOpenFailureDialog(apiTests.find(apiTest => apiTest.test_case_id === row?.id))}
                              sx={{ marginRight: '-8px' }}
                            >
                              <InfoOutlined fontSize="small" />
                            </IconButton>
                            {/* <Button variant="text" size="small" onClick={() => handleFixConfirmation(row.id)}>
                              Fix it
                            </Button> */}
                          </Box>
                        ) : row?.lhs && <Typography variant="body2" sx={{ color: 'orange' }}>Running</Typography>
                        }
                      </TableCell>
                      <TableCell sx={{ border: 'none', padding: 0 }}>
                        <IconButton onClick={() => handleDelete(index, row)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
            <LoadingOverlay isLoading={ isGeneratingTestCases || isReloadingTestCases } />
          </Box>
          <Dialog
            open={openFixConfirmation}
            onClose={() => setOpenFixConfirmation(false)}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
          >
            <DialogTitle id="confirmation-dialog-title">
              Confirm Fix
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="confirmation-dialog-description">
                Are you sure you want to fix this test case?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenFixConfirmation(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={confirmFixTest} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openJsonDialog}
            onClose={() => setOpenJsonDialog(false)}
            aria-labelledby="json-dialog-title"
            fullWidth
            maxWidth="md"
          >
            <DialogTitle id="json-dialog-title">JSON</DialogTitle>
            <DialogContent>
              <pre>{jsonData}</pre>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenJsonDialog(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openFailureDialog}
            onClose={() => setOpenFailureDialog(false)}
            aria-labelledby="failure-dialog-title"
            fullWidth
            maxWidth="md"
          >
            <DialogTitle id="failure-dialog-title">Failure Reason</DialogTitle>
            <DialogContent>
              <TableContainer sx={{ borderRadius: '5px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: '0.875rem' }}>Expected</TableCell>
                      <TableCell sx={{ fontSize: '0.875rem' }}>Actual</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {failureData && (
                      <TableRow>
                        <TableCell>
                          <JsonDisplay
                            jsonString={JSON.stringify(failureData.expected, null, 2)}
                            highlightKeys={failureData?.key_differences?.missing_in_left || []}
                          />
                        </TableCell>
                        <TableCell>
                          <JsonDisplay
                            jsonString={JSON.stringify(failureData.actual, null, 2)}
                            highlightKeys={failureData?.key_differences?.missing_in_right || []}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenFailureDialog(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        : (
          <Box sx={{ position: 'relative' }}>
            <AdvancedTestCase entity={entity} openAddTestModal={openAddTestModal} handleOpenAddTestModal={handleOpenAddTestModal} handleCloseAddTestModal={handleCloseAddTestModal} generatedTestCases={generatedAdvanceTestCases} setGeneratedTestCases={setGeneratedAdvanceTestCases} />
            <LoadingOverlay isLoading={ isGeneratingTestCases || isReloadingTestCases } />
          </Box>
        )}
        <Dialog
          open={openDialog}
          onClose={cancelRegenerateTests}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Regeneration"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to regenerate new system-generated tests? This will remove any existing tests.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelRegenerateTests} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmRegenerateTests} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please remove your existing { activeTab === 0 ? 'Basic' : 'Advanced' } Test Cases to switch to { activeTab === 0 ? 'Advanced' : 'Basic' } tab.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
              Close
            </Button>           
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default TestCases;
