import React, {useState} from 'react';
import {Box, FormGroup, FormControlLabel, Checkbox, Paper} from "@mui/material";

const TaskPicker = ({onSelect}) => {
  const [selectedTasks, setSelectedTasks] = useState([]);
  const taskOptions = [
    {
      id: "generate_api_docs",
      label: "API Doc",
    },
    {
      id: "generate_unit_tests",
      label: "Unit Test",
    },
    {
      id: "generate_integration_tests",
      label: "Integration Test",
    },
    {
      id: "generate_security_tests",
      label: "Security Scan",
    },
  ];

  const handleCheckboxChange = (taskId) => {
    let newState;
    if (selectedTasks.indexOf(taskId) !== -1) {
      newState = [...selectedTasks.filter((id) => id !== taskId)];
    } else {
      newState = [...selectedTasks, taskId];
    }
    setSelectedTasks(newState);
    onSelect([...taskOptions.filter(task => newState.indexOf(task.id) !== -1)]);
  };

  return (
    <Box sx={{ my: 1, width: "100%" }}>
      <h6 style={{ fontWeight: "bold", textAlign: "left" }}>Select Tasks</h6>
      <Paper sx={{ width: "100%", maxHeight: "200px", overflow: "auto", boxShadow: "none", background: "#f5f5f5" }}>
        <FormGroup sx={{ fontFamily: "inherit" }}>
          {taskOptions.map((task) => (
            <FormControlLabel
              key={task.id}
              control={
                <Checkbox
                  checked={selectedTasks.indexOf(task.id) !== -1}
                  onChange={() => {
                    handleCheckboxChange(task.id);
                  }}
                />
              }
              label={task.label}
              sx={{borderBottom: "1px solid #e4e4e4", mr: 0}}
            />
          ))}
        </FormGroup>
      </Paper>
    </Box>
  )
}

export default TaskPicker;
