import React, { useState, useEffect } from 'react';
import { Box, Radio, Typography, FormControlLabel } from '@mui/material';
import BodyRaw from './BodyRaw';
import FormDataComponent from './FormDataComponent';
import Binary from './Binary';

const BodyComponent = ({ body, bodyType, onBodyChange, hideExtraFields=false }) => {
  const [bodyRaw, setBodyRaw] = useState();
  const [bodyForm, setBodyForm] = useState();
  const [bodyFormEncoded, setBodyFormEncoded] = useState();
  const [bodyGraphQl, setBodyGraphQl] = useState();
  const [bodyNone, setBodyNone] = useState();

  useEffect(() => {
    if (bodyType == 'raw') setBodyRaw(body);
    if (bodyType == 'form-data') setBodyForm(body);
    if (bodyType == 'x-www-form-urlencoded') setBodyFormEncoded(body);
    if (bodyType == 'graphql') setBodyGraphQl(body);
    if (bodyType == 'none') setBodyNone();
  }, [body]);

  const handleRadioChange = (event) => {
    if (event.target.value == 'raw') {
      onBodyChange(bodyRaw, event.target.value);
    } else if (event.target.value == 'form-data') {
      onBodyChange(bodyForm, event.target.value);
    } else if (event.target.value == 'x-www-form-urlencoded') {
      onBodyChange(bodyFormEncoded, event.target.value);
    } else if (event.target.value == 'graphql') {
      onBodyChange(bodyGraphQl, event.target.value);
    } else if (event.target.value == 'none') {
      onBodyChange(bodyNone, event.target.value);
    }
  };

  const handleBodyChange = (newBody) => {
    if (bodyType == 'raw') {
      setBodyRaw(newBody);
    } else if (bodyType == 'form-data') {
      setBodyForm(newBody);
    } else if (bodyType == 'x-www-form-urlencoded') {
      setBodyFormEncoded(newBody);
    } else if (bodyType == 'graphql') {
      setBodyGraphQl(newBody);
    } else if (bodyType === 'none') {
      setBodyNone();
    }
    onBodyChange(newBody, bodyType);
  };

  const renderBodyContent = () => {
    switch (bodyType) {
      case 'none':
        return (
          <Typography sx={{ color: '#999', margin: '0px 10px', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px" }}>
            This request does not have a body
          </Typography>
        );
      case 'raw':
        return <BodyRaw body={bodyRaw} onBodyChange={handleBodyChange} />;
      case 'form-data':
        return (
          <FormDataComponent body={bodyForm} onBodyChange={handleBodyChange} hideExtraFields={hideExtraFields} />
        );
      case 'x-www-form-urlencoded':
        return (
          <FormDataComponent
            body={bodyFormEncoded}
            onBodyChange={handleBodyChange}
            hideExtraFields={hideExtraFields}
          />
        );
      case 'binary':
        return <Binary />;
      case 'graphql':
        return <BodyRaw body={bodyGraphQl} onBodyChange={handleBodyChange} />;
      default:
        return (
          <Typography sx={{ color: '#999', margin: '0px 10px' }}>
            This request does not have a body
          </Typography>
        );
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
        {['none', 'form-data', 'x-www-form-urlencoded', 'raw', 'graphql'].map(
          (option) => (
            <FormControlLabel
              key={option}
              control={
                <Radio
                  checked={bodyType === option}
                  onChange={handleRadioChange}
                  value={option}
                  name="body-option"
                  color="primary"
                />
              }
              label={option}
              sx={{ 
                marginRight: '16px',
                span : {
                  fontFamily: "Inter, Sans-serif",
                  fontWeight: "550",
                  fontSize: "12px"
                }
              }}
            />
          )
        )}
      </Box>
      {renderBodyContent()}
    </>
  );
};

export default BodyComponent;
