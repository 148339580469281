import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import TestScenarioListPage from './TestScenarioListPage';
import TestScenarioDetail from './TestScenarioDetail';
import TestScenarioBuildLogs from '../TestScenarioBuildLogs/TestScenarioBuildLogs';

const TestScenariosPage = function({project, testScenarioId, buildId}) {
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {
    if (testScenarioId && buildId) {
      setCurrentView("testScenarioBuild");
    } else if (testScenarioId) {
      setCurrentView("testScenarioDetail");
    } else {
      setCurrentView("testScenariosList");
    }
  }, [testScenarioId]);

  let content = null;
  if (currentView === 'testScenariosList') {
    content = (
      <TestScenarioListPage project={project} />
    );
  } else if (currentView === 'testScenarioDetail') {
    content = (
      <TestScenarioDetail project={project} testScenarioId={testScenarioId} />
    );
  } else if (currentView === 'testScenarioBuild') {
    content = (
      <TestScenarioBuildLogs project={project} testScenarioId={testScenarioId} buildId={buildId} />
    );
  } else {
    // content = (<div>Loading...</div>);
  }

  return (
    <Box>
      {content}
    </Box>
  )
}

export default TestScenariosPage;
