import React from 'react';
import { Box, Typography, Paper, Table, TableBody, TableRow, TableCell } from '@mui/material';
import MonacoEditor from 'react-monaco-editor';

const BodyComponent = ({ state, api }) => {
  // TODO :: The original form and graphql components don't handle data coming from the backend. Fix that and then revisit the relevant parts of this component.
  const { formData, binaryFile, graphqlData } = state;
  const { request } = api;
  const selectedOption = request?.body_type;

  const StaticFormDataDisplay = ({ formData }) => (
    <Table size="small">
      <TableBody>
        {formData.map((data, index) => (
          <TableRow key={index}>
            <TableCell>{data.key}</TableCell>
            <TableCell>{data.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const StaticGraphQLDisplay = ({ graphqlData }) => (
    <Box>
      <Typography variant="h6" gutterBottom>GraphQL Query</Typography>
      <Typography style={{ whiteSpace: 'pre-wrap' }}>{graphqlData?.query}</Typography>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>GraphQL Variables</Typography>
      <Typography style={{ whiteSpace: 'pre-wrap' }}>{graphqlData?.variables}</Typography>
    </Box>
  );

  const renderBodyContent = () => {
    switch (selectedOption) {
      case 'none':
        return <Typography sx={{ margin: '0px 10px', color: '#999' }}>This request does not have a body</Typography>;
      case 'raw':
        return (
          <pre style={{ padding: '10px', overflowX: "auto", whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            <code>
              { JSON.stringify(request?.body) }
            </code>
          </pre>
        )
      case 'form-data':
      case 'x-www-form-urlencoded':
        return (
          <Paper variant="outlined" sx={{ padding: '16px', backgroundColor: '#f6f8fa' }}>
            <Typography variant="h6" gutterBottom>Form Data</Typography>
            {formData && formData.length > 0 ? (
              <StaticFormDataDisplay formData={formData} />
            ) : (
              <Typography>No form data available.</Typography>
            )}
          </Paper>
        );
      case 'binary':
        return (
          <Paper variant="outlined" sx={{ padding: '16px', backgroundColor: '#f6f8fa' }}>
            <Typography variant="h6" gutterBottom>Binary File Data</Typography>
            {binaryFile ? (
              <Typography>File: {binaryFile?.name} (Size: {binaryFile?.size} bytes)</Typography>
            ) : (
              <Typography>No file selected.</Typography>
            )}
          </Paper>
        );
      case 'GraphQL':
        return (
          <Paper variant="outlined" sx={{ padding: '16px', backgroundColor: '#f6f8fa' }}>
            {graphqlData ? (
              <StaticGraphQLDisplay graphqlData={graphqlData} />
            ) : (
              <Typography>No GraphQL data available.</Typography>
            )}
          </Paper>
        );
      default:
        return <Typography sx={{ margin: '0px 10px', color: '#999' }}>This request does not have a body</Typography>;
    }
  };

  return (
    <Box>
      <Typography variant="body1">
        Request Body Type: {selectedOption}
      </Typography>
      {renderBodyContent()}
    </Box>
  );
};

export default BodyComponent;
