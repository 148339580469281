
import React, {useState} from 'react';
import {Grid, Chip, TextField, Box} from "@mui/material";
import {Input, RemoveCircle} from "@mui/icons-material";

const TagPicker = ({onSelect}) => {
  const [tagText, setTagText] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  const onChange = (event) => {
    setTagText(event.target.value);
  }

  const handleDeleteTag = (tag) => {
    const tags = selectedTags.filter(t => t !== tag);
    setSelectedTags(tags);
    onSelect(tags.map(t => ({id: t, label: t})));
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && tagText && tagText.trim()) {
      event.preventDefault();
      const trimmedTag = tagText.trim();
      const tagIndex = selectedTags.findIndex(
        (tag) => tag.toLowerCase() === trimmedTag.toLowerCase()
      );
      if (tagIndex >= 0) {
        return;
      }
      const tags = [
        ...selectedTags,
        trimmedTag[0].toUpperCase() + trimmedTag.slice(1),
      ];
      setSelectedTags((prevState) => [
        ...prevState,
        trimmedTag[0].toUpperCase() + trimmedTag.slice(1),
      ]);
      onSelect(tags.map(t => ({id: t, label: t})));
      setTagText("");
    }
  }

  return (
    <Box sx={{ my: 1, width: "100%" }}>
      <h6 style={{ fontWeight: "bold", textAlign: "left" }}>Select tags</h6>
      <Grid sx={{pb: 1, borderBottom: "1px solid #e4e4e4"}}>
        {selectedTags.map(tag => (
          <Chip
            key={tag} sx={{ mr: 1, mt: 1, borderRadius: "4px" }} label={tag}
            deleteIcon={<RemoveCircle />}
            onDelete={() => {
              handleDeleteTag(tag);
            }}
          />
        ))}
        <div style={{display: "inline-block", width: "200px"}}>
          <input
            placeholder="Type to add"
            onKeyDown={handleKeyDown}
            value={tagText}
            onChange={onChange}
            style={{width: "inherit", verticalAlign: "middle", outline: "none", border: "none", borderBottom: "none", background: "transparent", marginTop: "8px"}}
          />
        </div>
      </Grid>
    </Box>
  )
}

export default TagPicker;
