import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiAgent } from '../config/axiosConfig';
import { updateLoadError } from "./currentUserSlice";

export const createSettings = createAsyncThunk(
  "createSettings",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${_.projectId}/settings`,
        data: { setting: _.settings },
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSettings = createAsyncThunk(
  "updateSettings",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { id, projectId, settings } = _;
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/api/v1/settings/${id}`,
        data: {
          setting: settings
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchSettings = createAsyncThunk(
  "fetchSettings",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { projectId, entityableId, entityableType } = _;
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${projectId}/settings`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json"
        },
        params: {
          project_id: projectId,
          entityable_id: entityableId,
          entityable_type: entityableType
        }
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }

    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);
