import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import {Box, Tooltip, Typography, TextField, IconButton, Divider} from '@mui/material';
import {ArticleOutlined, DeleteOutline, ExpandMore, ChevronRight, DragIndicator, ContentCopyOutlined, MoreVert} from '@mui/icons-material';
import CustomMenu from '../Common/CustomMenu';
import LoadingOverlay from "../Common/LoadingOverlay";
import EntitySelection from './EntitySelection';

const TestCaseSetHeader = (props) => {
  const { testCaseSet } = props;
  const [anchorEls, setAnchorEls] = useState({});
  const [title, setTitle] = useState("");
  const [isEdit, setIsEdit] = useState(testCaseSet.isNew ? true : false);
  // const isSaving = useSelector(state => (state.testCaseSet.isSaving));

  useEffect(() => {
    setTitle(testCaseSet?.title);
  }, []);

  const handleMenuClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClick = (event, elementType) => {
    if (elementType === "nonAccordion")
      event.stopPropagation();
  }

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const onChange = (event) => {
    const title = event.target.value;
    setTitle(event.target.value);
    if (title && title.trim() && testCaseSet.isNew) {
      props.onSaveTitle(title.trim());
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (title && title.trim()) {
        props.onSaveTitle(title.trim());
        setIsEdit(false);
      } else {
        // show error message
      }
    } else if (event.key === "Escape") {
      setTitle(testCaseSet?.title);
      setIsEdit(false);
    }
  }

  return (
    <Box sx={{width: "100%"}} className={`testCaseSetHeader ${anchorEls[testCaseSet.id] && "active"} ${props.isExpanded && "expanded"}`}>
      <div style={{display: "inline-block", verticalAlign: "top", margin: "24px 8px 0 0"}} className="dragIcon">
        <DragIndicator />
      </div>
      <div style={{display: "inline-block", width: "calc(100% - 150px)"}}>
        <div>
          <div style={{width: "100%", display: "inline-block", maxHeight: "32px"}}>
            {
              isEdit ?
              (<TextField sx={{width: "100%", outline: "none"}} size="small" placeholder="Add title" value={title} onClick={(event) => {handleClick(event, "nonAccordion")}} onKeyDown={handleKeyDown} onChange={onChange} />) :
              ( 
                <Tooltip title={testCaseSet.title || "Add a title"} placement="top-start">
                  <Typography size="small" className="truncate-1-lines" sx={{p: 1, pl: 0, height: "30px", width: "100%", fontSize: "14px", wordBreak: "break-all"}} onClick={(event) => {handleClick(event, "nonAccordion"); setIsEdit(true)}}>{testCaseSet.title || "Add a title"}</Typography>
                </Tooltip>
              )
            }
            {/* {(isSaving && isEdit) && (<LoadingOverlay isLoading={isSaving} />)} */}
          </div>
          <div style={{width: "100px", position: "absolute", right: "16px", display: "inline-block"}}>
            {
              props.isExpanded && 
              (
                <IconButton disabled={props.disableActions} aria-label="actions" sx={{width: "50px", verticalAlign: "top", display: "inline"}} className="expandIcon">
                  <ExpandMore />
                </IconButton>
              )
            }
            {
              !props.isExpanded && 
              (
                <IconButton disabled={props.disableActions} aria-label="actions" sx={{width: "50px", verticalAlign: "top", display: "inline"}} className="expandIcon">
                  <ChevronRight />
                </IconButton>
              )
            }
            <IconButton id={`actions-btn-${testCaseSet.id}`} onClick={(event) => {handleClick(event, "nonAccordion"); handleMenuClick(event, testCaseSet.id);}} aria-label="actions" sx={{width: "50px", verticalAlign: "top", display: "inline"}} className="menuIcon">
              <MoreVert />
            </IconButton>
          </div>
        </div>
        <div style={{width: "fit-content", marginTop: "8px"}} onClick={(event) => {handleClick(event, "nonAccordion")}}>
          <EntitySelection
            collections={props.collections}
            entities={props.entities}
            selectedEntity={props.entity}
            selectedCollection={props.collection}
            onCollectionChange={props.onCollectionChange}
            onEntityChange={props.onEntityChange}
            isDisabled={props.entity}
          />
        </div>
        <CustomMenu
          labelledBy={`actions-btn-${testCaseSet.id}`}
          anchorEl={anchorEls[testCaseSet.id]}
          onClose={() => handleClose(testCaseSet.id)}
        >
          <div style={{ padding: '14px' }}>
            <div onClick={(event) => {handleClick(event, "nonAccordion"); props.onClickEdit(); handleClose(testCaseSet.id)}} style={{marginBottom: "8px", cursor: "pointer"}}><ArticleOutlined sx={{mr: 1 }} />Edit</div>
            <div onClick={(event) => {handleClick(event, "nonAccordion"); props.onClickClone(); handleClose(testCaseSet.id)}} style={{marginBottom: "8px", cursor: "pointer"}}><ContentCopyOutlined sx={{mr: 1}} />Clone</div>
            <div onClick={(event) => {handleClick(event, "nonAccordion"); props.onClickDelete(); handleClose(testCaseSet.id)}} style={{cursor: "pointer"}}><DeleteOutline sx={{mr: 1}} />Delete</div>
          </div>
        </CustomMenu>
      </div>
    </Box>
  )
}

export default TestCaseSetHeader;
