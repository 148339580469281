import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import CustomCheckbox from "../Common/CustomCheckbox.jsx";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const getNewRow = () => {
  const newRow = { name: '', value: '', description: '', required: false, datatype: '' };
  return {...newRow};
}

const HeadersComponent = ({
  headers,
  onHeadersChange,
  skipOutsideClickCheck,
  hideExtraFields=false
}) => {
  const [rows, setRows] = useState([{...getNewRow()}]);
  const tableRef = useRef(null);

  const handleInputChange = (index, field, value) => {
    let updatedRows = [...rows];
    if (field === "required") {
      console.log(value);
      updatedRows[index] = { ...updatedRows[index], [field]: value ? true : false };
    } else {
      updatedRows[index] = { ...updatedRows[index], [field]: value };
    }

    updatedRows = updatedRows.filter((row) => {
      return row.name || row.value;
    });

    updatedRows.push({ ...getNewRow() });
    setRows(updatedRows);

    if (skipOutsideClickCheck) onHeadersChange(updatedRows);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const handleOutsideClick = async (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      let filteredRows = rows.filter((row) => {
        return row.name != '';
      });
      onHeadersChange(filteredRows);
    }
  };

  useEffect(() => {
    let updatedRows = [...headers];
    updatedRows = updatedRows.filter((row) => {
      return row.name || row.value;
    });

    updatedRows.push({...getNewRow()});
    setRows(updatedRows);
  }, [headers]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [rows]);

  const rowTableCellSx = {
    padding: '5px 10px', margin: 0, fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "12px"
  }
  const inputTableCellSx = {
    padding: '5px 10px',
    fontSize: '12px',
    fontFamily: "Inter, Sans-serif",
    fontWeight: "450"
  }

  return (
    <Box>
      <Typography variant="body1">Headers</Typography>
      <TableContainer sx={{ backgroundColor: '#fff', borderRadius: '5px' }}>
        <Table size="small" aria-label="simple" sx={{ border: '1px solid' }}>
          <TableHead>
            <TableRow sx={{ alignItems: 'center', height: '1vh' }}>
              <TableCell
                sx={{ padding: '3px', ...rowTableCellSx }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{ padding: '3px', ...rowTableCellSx }}
              >
                Value
              </TableCell>
              <TableCell
                sx={{
                  width: '35%',
                  padding: '3px',
                  ...rowTableCellSx
                }}
              >
                Description
              </TableCell>
              {
                !hideExtraFields && (
                  <>
                    <TableCell sx={rowTableCellSx}>Required</TableCell>
                    <TableCell sx={rowTableCellSx}>Data Type</TableCell>
                  </>
                )
              }
              <TableCell
                sx={{
                  width: '5%',
                  padding: '3px',
                  ...rowTableCellSx
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody ref={tableRef}>
            {rows.map((header, index) => (
              <TableRow key={index}>
                <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Name"
                    value={header.name}
                    onChange={(e) =>
                      handleInputChange(index, 'name', e.target.value)
                    }
                    InputProps={{
                      readOnly: header.readOnly,
                      classes: { notchedOutline: 'no-border' },
                      className: 'custom-textfield',
                    }}
                    sx={{
                      height: '27px',
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Value"
                    value={header.value}
                    onChange={(e) =>
                      handleInputChange(index, 'value', e.target.value)
                    }
                    InputProps={{
                      readOnly: header.readOnly,
                      classes: { notchedOutline: 'no-border' },
                      className: 'custom-textfield',
                    }}
                    sx={{
                      height: '27px',
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                <TableCell sx={{ padding: 0, margin: 0 }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Description"
                    value={header.description}
                    onChange={(e) =>
                      handleInputChange(index, 'description', e.target.value)
                    }
                    InputProps={{
                      readOnly: header.readOnly,
                      classes: { notchedOutline: 'no-border' },
                      className: 'custom-textfield',
                    }}
                    sx={{
                      height: '27px',
                      '& .MuiInputBase-input': inputTableCellSx
                    }}
                  />
                </TableCell>
                {
                (
                  !hideExtraFields && 
                  <>
                    <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }}>
                      <CustomCheckbox
                        key={`required-${index}`}
                        checked={header.required}
                        label=""
                        onChange={(e) => handleInputChange(index, "required", e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled', readOnly: header.readOnly }}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="datatype-simple-select-label"
                          id="datatype-simple-select"
                          value={header.datatype}
                          label=""
                          size="small"
                          placeholder="datatype"
                          onChange={e => handleInputChange(index, 'datatype', e.target.value)}
                          sx={{...inputTableCellSx, py: 0, textTransform: "capitalize"}}
                        >
                          {["array", "object", "string", "number", "boolean", "null"].map((type) => {
                            return (
                              <MenuItem value={type} sx={{fontFamily: "Inter, Sans-serif", textTransform: "capitalize"}}>{type}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </>
                  )
                }
                <TableCell sx={{ textAlign: 'center', padding: 0, margin: 0 }}>
                  {!header.readOnly && index !== rows.length - 1 ? (
                    <IconButton
                      size="small"
                      aria-label="Delete header"
                      onClick={() => handleDeleteRow(index)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HeadersComponent;
