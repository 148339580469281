import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
  MenuItem as MuiMenuItem,
  List, ListItem, ListItemIcon, ListItemText, Divider
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Add as AddIcon,
  LayersOutlined as LayersOutlinedIcon,
  HomeOutlined as HomeOutlinedIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  CheckOutlined as CheckIcon,
  ContentCopyOutlined as ContentCopyIcon,
  PersonAddOutlined as PersonAddIcon,
  DnsOutlined as DnsIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  BorderColor as BorderColorIcon,
  Folder as FolderIcon,
  GroupsOutlined as GroupsIcon,
} from "@mui/icons-material";
import { PopupButton } from "react-calendly";
import { Delete as DeleteIcon } from "@mui/icons-material";

// Assets
import "./index.css";
import "./CustomSidebar.css";
import qodexLogo from "../../assets/logos/logo_small.png";

// Components
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import AppDashboard from "./index";
import CollectionSidebar from "../Collections/CollectionSidebar";
import ProjectScreen from "../Project";
import OrganizationScreen from "../Organization";
import CustomSnackbar from "../Common/CustomSnackbar";

// Configurations
import { apiAgent } from "../../config/axiosConfig";
import { Logout, inviteByEmail, generateInviteLink, fetchProjectMembers, deleteProjectMember } from "../../redux-store/currentUserActions";
import { setProject } from "../../redux-store/currentUserSlice";
import { resetTabs } from "../../redux-store/tabSlice";
import RunsTriggered from "./RunsTriggered";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import LoadingOverlay from "../Common/LoadingOverlay";
import ProfileScreen from "../Account/Profile";
import { fetchCollection, fetchCollectionBySlug } from "../../redux-store/collectionActions";
import { useGuestUser } from "../../contexts/GuestUserContext";
import customUtils from "../../utils/customUtils";

// TODO: Add a new menu item for Runs

const GuestDashboardScreen = () => {
  // State and Hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const { isGuestUser, setIsGuestUser } = useGuestUser();
  const { collectionSlug, folderSlug, entitySlug } = useParams();
  const { openSnackbar } = useSnackbar();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isCollectionSidebar, setCollectionSidebar] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [orgId, setOrgId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOrgModalOpen, setIsOrgModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(collectionSlug);
  const [selectedFolder, setSelectedFolder] = useState(folderSlug);
  const [selectedEntity, setSelectedEntity] = useState(entitySlug);
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [invitationProjectId, setInvitationProjectId] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');
  const [projectMembers, setProjectMembers] = useState([]);
  const [filter, setFilter] = useState('all');
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [collection, setCollection] = useState(null);

  const sidebarRef = useRef();
  const orgModalRef = useRef();
  const projectModalRef = useRef();

  useEffect(() => {

    async function fetchCollectionData(collectionSlug) {
      const res = await dispatch(fetchCollectionBySlug({ collectionSlug: collectionSlug }));
      setCollection(res.payload.collection);
      setSelectedCollection(res.payload.collection);
      setSelectedProject(res.payload.project);

      setIsGuestUser(true);
    }

    if (collectionSlug) {
      fetchCollectionData(collectionSlug);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Functions and Handlers

  const [selectedMenuItem, setSelectedMenuItem] = useState('collections');
  /////////////////////////////////

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  const openModal = (org) => {
    setSidebarVisible(false);
    setIsModalOpen(true);
    setOrgId(org);
  };

  const openModalOrg = () => {
    setSidebarVisible(false);
    setIsOrgModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsOrgModalOpen(false);
    fetchOrganizations();
  };

  const fetchOrganizations = async () => {
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "get",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        url: `${process.env.REACT_APP_API_URL}/dashboard`,
      });

      const data = response.data;
      setOrganizations(data);

      const lastProject = JSON.parse(localStorage.getItem('lastSelectedProject'));

      if (!lastProject && data.length > 0 && data[0].projects.length > 0) {
        const firstProject = data[0].projects[0];
        setSelectedProject(firstProject);
        dispatch(setProject(firstProject));
        localStorage.setItem('lastSelectedProject', JSON.stringify(firstProject));
      } else if (lastProject) {
        setSelectedProject(lastProject);
        dispatch(setProject(lastProject));
      }
    } catch (error) {
      // Handle the error appropriately
    }
  };

  const handleProjectClick = (project) => {
    dispatch(resetTabs());
    setSelectedProject(project);
    dispatch(setProject(project));
    history.push(`/collections`);
    localStorage.setItem('lastSelectedProject', JSON.stringify(project));
  };

  const updateSelectedProject = (project) => {
    handleProjectClick(project);
  };  

  const handleSelectClick = () => {
    setSidebarVisible(prevSidebarVisible => !prevSidebarVisible);
  };

  const handleCollectionSidebar = () => {
    setCollectionSidebar(prevIsCollectionSidebar => !prevIsCollectionSidebar);
  };

  const redirectToSignUp = () => {    
    const currentLocation = history.location.pathname + history.location.search;
    history.push(`/?redirect=${encodeURIComponent(currentLocation)}`);
  };

  const redirectToSignIn = () => {    
    const currentLocation = history.location.pathname + history.location.search;
    history.push(`/login?redirect=${encodeURIComponent(currentLocation)}`);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const CustomDropdown = () => (
    <Box
      ref={dropdownRef}
      sx={{
        position: 'absolute',
        right: 0,
        top: '100%',
        zIndex: 1,
        mt: '2px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        backgroundColor: '#fff',
        minWidth: '120px',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        sx={{
          padding: '8px 0',
        }}
      >
        <Button sx={{ justifyContent: 'flex-start' }} fullWidth onClick={ redirectToSignIn }>Sign In</Button>
        <Button sx={{ justifyContent: 'flex-start' }} fullWidth onClick={ redirectToSignUp }>Sign Up</Button>
      </Box>
    </Box>
  );

  // View components
  const RenderToolbar = () => {
    return (
      <Box className="toolbar-container">
        <Toolbar variant="dense" className='tool-bar-style'>
          <Grid container>
            <Box className='margin-set'>
              <Grid item>
                <PopupButton
                  url={customUtils.calendlyDemoUrl}
                  rootElement={document.getElementById("root")}
                  text="Book a Demo"
                  className="book-demo-btn"
                />
                <IconButton size="small" onClick={toggleDropdown}>
                  <Avatar src="./Images/profile_image.png" sx={{ width: '36px', height: '36px' }} />
                </IconButton>
                {dropdownVisible && <CustomDropdown />}
              </Grid>
            </Box>
          </Grid>
        </Toolbar>
      </Box>
    );
  };

  const RenderSidebar = () => {
    const menuItems = [
      {
        id: 'collections',
        label: 'Collections',
        icon: <LayersOutlinedIcon fontSize="large" />,
        onClick: () => {}
      }
    ];

    const isMenuItemDisabled = (label) => {
      const allowedItems = ['dashboard', 'collections', 'members', "runner", "test-scenarios"];
      return !allowedItems.includes(label.toLowerCase());
    };

    return (
      <Sidebar backgroundColor="#6241D4" color='#fff' width='60px' style={{ color: '#fff', position: 'fixed', height: '100%' }}>
        <img style={{height: '40px', margin: '8px 7px'}} className='responsive-image centerContent' src={qodexLogo} alt="qodex Logo" />
        <hr style={{ margin: 0 }}/>
        <Menu style={{ display: "flex" }}>
          {menuItems.map(item => (
            <MenuItem
              key={item.label}
              style={{
                color: selectedMenuItem === item.label.toLowerCase() ? '#575757' : '#fff',
                backgroundColor: selectedMenuItem === item.label.toLowerCase() ? 'white' : '#6241D4',
              }}
              className="menu-item"
              onClick={item.onClick}
              disabled={isMenuItemDisabled(item.id)}
            >
              <Box display="flex" flexDirection="column" alignItems="center" style={{ whiteSpace: 'normal', lineHeight: '16px', fontSize: '12px', fontWeight: '400' }}>
                <Tooltip placement="right" title={
                  <React.Fragment>
                    <Typography variant="body1">{item.label}</Typography>
                  </React.Fragment>
                }>
                  {item.icon}
                </Tooltip>
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </Sidebar>
    );
  };

  return (
    <>
      <Box className='main-content-container'
        id="app"
        style={{ height: "100vh", display: "flex", backgroundColor: "#fff" }}
      >
        <RenderSidebar />
    
        <Box sx={{ flexGrow: 1 }} style={{ marginLeft: '60px', maxWidth: 'calc(100% - 60px)' }}>
          <div>
            <RenderToolbar />

            {selectedMenuItem === 'collections' && selectedProject && (
              <CollectionSidebar project={selectedProject} selectedCollection={selectedCollection} ></CollectionSidebar>
            )}
          </div>

          {isModalOpen && (
            <>
              <Box className="modal-overlay" ref={projectModalRef}>
                <ProjectScreen org={orgId} onChild={closeModal} updateSelectedProject={updateSelectedProject}/>
              </Box>
            </>
          )}
          {isOrgModalOpen && (
            <>
              <Box className="modal-overlay" ref={orgModalRef}>
                <OrganizationScreen onChild={closeModal}/>
              </Box>
            </>
          )}
      
        </Box>
      </Box>
      <CustomSnackbar open={snackbarOpen} handleClose={() => { setSnackbarOpen(false) }} message="API Key copied to clipboard" severity="success" />
    </>
  );
};

export default GuestDashboardScreen;
