import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Modal,
  Typography,
  Button
} from '@mui/material';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import { getEntity, getPastResponses } from "../../redux-store/currentUserActions";
import LoadingOverlay from '../Common/LoadingOverlay';
import Samples from './index';

const CollectedSamplesTable = ({ state, api }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);
  const [recentResponses, setRecentResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [paginationMetadata, setPaginationMetadata] = useState({});

  const entities = useSelector((state) => state.user.entities);
  const entity = useMemo(() => {
    return entities?.[selectedSidebarState?.id] || api;
  }, [selectedSidebarState?.id, entities]);

  useEffect(() => {
    const fetchEntity = async () => {
      try {
        await dispatch(getEntity({ id: selectedSidebarState?.id }));
      } catch (error) {
        console.error('Error fetching entity:', error);
      }
    };

    const fetchPastResponses = async () => {
      setIsLoading(true);

      try {
        const res = await dispatch(getPastResponses({ entityId: selectedSidebarState?.id, page: page + 1, perPage: rowsPerPage }));
        console.log('res.payload', res?.payload)
        if (res?.payload?.responses) {
          const recentList = [];
    
          res.payload.responses.forEach(response => {
            recentList.push(response);
          });

          setRecentResponses(recentList);
          setPaginationMetadata(res?.payload?.meta);
          setSelectedResponse(recentList[0] || {});

          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching past responses:', error);
      } finally {
        setIsLoading(false);
      }
    };    

    if (selectedSidebarState?.id) {
      if (!entity) {
        fetchEntity();
      }
      
      fetchPastResponses();
    }
  }, [selectedSidebarState, page, rowsPerPage, dispatch]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: false
    };

    return date.toLocaleString('en-US', options).replace(/\//g, '-').replace(',', '');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = async (response) => {
    setSelectedResponse(response);

    setOpenModal(true);
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 190px)',
    overflow: 'hidden',
    position: 'relative',
    '& .MuiTableContainer-root': {
      overflowY: 'auto',
      scrollbarColor: '#dcdff1 #eef0f6',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#dcdff1',
        outline: '1px solid #eef0f6',
      },
    },
    '& .MuiPaper-root': {
      backgroundColor: '#fafafa',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
    },
  };
  
  const tableStyle = {
    backgroundColor: '#ffffff',
    '& .MuiTableCell-head': {
      color: '#333',
      fontWeight: 'bold',
      backgroundColor: '#f0f2f5',
      padding: '12px 16px',
    },
    '& .MuiTableRow-root:hover': {
      backgroundColor: '#f5f5f5',
    },
    '& .MuiTableCell-body': {
      color: '#333',
      padding: '14px 16px',
    },
  };
  
  const paginationStyle = {
    '& .MuiToolbar-root': {
      color: '#333',
    },
    '& .Mui-selected': {
      backgroundColor: '#eef0f6',
    },
    '& .MuiSvgIcon-root': {
      color: '#333',
    },
    backgroundColor: '#f0f2f5',
    borderTop: '1px solid #dcdff1',
    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
      color: '#333',
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '90%',
    overflow: 'auto',
    borderRadius: '5px'
  };

  const tableCellStyle = {
    textTransform: 'uppercase', fontFamily: "Inter, Sans-serif", fontWeight: "500", fontSize: "11px"
  }

  return (
    <Box sx={ containerStyles } className='collected-samples-component'>
      <Paper sx={{ marginLeft: '10px', marginRight: '10px', height: '99%', overflow: 'auto', '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none' }}>
        <TableContainer sx={{ width: '100%', height: 'calc(100% - 50px)', overflowY: 'auto', flexGrow: 1 }}>
          <Table aria-label="simple" sx={ tableStyle }>
            <TableHead>
              <TableRow>
                <TableCell sx={tableCellStyle}>Date</TableCell>
                <TableCell sx={tableCellStyle} >Status Code</TableCell>
                <TableCell sx={tableCellStyle} >Latency</TableCell>
                <TableCell sx={tableCellStyle} >All Tests</TableCell>
                <TableCell sx={tableCellStyle} >Passed</TableCell>
                <TableCell sx={tableCellStyle} >Failed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recentResponses.length > 0 ? recentResponses.map((response, index) => (
                <TableRow
                  key={response.id}
                  onClick={() => handleOpenModal(response)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <FiberManualRecord style={{ color: response.test_status ? 'green' : 'red', width: '12px', height: '12px', marginRight: '5px' }} />
                    <Button color="primary" onClick={(e) =>{ e.preventDefault(); }} variant="text" sx={{ padding: 0, margin: 0, ...tableCellStyle }}>
                      {formatTimestamp(response.created_at)}
                    </Button>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>{response.status}</TableCell>
                  <TableCell sx={tableCellStyle}>{response.time} ms</TableCell>
                  <TableCell sx={tableCellStyle}>{response.total_tests}</TableCell>
                  <TableCell sx={{ ...tableCellStyle, color: response.tests_passed && response.tests_passed > 0 ? 'green !important' : 'inherit' }}>{response.tests_passed || 0}</TableCell>
                  <TableCell sx={{ ...tableCellStyle, color: response.tests_failed && response.tests_failed > 0 ? 'red !important' : 'inherit' }}>{response.tests_failed || 0}</TableCell>
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', color: '#999' }}>No recent responses found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={paginationStyle}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={paginationMetadata.total_items || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <LoadingOverlay isLoading={isLoading} />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          { isLoading ? (
            <LoadingOverlay isLoading={isLoading} />
          ) : api ? (
            <Box flex={1}>
              <Typography variant="h6" sx={{ margin: '10px 16px' }}>{ selectedResponse ? formatTimestamp(selectedResponse.created_at) : '' }</Typography>
              <Samples state={state} api={api} response={selectedResponse} />
            </Box>
          ) : (
            <Typography variant="body1" sx={{ p: 2 }}>
              Unable to load details.
            </Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default CollectedSamplesTable;
