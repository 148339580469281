import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiAgent } from '../config/axiosConfig';

export const fetchEntityVariables = createAsyncThunk(
  "fetchEntityVariables",
  async (_, { dispatch, rejectWithValue , history}) => {
    try {
      const authToken = localStorage.getItem("token");
      const res = await apiAgent({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${_.entityId}/scoped_variables`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json"
        }
      });
      if (res.status === 200) {
        return res.data?.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

